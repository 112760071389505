export const formatCoin = (data: any) => {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
function getRandomItem(arr: any) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function generateRandomDuplicateList(
  originalList: any,
  targetLength: number
) {
  let duplicatedList = [...originalList]; // Start with the original items
  while (duplicatedList.length < targetLength) {
    duplicatedList.push(getRandomItem(originalList)); // Randomly pick and add items
  }
  return duplicatedList;
}
export function formatDate(date: any) {
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0"); // Tháng từ 0-11 nên cần +1
  const day = String(date?.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export const MaskedPhoneNumber = (phoneNumber: string | undefined) => {
  // Lấy 4 số cuối của số điện thoại
  const visiblePart = phoneNumber?.slice(-4);

  // Tạo chuỗi mới với 6 số đầu bị ẩn
  const maskedPhoneNumber = `XXXXXX${visiblePart}`;

  return maskedPhoneNumber;
};
export const validateDate = (
  day: string,
  month: string,
  year: string,
  isLeapYear: boolean
) => {
  if (!day || !month || !year) return true;
  const daysInMonth: any = {
    1: 31,
    2: isLeapYear ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  if (parseInt(day) > daysInMonth[parseInt(month)]) {
    return false;
  }
  return true;
};
export const calculateAge = (dateString: string) => {
  const [day, month, year] = dateString.split("/").map(Number);
  const birthDate = new Date(year, month - 1, day);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};
