import { useEffect, useState, lazy, Suspense } from "react";
import "./App.css";
import {
  isLocalhost,
  isMobileDevice,
  isSupportedBrowser,
} from "./utils/deviceModel";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ListHistory from "./pages/ListHistory/ListHistory";
import ListGift from "./pages/ListGift/ListGift";
import Profile from "./pages/Profile/Profile";
import ListBrand from "./pages/ListBrand/ListBrand";
import Gacha from "./pages/Game/Gacha/Gacha";
import SpinWheel from "./pages/Game/SpinWheel/SpinWheel";
import FlipCard from "./pages/Game/FlipCard/FlipCard";
import AccumulatePoints from "./pages/ListAccumulatePoints/AccumulatePoints";
import ListGameExchangePoint from "./pages/ListGameExchangePoint/ListGameExchangePoint";
import PrizeGame from "./pages/HomePage/BannerGame/PrizeGame";
import Login from "./pages/Login/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DetailsRating from "./pages/HomePage/DetailsRating/DetailsRating";
import RulesPoint from "./pages/Rules/RulesPoint";
import ListReceiveCustomer from "./pages/ListReceiveCustomer/ListReceiveCustomer";
import ExchangePoint from "./pages/ListExchangePoint/ExchangePoint";
import Waiting from "./components/Popup/Waiting";
import ListRotation from "./pages/ListRotation/ListRotation";
import DetailsNews from "./pages/HomePage/ItemTabHome/DeatailsNews";
import Flip from "./pages/Game/GamePri/Flip";
import { QueryClient, QueryClientProvider } from "react-query";
import DetailsExhangePoint from "./pages/ListExchangePoint/DetailsExchangePoint";
import Countdown from "./components/Popup/CountDown";
import ValidatePassword from "./pages/Login/ValidatePassword";
import ValidateOTP from "./pages/Login/ValidateOTP";
import Register from "./pages/Login/Register";
import ForgotPassword from "./pages/Login/ForgotPassword";
import UpdateProfile from "./pages/Login/UpdateProfile";
import GuideTakeAPhoto from "./pages/GuideTakeAPhoto/GuideTakeAPhoto";
import GiftDetails from "./pages/ListGift/GiftDetails";
import { userLocal } from "./services/localService/localService";
import { setAuthorization } from "./services/apiService/configURL";
import CarouselPhoto from "./pages/GuideTakeAPhoto/CarouselPhoto";
import UrgentPageReview from "./pages/GuideTakeAPhoto/UrgentPageReviewBill/UrgentPageReview";
import BillInValid from "./pages/GuideTakeAPhoto/UrgentPageReviewBill/BillInValid";
import NewGacha from "./pages/Game/NewGacha/NewGacha";
import NoAccessBrowser from "./components/NoAccessBrowser/NoAccessBrowser";
import PeerJumpTower from "./pages/Game/PeerJumpTower/PeerJumpTower";
import PeerReceive from "./pages/Game/PeerJumpTower/PeerReceive";
import ListLeaderBoard from "./pages/ListLeaderBoard/ListLeaderBoard";
import ListPrizeBySeasons from "./pages/ListLeaderBoard/ListPrizeBySeasons";
import { detectIncognito } from "detectincognitojs";
import MainPage from "./pages/Game/Ghost/MainPage";
import LeaderBoardPlantTree from "./pages/ListLeaderBoard/Plantree/LeaderBoardPlantTree";
import ReceiveGift from "./pages/Game/Ghost/ReceiveGift";
import LuckyMachine from "./pages/Game/LuckyMachine/LuckyMachine";
import SpecialTC from "./pages/HomePage/BannerGame/SpecialTC";
import ListReceiveSpecial from "./pages/ListReceiveCustomer/ListReceiveSpecial";
import MainPageSting from "./pages/Game/Sting/MainPageSting";
import NewSpinWheel from "./pages/Game/SpinWheel/NewSpinWheel";
import MainPageAqua from "./pages/Game/Aquafina/MainPageAquafina";
import LuckyBox from "./pages/Game/LuckyBox/LuckyBox";
import DragonSnake from "./pages/Game/DragonSnake/DragonSnake";
import IntroDragon from "./pages/Game/DragonSnake/IntroDragon";
import IntroPlantTree from "./pages/Game/PlantTree/IntroPlantTree";
import PlantTree from "./pages/Game/PlantTree/PlantTree";
import HomePage from "./pages/HomePage/HomePage";
import TCGame from "./pages/Game/DragonSnake/TC/TCGame";
import SurveyFormPickleBall from "./pages/Game/PickeBall/SurveyFormPickleBall";
import UnboxGift from "./pages/Game/Summer/UnboxGift";
import ResultGift from "./pages/Game/Summer/ResultGift";
import IntroPickleBall from "./pages/Game/PickeBall/IntroPickleBall";
import SuccessPickleBall from "./pages/Game/PickeBall/SuccessPickleBall";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { DeviceModel } from "./models/deviceModel";
import IntroSummer from "./pages/Game/Summer/IntroSummer";
import Summer from "./pages/Game/Summer/Summer";
import Pickup from "./pages/Game/Pickup_v2/Pickup";
// const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
function App() {
  let access = false;
  document.body.style.backgroundColor = "white";
  let mobileDevice = isMobileDevice();
  let supportedBrowser = isSupportedBrowser();
  if ((mobileDevice && supportedBrowser) || isLocalhost()) {
    if (window.innerWidth < 450) {
      access = true;
    }
  }
  let { token } = userLocal.get();
  setAuthorization(token);
  const queryClient = new QueryClient();
  const [isPrivate, setIsPrivate] = useState(false);
  useEffect(() => {
    detectIncognito().then((result: any) => {
      if (result.isPrivate === false) {
        setIsPrivate(true);
      }
    });
  }, []);
  return (
    <div className="font-regular-so">
      <GoogleReCaptchaProvider
        reCaptchaKey="6LdM0dsqAAAAAIkD1hlgAhfN2tL_t9g2A1IjHPFt"
        language="en"
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined,
        }}
      >
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {access && isPrivate ? (
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login/:back?" element={<Login />} />
                <Route
                  path="/validate-password/:back?"
                  element={<ValidatePassword />}
                />
                <Route
                  path="/validate-otp/:next/:back?"
                  element={<ValidateOTP />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/update-profile" element={<UpdateProfile />} />
                <Route path="/tc" element={<RulesPoint />} />
                <Route path="/waiting" element={<Waiting />} />
                <Route path="/countdown" element={<Countdown />} />
                <Route
                  path="/exchange-point/:back?"
                  element={<ExchangePoint />}
                />
                <Route path="/list-gift/:current?" element={<ListGift />} />
                <Route
                  path="/gift-details/:type_id/:id/:current"
                  element={<GiftDetails />}
                />
                <Route
                  path="/accumulate-point"
                  element={<AccumulatePoints />}
                />
                <Route path="/list-history" element={<ListHistory />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/brand" element={<ListBrand />} />
                <Route
                  path="/list-game/:type/:id?"
                  element={<ListGameExchangePoint />}
                />
                <Route path="/games" element={<ListRotation />} />
                <Route
                  path="/list-receive-customer/:type/:id?"
                  element={<ListReceiveCustomer />}
                />
                <Route
                  path="/special/list-receive/:type/:id?"
                  element={<ListReceiveSpecial />}
                />
                <Route
                  path="/aqua/list-receive/:type/:id?"
                  element={<ListReceiveSpecial />}
                />
                <Route
                  path="/list-leader-board/:id?/:race_id?/:prize?"
                  element={<ListLeaderBoard />}
                />
                <Route
                  path="/list-leader-board-plant-tree/:id?"
                  element={<LeaderBoardPlantTree />}
                />
                <Route
                  path="/list-prize-seasons/:id?"
                  element={<ListPrizeBySeasons />}
                />
                <Route path="/prize-game/:id" element={<PrizeGame />} />
                <Route path="/special/tc/:id" element={<SpecialTC />} />
                <Route path="/aqua/tc/:id" element={<SpecialTC />} />
                <Route path="/dragon_snake/tc/:id" element={<TCGame />} />
                <Route
                  path="/guide-take-photo/:id?"
                  element={<GuideTakeAPhoto />}
                />
                <Route path="/urgent-page" element={<UrgentPageReview />} />
                <Route path="/bill-invalid" element={<BillInValid />} />
                <Route
                  path="/guide-page-picture/:id?"
                  element={<CarouselPhoto />}
                />
                <Route path="/details-rating" element={<DetailsRating />} />
                <Route path="/details-news/:id" element={<DetailsNews />} />
                <Route
                  path="/details-gift/:id/:back?"
                  element={<DetailsExhangePoint />}
                />
                <Route
                  path="/ball_machine/:campaign_id/:sale_id"
                  element={<NewGacha />}
                />
                <Route
                  path="/ball_machines/:campaign_id/:sale_id"
                  element={<Gacha />}
                />
                <Route
                  path="/lucky_wheel/:campaign_id/:sale_id"
                  element={<SpinWheel />}
                />
                <Route
                  path="/lucky_wheel_v2/:campaign_id/:sale_id"
                  element={<NewSpinWheel />}
                />
                <Route
                  path="/card_flipping/:campaign_id/:sale_id"
                  element={<FlipCard />}
                />
                <Route
                  path="/card_flip_flop/:campaign_id/:sale_id"
                  element={<Flip />}
                />
                <Route
                  path="/pickup_machine/:campaign_id/:sale_id"
                  element={<Pickup />}
                />
                <Route
                  path="/bear_pickup/:campaign_id/:sale_id"
                  element={<PeerJumpTower />}
                />
                <Route
                  path="/peerreceive/:campaign_id/:sale_id"
                  element={<PeerReceive />}
                />
                <Route
                  path="/lucky_machine/:campaign_id/:sale_id"
                  element={<LuckyMachine />}
                />
                <Route
                  path="/halloween_ghost/:campaign_id/:sale_id"
                  element={<MainPage />}
                />
                <Route
                  path="/sting_pickup/:campaign_id/:sale_id"
                  element={<MainPageSting />}
                />
                <Route
                  path="/aqua_pickup/:campaign_id/:sale_id"
                  element={<MainPageAqua />}
                />
                <Route
                  path="/lucky_box/:campaign_id/:sale_id"
                  element={<LuckyBox />}
                />
                <Route path="/intro_dragon/:id" element={<IntroDragon />} />
                <Route path="/dragon_snake" element={<DragonSnake />} />
                <Route
                  path="/intro_plant_tree/:id"
                  element={<IntroPlantTree />}
                />
                <Route path="/plant-tree/:id/:type?" element={<PlantTree />} />
                <Route path="/surveyform" element={<SurveyFormPickleBall />} />
                <Route
                  path="/intro-pickleball/:id"
                  element={<IntroPickleBall />}
                />
                <Route
                  path="/success-pickleball/:id"
                  element={<SuccessPickleBall />}
                />
                <Route
                  path="/receive-gift-ghost/:campaign_id/:sale_id"
                  element={<ReceiveGift />}
                />
                <Route path="/intro-summer" element={<IntroSummer />} />
                <Route path="/summer" element={<Summer />} />
                <Route path="/threejs" element={<UnboxGift />} />
                <Route path="/result-summer" element={<ResultGift />} />
              </Routes>
            </QueryClientProvider>
          </BrowserRouter>
        ) : (
          <NoAccessBrowser />
        )}
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
