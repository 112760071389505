import PHONE from "../../../assets/image/Popup/phone.png";
import ERROR from "../../../assets/image/GuideTakePhoto/error.png";
import { useState } from "react";
import CANCEL from "../../../assets/image/Home/cancel-popup.png";
import BTN_HOTLINE from "../../../assets/image/Popup/bg-hotline.png";
import { useNavigate } from "react-router-dom";
import ContactPhoneZalo from "../../../components/ContactPhoneZalo/ContactPhoneZalo";

export default function ErrorBill({
  isShow,
  messError,
  handleClose,
  labelBtn,
  title,
  imageError,
}: {
  title: string;
  isShow: boolean;
  messError: string;
  handleClose: () => void;
  labelBtn: string;
  imageError: string;
}) {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const navigation = useNavigate();
  const [isOpenImg, setIsOpneImg] = useState(false);
  const handleSeeImg = () => {
    setIsOpneImg(true);
  };
  const handleOpenImage = () => {
    setIsOpneImg(false);
  };
  const handleClickGift = () => {
    navigation("/guide-take-photo");
  };
  return (
    <div>
      {!isOpenImg ? (
        <div
          className={`${
            isShow ? "" : "hidden"
          } background-result min-h-screen relative`}
        >
          <div className="absolute w-full">
            <div className="font-bold-so text-[#4E9ABB] text-[24px] text-center w-full mt-20">
              {title}
            </div>
            <div className={`flex justify-center`}>
              <img src={ERROR} className="max-w-[200px]" />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: messError as string,
              }}
              className="font-light-so text-[#424242] text-[16px] text-center px-10"
            ></div>
            <div className="flex justify-center w-full mt-10 px-5">
              <button
                className="w-1/2 rounded-2xl mr-3 leading-5 py-2 shadow-item"
                style={{
                  backgroundColor: "#5BC2DC",
                  border: `2px solid #ffffff`,
                  color: "#ffffff",
                }}
                onClick={() => handleSeeImg()}
              >
                Xem lại hóa đơn
              </button>
              <button
                className="w-1/2 rounded-2xl leading-5 py-5 shadow-item"
                style={{
                  backgroundColor: "#2D4E68",
                  border: `2px solid #ffffff`,
                  color: "#ffffff",
                }}
                onClick={() => handleClose()}
              >
                Chụp lại
              </button>
            </div>
            <div className="relative flex items-center mt-10 w-full justify-center">
              <div>
                <img src={PHONE} />
              </div>
              <div className="text-[#2D4E68] font-light-so ml-2">
                Liên hệ Hotline{" "}
                <span className="font-semibold-so">
                  <a href={`tel:${hotline}`}>{hotline}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative z-50">
          <div className="object-cover">
            <img
              src={imageError}
              className="min-h-screen w-full object-cover"
            />
          </div>
          <div className="fixed bottom-10 left-1/2 -translate-x-1/2">
            <img src={CANCEL} onClick={handleOpenImage} />
          </div>
        </div>
      )}
      <ContactPhoneZalo />
    </div>
  );
}
