import BackgroundGame from "../../../components/BackgroundPopup/BackgroundGame";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import BOWL from "../../../assets/image/Game/Summer/bowl-pepsi.png";
import PLUS from "../../../assets/image/Game/Summer/plus.png";
import BOARD from "../../../assets/image/Game/Summer/board.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect } from "react";

export default function ResultGift() {
  const navigation = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const campaign_id = params.get("campaign_id");
  const checkNumberGame = localStorage.getItem("CHECKGAME");
  const handleNext = () => {
    navigation(`/summer?id=${id}&campaign_id=${campaign_id}`);
    localStorage.removeItem("GIFT_WHEEL");
    localStorage.removeItem("BOTTLE_CAP");
    localStorage.removeItem("LUCKY_CODE");
  };
  const handleMyGift = () => {
    navigation("/list-gift");
    localStorage.removeItem("GIFT_WHEEL");
    localStorage.removeItem("BOTTLE_CAP");
    localStorage.removeItem("LUCKY_CODE");
    localStorage.removeItem("LUCKY_CODE");
  };
  const dataGame = JSON.parse(localStorage.getItem("GAME_DATA_LOCAL") || "{}");
  const dataGift = JSON.parse(localStorage.getItem("GIFT_WHEEL") || "{}");
  const bottleCap = JSON.parse(localStorage.getItem("BOTTLE_CAP") || "{}");
  const exchangeCode = JSON.parse(localStorage.getItem("LUCKY_CODE") || "{}");

  useEffect(() => {
    if (dataGift?.no_gift === undefined) {
      navigation(`/summer?id=${id}&campaign_id=${campaign_id}`);
    }
    if (parseInt(checkNumberGame as string) >= 1) {
      window.location.reload();
      localStorage.removeItem("CHECKGAME");
    }
  }, []);
  return (
    <Fragment>
      {dataGift?.no_gift !== undefined ? (
        <BackgroundGame img={dataGame?.background_game_image_url}>
          <PopupOverlay isShow={true}>
            <div
              style={{
                backgroundImage: `url(${dataGame?.frame_icon_url})`,
                width: "90vw",
                backgroundPosition: "container",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
              className="relative z-10 left-1/2 -translate-x-1/2"
            >
              <div className="absolute -top-4 right-0" onClick={handleNext}>
                <img src={dataGame?.close_button_image_url} className="w-12" />
              </div>
              <div className="py-5 text-white">
                {dataGift?.no_gift ? (
                  <div>
                    <div className="uppercase text-border text-[18px] font-semibold-so mt-5 mb-3 text-[#FFEB76]">
                      {dataGift?.gift_name}
                    </div>
                  </div>
                ) : (
                  <div>
                    {" "}
                    <div className="">BẠN ĐÃ NHẬN ĐƯỢC</div>
                    <div className="uppercase text-border text-[18px] px-5 font-semibold-so text-[#FFEB76]">
                      {dataGift?.gift_name}
                    </div>
                  </div>
                )}

                <div>
                  {!dataGift?.no_gift && (
                    <div className="text-[8px] text-black">
                      {exchangeCode && exchangeCode}
                    </div>
                  )}

                  <img
                    src={dataGift?.gift_square_image_url}
                    className="relative mt-1 left-1/2 -translate-x-1/2 w-5/12"
                  />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataGift?.instructions_redeeming_rewards,
                  }}
                  className="relative left-1/2 mt-2 -translate-x-1/2 font-light-so text-[12px] p-2 bg-[#0b77a4] rounded-xl w-10/12 leading-4 borderpopup_description-summer"
                ></div>

                <div className="flex justify-center mt-2">
                  <div className="flex items-center -mr-1 ml-2">
                    <img src={PLUS} className="w-7" />
                  </div>
                  <div className="relative z-10">
                    <img
                      src={bottleCap?.gift_square_image_url}
                      className="w-24"
                    />
                  </div>
                  <div className="relative z-0 flex items-center">
                    <img src={BOARD} className="-ml-8 relative w-64 h-12" />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: bottleCap?.conditions_receiving_prize,
                      }}
                      className="absolute left-[60%] -translate-x-3/4 w-full leadi top-1/2 -translate-y-1/2 text-[11px] text-white font-semibold-so"
                    ></div>
                  </div>
                </div>
                <div className="px-5 mt-3 flex justify-around ">
                  <button onClick={handleMyGift} className="relative">
                    <img
                      src={dataGame?.gift_button_image_url}
                      className="h-16"
                    />
                    <div className="absolute font-bold-so text-[13px] top-1/2 -translate-y-[65%] flex justify-center w-full">
                      QUÀ CỦA TÔI
                    </div>
                  </button>
                  <button onClick={handleNext} className="relative">
                    <img
                      src={dataGame?.leader_board_icon_url}
                      className="h-16"
                    />
                    <div className="absolute font-bold-so text-[13px] top-1/2 -translate-y-[65%] flex justify-center w-full">
                      CHƠI TIẾP
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="absolute z-0 -top-[56px] w-screen left-1/2 -translate-x-1/2">
              <img
                src={dataGame?.popup_header_image_url}
                className="relative z-0"
              />
              <div className="absolute font-regular-coin top-1/2 -translate-y-[80%] text-center w-full text-white text-[20px]">
                THÔNG BÁO
              </div>
            </div>
          </PopupOverlay>
        </BackgroundGame>
      ) : null}
    </Fragment>
  );
}
