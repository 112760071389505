import PHONE from "../../assets/image/Home/icon-phone.png";
import ZALO from "../../assets/image/Home/iconzalo.png";
import ICONREVIVE from "../../assets/image/Game/Revive/iconrevive.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import { BACK_GLOBAL } from "../../services/localService/localService";

export default function ContactPhoneZalo() {
  const { hotline, zalo_link } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const navigation = useNavigate();
  const hanldeRevive = () => {
    localStorage.setItem(
      BACK_GLOBAL,
      `intro-pickleball/${data?.collections?.collection_id}`
    );
    navigation(`/intro-pickleball/${data?.collections?.collection_id}`);
  };
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: async () => await homeServices.homeApi(),
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });

  return (
    <div className="fixed bottom-28 z-40 right-2 flex flex-col justify-center items-center">
      <a href={`tel:${hotline}`}>
        <img src={PHONE} className="w-10" width={40} />
      </a>
      <a href={`${zalo_link}`} target="_blank" className="mt-5">
        <img src={ZALO} className="w-9" width={36} />
      </a>
      {data?.collections?.collection_id && (
        <div className="mt-5" onClick={hanldeRevive}>
          <img src={ICONREVIVE} className="w-[68px]" width={68} height={68} />
        </div>
      )}
    </div>
  );
}
