import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Campaign } from "../../../models/GameModel";
import { GameServices } from "../../../services/apiService/GameService";

export default function SuccessPickleBall() {
  const { id } = useParams();
  const [data, setData] = useState<Campaign>();
  const [isPopup, setIsPopup] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  useEffect(() => {
    GameServices.GetDataCollection(id as string)
      .then((res: any) => {
        setData(res?.collection_content_data);
      })
      .catch((err) => {});
  }, []);
  const handleMygift = () => {
    navigation(`/list-gift`);
  };
  return (
    <div
      style={{
        backgroundImage: `url(${data?.background_gift_image_url})`,
        height: "100vh",
        width: "100vw",
        backgroundPosition: "cover",
        backgroundSize: "100% 100%",
      }}
      className="relative"
    >
      <div className="flex justify-center">
        <div className="rounded-2xl flex flex-col items-center justify-center font-semibold-so text-[24px] px-5 py-8 absolute text-center top-1/2 -translate-y-1/2">
          <img
            src={
              type === "0"
                ? data?.button_gift_enable_image_url
                : data?.button_gift_disable_image_url
            }
            className=" w-8/12"
          />
          {type === "0" ? (
            <div className="text-[14px] bg-white rounded-xl p-2 mt-3 font-regular-so">
              BTC sẽ sắp xếp{" "}
              <i className="text-[#12419C] font-semibold-so">"ghép đôi" </i>{" "}
              ngẫu nhiên và công bố Danh sách tham gia cuối cùng tại Fanpage,
              Website của{" "}
              <i className="text-[#12419C] font-semibold-so">Revive</i> và{" "}
              <i className="text-[#12419C] font-semibold-so">
                Zalo OA Suntory Pepsico
              </i>{" "}
              Khuyến Mãi – dự kiến vào 20:00 ngày 27/3/2025!
            </div>
          ) : (
            <div className="text-[14px] bg-white rounded-xl p-2 mt-3 font-regular-so">
              <span className="text-[#12419C] font-semibold-so">
                Địa điểm tổ chức:
              </span>{" "}
              D-Joy CỤM SÂN NAM SAIGON - Nguyễn Lương Bằng, Tân Phú, Quận 7,
              TP.HCM. Thời gian sự kiện:{" "}
              <span className="text-[#12419C] font-semibold-so">
                {" "}
                29 - 30/03/2025
              </span>{" "}
              Xem thêm Danh sách tham gia cuối cùng tại Fanpage, website và Zalo
              OA Suntory Pepsico Khuyến Mãi – dự kiến vào 20:00 ngày 27/3/2025!
            </div>
          )}

          <button
            style={{
              backgroundColor: data?.gift_button_color,
              color: data?.gift_button_text_color,
            }}
            className="w-7/12 mt-14 uppercase rounded-2xl font-semibold-so text-[20px] px-5 py-4 text-center top-[65%] -translate-y-1/2"
            onClick={handleMygift}
          >
            Quà của tôi
          </button>
        </div>
      </div>
    </div>
  );
}
