import { LoginAPIModel } from "../../models/LoginModel";
import { axiosInstance } from "./configURL";

export const LoginServices = {
  LoginApi: (data: LoginAPIModel) => {
    return axiosInstance.post("/consumer/login", {
      params: data,
    });
  },
  ValidateAPI: (data: LoginAPIModel) => {
    return axiosInstance.post("v1/consumer/register/validate_phone", {
      params: data,
    });
  },
  CheckAuthenExpired: () => {
    return axiosInstance.post("/campaign/check_authen", {
      params: {},
    });
  },
  GetOTPPassword: (data: LoginAPIModel) => {
    return axiosInstance.post("/consumer/forgot_password/otp", {
      params: data,
    });
  },
  ValidateOTPPassword: (data: LoginAPIModel) => {
    return axiosInstance.post("/consumer/forgot_password/validate_otp", {
      params: data,
    });
  },
  ValidateOTPRegister: (data: LoginAPIModel) => {
    return axiosInstance.post("/v1/consumer/register/validate_otp", {
      params: data,
    });
  },
  Register: (data: LoginAPIModel) => {
    return axiosInstance.post("/v1/consumer/register_new_account", {
      params: data,
    });
  },
  UpdatePassword: (data: LoginAPIModel) => {
    return axiosInstance.post("/consumer/forgot_password", {
      params: data,
    });
  },
  ValidateOTPApi: (data: LoginAPIModel) => {
    return axiosInstance.post("/consumer/login", {
      params: data,
    });
  },
  GetCityApi: () => {
    return axiosInstance.get("/base/city");
  },
  GetDistrictApi: (id: string) => {
    return axiosInstance.get(`/base/district?city_id=${id}`);
  },
  GetWardApi: (id: string) => {
    return axiosInstance.get(`/base/ward?district_id=${id}`);
  },
};
