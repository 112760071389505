import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Fragment, useEffect, useState } from "react";
import "../../assets/css/Login.css";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import FOOTER from "../../assets/image/Login/footer.png";
import LoadingButton from "../../components/Loading/LoadingButton";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import { DataRegister } from "../../models/ListReceiveCustomer";
import DOWN from "../../assets/image/Profile/down.png";
import PopupTerm from "../../components/Popup/PopupTerm";
import PopupError from "../../components/Popup/PopupError";
import { validateDate } from "../../utils/formatData";

export default function SurveyForm({
  goToPrevious,
  dataRegister,
  title,
  labelButton,
  handleBack,
  handleNext,
  required,
  consent,
  updateAfter,
  isLoading,
}: {
  goToPrevious: any;
  dataRegister: DataRegister;
  title: string;
  labelButton: string;
  handleBack: () => void;
  required: boolean;
  consent: boolean;
  handleNext: any;
  updateAfter: boolean;
  isLoading: boolean;
}) {
  document.body.style.backgroundColor = "#FFF9F4";
  const [isShowPopup, setShowPopup] = useState(false);
  const [isShowAge, setIsShowAge] = useState(false);
  const [errAge, setErrorAge] = useState("");
  const phone = localStorage.getItem("PHONE");
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const onSubmit = (data: any) => {
    data["participation"] = typeJoin;
    data["register_competition"] = typePlayer;
    reset(data);
    const age = calculateAge(`${data?.day}/${data?.month}/${data?.year}`);

    const age_couple = calculateAge(
      `${data?.couple_info?.day}/${data?.couple_info?.month}/${data?.couple_info?.year}`
    );
    if (age > 13) {
      data["birthday"] = data?.year + "-" + data?.month + "-" + data?.day;
      if (typeJoin == "1") {
        if (age_couple > 13) {
          data.couple_info["birthday"] =
            data?.couple_info?.year +
            "-" +
            data?.couple_info?.month +
            "-" +
            data?.couple_info?.day;
          if (isValidDateCouple) {
            const params = {
              phone: phone,
            };
            const merge = Object.assign(data, params);
            goToPrevious(merge);
          }
        } else {
          setIsShowAge(true);
          setErrorAge("Cặp đôi chưa trên 13 tuổi để tham gia chương trình");
        }
      } else {
        if (isValidDate) {
          const params = {
            phone: phone,
          };
          const merge = Object.assign(data, params);
          goToPrevious(merge);
        }
      }
    } else {
      setIsShowAge(true);
      setErrorAge("Bạn chưa trên 13 tuổi để tham gia chương trình");
    }
  };

  const {
    register,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    criteriaMode: "all",
    shouldUnregister: false,
  });
  const [typeJoin, setTypeJoin] = useState("");
  const [typePlayer, setTypePlayer] = useState("");
  const [typeParticipate, setTypeParticipate] = useState("");
  useEffect(() => {
    setValue("player", "");
    setValue("participation", typeJoin);
    setValue("register_competition", typePlayer);
    setValue("participate_event", typeParticipate);
    setValue("reason_play", "");
    setValue("favorite_music", "");
    setValue("hoppy", "");
    setValue("couple_name", "");
    setValue("couple_info.name", "");
    setValue("couple_info.phone", "");
    setValue("couple_info.email", "");
    setValue("couple_info.birthday", "");
    setValue("couple_info.gender", "");
    setValue("couple_info.player", "");
    if (dataRegister) {
      setValue("name", dataRegister.name);
      setValue("year", dataRegister?.birthday?.split("-")[0]);
      setValue("day", dataRegister?.birthday?.split("-")[2]);
      setValue("gender", dataRegister.gender);
      setValue("phone", dataRegister.phone);
      setValue("email", dataRegister.email || "");
      if (dataRegister?.birthday?.split("-")[1]?.charAt(0) === "0") {
        const a = dataRegister?.birthday?.split("-")[1].split("0")[1];
        setValue("month", a);
      } else {
        setValue("month", dataRegister?.birthday?.split("-")[1]);
      }
    }
  }, [dataRegister]);
  const [name, setName] = useState<string>("");
  const [check, setCheck] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<string>("");
  const [errorCheck, setErrorCheck] = useState<string>("");
  const checkPrize = () => {
    setShowPopup(true);
  };
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [dayCouple, setDayCouple] = useState("");
  const [monthCouple, setMonthCouple] = useState("");
  const [yearCouple, setYearCouple] = useState("");
  const [isLeapYear, setIsLeapYear] = useState(false);
  const [isValidDate, setIsValidDate] = useState(false);

  const [isLeapYearCouple, setIsLeapYearCouple] = useState(false);
  const [isValidDateCouple, setIsValidDateCouple] = useState(false);

  const checkLeapYear = (year: any) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };
  useEffect(() => {
    if (year.length === 4) {
      setIsLeapYear(checkLeapYear(Number(year)));
    } else {
      setIsLeapYear(false);
    }
  }, [year]);
  useEffect(() => {
    if (yearCouple.length === 4) {
      setIsLeapYearCouple(checkLeapYear(Number(yearCouple)));
    } else {
      setIsLeapYearCouple(false);
    }
  }, [yearCouple]);
  useEffect(() => {
    setIsValidDate(validateDate(day, month, year, isLeapYear));
  }, [day, month, year, isLeapYear]);
  useEffect(() => {
    setIsValidDateCouple(
      validateDate(dayCouple, monthCouple, yearCouple, isLeapYearCouple)
    );
  }, [dayCouple, monthCouple, yearCouple, isLeapYearCouple]);

  const calculateAge = (dateString: string) => {
    const [day, month, year] = dateString.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const handleClose = () => {
    setIsShowAge(!isShowAge);
  };
  useEffect(() => {
    const updateHeight = () => {
      document.body.style.height = window.innerHeight + "px";
    };

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <div>
      <HeaderFrame
        footer={FOOTER}
        title={title}
        iconBack={BACK_HEADER}
        handleBack={handleBack}
      >
        <div className="pb-10 px-3">
          <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Họ và tên <span className="text-[#f53a3a]">(*)</span>
              </label>
              <div className="rounded-lg bg-white mt-1 border-itemlogin">
                <input
                  placeholder="Vui lòng nhập Họ và tên"
                  className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                  {...register("name", {
                    required: "Không để trống",
                    onChange: (e) => {
                      setName(e.target.value);
                    },
                  })}
                />
              </div>
              <div className="w-full text-center text-[red] mt-1">
                {name ? "" : errorName}
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="grid grid-cols-6 gap-2">
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Ngày <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      type="tel"
                      placeholder="Nhập Ngày"
                      className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                      min="1"
                      max="31"
                      {...register("day", {
                        required: "Không để trống",
                        onChange: (e) => {
                          setDay(e.target.value);
                        },
                      })}
                    />
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="day"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Tháng <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                    <select
                      id="day"
                      {...register("month", {
                        required: required ? "Không để trống" : required,
                        onChange: (e) => {
                          setMonth(e.target.value);
                        },
                      })}
                      className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                    >
                      <option value="" selected>
                        Tháng
                      </option>
                      <option value="1">Tháng 1</option>
                      <option value="2">Tháng 2</option>
                      <option value="3">Tháng 3</option>
                      <option value="4">Tháng 4</option>
                      <option value="5">Tháng 5</option>
                      <option value="6">Tháng 6</option>
                      <option value="7">Tháng 7</option>
                      <option value="8">Tháng 8</option>
                      <option value="9">Tháng 9</option>
                      <option value="10">Tháng 10</option>
                      <option value="11">Tháng 11</option>
                      <option value="12">Tháng 12</option>
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="month"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Năm <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      type="tel"
                      placeholder="Nhập Năm"
                      className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                      {...register("year", {
                        required: "Không để trống",
                        onChange: (e) => {
                          setYear(e.target.value);
                        },
                      })}
                      min="1900"
                      max="2100"
                    />
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="year"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-6 text-center">
                  {!isValidDate && (
                    <p style={{ color: "red" }}>Ngày không hợp lệ</p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-6 gap-2">
                <div className="col-span-6">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Giới tính <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                    <select
                      id="day"
                      {...register("gender", {
                        required: required ? "Không được để trống" : required,
                      })}
                      className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                    >
                      <option value="" selected>
                        Giới tính
                      </option>
                      <option value="male">Nam</option>{" "}
                      <option value="female">Nữ</option>
                      <option value="other">Khác</option>
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="gender"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Email <span className="text-[#f53a3a]">(*)</span>
              </label>
              <div className="font-normal z-0 text-[red] text-[13px] text-left">
                Vui lòng điền email chính xác, BTC D-Joy sẽ gửi mail cho bạn
              </div>
              <div className="rounded-lg bg-white mt-1 border-itemlogin">
                <input
                  placeholder="Vui lòng nhập email"
                  className="w-full px-3 h-12 font-light-so  text-[14px] font-light-so rounded-2xl input-hidden text-left"
                  {...register("email", {
                    required: "Không để trống",
                    pattern: {
                      value:
                        /^[A-Za-z][a-z0-9._%+-]+@(gmail\.com|outlook\.com|yahoo\.com|yahoo\.com.vn)$/,
                      message:
                        "Vui lòng nhập kí tự thường đúng email @gmail.com hoặc @outlook.com hoặc @yahoo.com, @yahoo.com.vn",
                    },
                  })}
                />
              </div>
            </div>
            <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p
                          key={type}
                          className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                        >
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
            <div className="mt-4 grid grid-cols-6 gap-2">
              <div className="col-span-6">
                <label className="text-[#5BC2DC] font-semibold-so">
                  Đăng ký nội dung thi đấu
                </label>
                <div className="mt-1">
                  <div>
                    <input
                      type="checkbox"
                      id="new_register_competition"
                      {...register("register_competition")}
                      checked={typePlayer === "0"}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setValue("register_competition", isChecked ? "" : "0");
                        setTypePlayer(isChecked ? "0" : "");
                      }}
                    />
                    <label htmlFor="new_register_competition" className="ml-2">
                      Đôi Nam hoặc Đôi Nam Nữ
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      id="old_register_competition"
                      {...register("register_competition")}
                      checked={typePlayer === "1"}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setValue("register_competition", isChecked ? "1" : "1");
                        setTypePlayer(isChecked ? "1" : "");
                      }}
                    />
                    <label htmlFor="old_register_competition" className="ml-2">
                      Đôi Nữ hoặc Đôi Nam Nữ
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 grid grid-cols-6 gap-2">
              <div className="col-span-6">
                <label className="text-[#5BC2DC] font-semibold-so">
                  Giao lưu với Vận Động Viên chuyên nghiệp của team D-Joy
                </label>
                <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                  <select
                    id="participate_event"
                    {...register("participate_event")}
                    className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                  >
                    <option value="" selected>
                      Chọn câu trả lời
                    </option>
                    <option value="1">Có</option>{" "}
                    <option value="0">Không</option>
                  </select>
                  <div className="absolute right-0 top-1/2 -translate-y-1/2">
                    <img src={DOWN} className="mr-2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Bạn đã chơi Pickleball được bao lâu{" "}
                <span className="text-[#f53a3a]">(*)</span>
              </label>
              <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                <select
                  id="player"
                  {...register("player", {
                    required: "Không để trống",
                  })}
                  className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                >
                  <option value="" selected>
                    Chọn câu trả lời
                  </option>
                  <option value="0">Mới tập chơi</option>{" "}
                  <option value="2">Đã chơi được 1 - 3 tháng</option>
                  <option value="3">Đã chơi được 3 - 6 tháng</option>
                  <option value="1">Đã chơi được hơn 6 tháng</option>
                </select>
                <div className="absolute right-0 top-1/2 -translate-y-1/2">
                  <img src={DOWN} className="mr-2" />
                </div>
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="player"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Hình thức tham gia
              </label>
              <div className="flex justify-between">
                <div>
                  <div>
                    <input
                      type="checkbox"
                      id="alone"
                      {...register("participation", {
                        required: "Không để trống",
                      })}
                      checked={typeJoin === "0"}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setValue("participation", isChecked ? "0" : "");
                        setTypeJoin(isChecked ? "0" : "");
                        setValue("reason_play", "");
                        setValue("hoppy", "");
                        setValue("favorite_music", "");
                        localStorage.setItem("TYPE", "0");
                      }}
                    />
                    <label htmlFor="alone" className="ml-2">
                      Tham gia ghép đôi
                    </label>
                  </div>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="couple"
                    {...register("participation", {
                      required: "Không để trống",
                    })}
                    checked={typeJoin === "1"}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setValue("participation", isChecked ? "1" : "");
                      setTypeJoin(isChecked ? "1" : "");
                      setValue("reason_play", "");
                      setValue("hoppy", "");
                      setValue("favorite_music", "");
                      localStorage.setItem("TYPE", "1");
                    }}
                  />
                  <label htmlFor="couple" className="ml-2">
                    Đã có cặp đôi
                  </label>
                </div>
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="participation"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            {typeJoin == "1" ? (
              <Fragment>
                <div className="mt-3">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Họ và tên bạn cặp{" "}
                    <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      placeholder="Vui lòng nhập Họ và tên"
                      className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                      {...register("couple_info.name", {
                        required: "Không để trống",
                        onChange: (e) => {
                          // setName(e.target.value);
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                  <ErrorMessage
                    errors={errors}
                    name="couple_info.name"
                    render={({ messages }) => {
                      return messages
                        ? Object.entries(messages).map(([type, message]) => (
                            <p
                              key={type}
                              className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                            >
                              {message}
                            </p>
                          ))
                        : null;
                    }}
                  />
                </div>
                <div className="mt-3">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Số điện thoại <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      placeholder="Vui lòng nhập Số điện thoại"
                      type="tel"
                      className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                      {...register("couple_info.phone", {
                        required: "Không để trống",
                        pattern: {
                          value: /^(0[35789]{1}[0-9]{8})$/,
                          message: "Vui lòng nhập đúng số điện thoại",
                        },
                        onChange: (e) => {
                          // setName(e.target.value);
                        },
                      })}
                    />
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="couple_info.phone"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <div className="grid grid-cols-6 gap-2">
                    <div className="col-span-2">
                      <label className="text-[#5BC2DC] font-semibold-so">
                        Ngày <span className="text-[#f53a3a]">(*)</span>
                      </label>
                      <div className="rounded-lg bg-white mt-1 border-itemlogin">
                        <input
                          type="tel"
                          placeholder="Nhập Ngày"
                          className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                          min="1"
                          max="31"
                          {...register("couple_info.day", {
                            required: "Không để trống",
                            onChange: (e) => {
                              setDayCouple(e.target.value);
                            },
                          })}
                        />
                      </div>
                      <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                        <ErrorMessage
                          errors={errors}
                          name="couple_info.day"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p
                                      key={type}
                                      className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                                    >
                                      {message}
                                    </p>
                                  )
                                )
                              : null;
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <label className="text-[#5BC2DC] font-semibold-so">
                        Tháng <span className="text-[#f53a3a]">(*)</span>
                      </label>
                      <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                        <select
                          id="day"
                          {...register("couple_info.month", {
                            required: required ? "Không để trống" : required,
                            onChange: (e) => {
                              setMonthCouple(e.target.value);
                            },
                          })}
                          className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                        >
                          <option value="" selected>
                            Tháng
                          </option>
                          <option value="1">Tháng 1</option>
                          <option value="2">Tháng 2</option>
                          <option value="3">Tháng 3</option>
                          <option value="4">Tháng 4</option>
                          <option value="5">Tháng 5</option>
                          <option value="6">Tháng 6</option>
                          <option value="7">Tháng 7</option>
                          <option value="8">Tháng 8</option>
                          <option value="9">Tháng 9</option>
                          <option value="10">Tháng 10</option>
                          <option value="11">Tháng 11</option>
                          <option value="12">Tháng 12</option>
                        </select>
                        <div className="absolute right-0 top-1/2 -translate-y-1/2">
                          <img src={DOWN} className="mr-2" />
                        </div>
                      </div>
                      <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                        <ErrorMessage
                          errors={errors}
                          name="couple_info.month"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p
                                      key={type}
                                      className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                                    >
                                      {message}
                                    </p>
                                  )
                                )
                              : null;
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-2">
                      <label className="text-[#5BC2DC] font-semibold-so">
                        Năm <span className="text-[#f53a3a]">(*)</span>
                      </label>
                      <div className="rounded-lg bg-white mt-1 border-itemlogin">
                        <input
                          type="tel"
                          placeholder="Nhập Năm"
                          className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                          {...register("couple_info.year", {
                            required: "Không để trống",
                            onChange: (e) => {
                              setYearCouple(e.target.value);
                            },
                          })}
                          min="1900"
                          max="2100"
                        />
                      </div>
                      <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                        <ErrorMessage
                          errors={errors}
                          name="couple_info.year"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p
                                      key={type}
                                      className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                                    >
                                      {message}
                                    </p>
                                  )
                                )
                              : null;
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-6 text-center">
                      {!isValidDateCouple && (
                        <p style={{ color: "red" }}>Ngày không hợp lệ</p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-2">
                    <div className="col-span-6">
                      <label className="text-[#5BC2DC] font-semibold-so">
                        Giới tính <span className="text-[#f53a3a]">(*)</span>
                      </label>
                      <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                        <select
                          id="day"
                          {...register("couple_info.gender", {
                            required: required
                              ? "Không được để trống"
                              : required,
                          })}
                          className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                        >
                          <option value="" selected>
                            Giới tính
                          </option>
                          <option value="male">Nam</option>{" "}
                          <option value="female">Nữ</option>
                          <option value="other">Khác</option>
                        </select>
                        <div className="absolute right-0 top-1/2 -translate-y-1/2">
                          <img src={DOWN} className="mr-2" />
                        </div>
                      </div>
                      <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                        <ErrorMessage
                          errors={errors}
                          name="couple_info.gender"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p
                                      key={type}
                                      className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                                    >
                                      {message}
                                    </p>
                                  )
                                )
                              : null;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Email <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="font-normal z-0 text-[red] text-[13px] text-left">
                    Vui lòng điền email chính xác, BTC D-Joy sẽ gửi mail cho bạn
                  </div>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      placeholder="Vui lòng nhập email"
                      className="w-full px-3 h-12 font-light-so  text-[14px] font-light-so rounded-2xl input-hidden text-left"
                      {...register("couple_info.email", {
                        required: "Không để trống",
                        pattern: {
                          value:
                            /^[A-Za-z][a-z0-9._%+-]+@(gmail\.com|outlook\.com|yahoo\.com|yahoo\.com.vn)$/,
                          message:
                            "Vui lòng nhập kí tự thường đúng email @gmail.com hoặc @outlook.com hoặc @yahoo.com, @yahoo.com.vn",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                  <ErrorMessage
                    errors={errors}
                    name="couple_info.email"
                    render={({ messages }) => {
                      return messages
                        ? Object.entries(messages).map(([type, message]) => (
                            <p
                              key={type}
                              className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                            >
                              {message}
                            </p>
                          ))
                        : null;
                    }}
                  />
                </div>

                <div className="mt-3">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Tên Couple
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      placeholder="Vui lòng nhập Tên Couple"
                      className="w-full px-3 h-12 font-light-so  text-[14px] font-light-so rounded-2xl input-hidden text-left"
                      {...register("couple_name")}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Bạn cặp đã chơi Pickleball được bao lâu{" "}
                    <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                    <select
                      id="couple_info.player"
                      {...register("couple_info.player", {
                        required: "Không được để trống",
                      })}
                      className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                    >
                      <option value="" selected>
                        Chọn câu trả lời
                      </option>
                      <option value="0">Mới tập chơi</option>{" "}
                      <option value="2">Đã chơi được 1 - 3 tháng</option>
                      <option value="3">Đã chơi được 3 - 6 tháng</option>
                      <option value="1">Đã chơi được hơn 6 tháng</option>
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>

                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="couple_info.player"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            ) : typeJoin == "0" ? (
              <Fragment>
                <div className="mt-3">
                  <div className="col-span-6">
                    <label className="text-[#5BC2DC] font-semibold-so">
                      Bạn muốn ghép cặp với giới tính nào?{" "}
                      <span className="text-[#f53a3a]">(*)</span>
                    </label>
                    <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                      <select
                        id="day"
                        {...register("reason_play", {
                          required: required ? "Không được để trống" : required,
                        })}
                        className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                      >
                        <option value="" className="" selected>
                          Giới tính
                        </option>
                        <option value="male">Nam</option>{" "}
                        <option value="female">Nữ</option>
                        <option value="other">Khác</option>
                      </select>
                      <div className="absolute right-0 top-1/2 -translate-y-1/2">
                        <img src={DOWN} className="mr-2" />
                      </div>
                    </div>
                    <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                      <ErrorMessage
                        errors={errors}
                        name="reason_play"
                        render={({ messages }) => {
                          return messages
                            ? Object.entries(messages).map(
                                ([type, message]) => (
                                  <p
                                    key={type}
                                    className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                                  >
                                    {message}
                                  </p>
                                )
                              )
                            : null;
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Sở thích vào thời gian rảnh
                    <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      placeholder="Sở thích vào thời gian rảnh"
                      className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                      {...register("hoppy", {
                        required: "Không để trống",
                        onChange: (e) => {
                          // setName(e.target.value);
                        },
                      })}
                    />
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="hoppy"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Loại nhạc yêu thích
                    <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      placeholder="Loại nhạc yêu thích"
                      className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                      {...register("favorite_music", {
                        required: "Không để trống",
                        onChange: (e) => {
                          // setName(e.target.value);
                        },
                      })}
                    />
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="favorite_music"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            ) : null}

            <div className="mt-5">
              <div>
                <input
                  type="checkbox"
                  placeholder=""
                  {...register("consent", {
                    required: "Vui lòng đồng ý điều khoản",
                    onChange: (e) => {
                      setCheck(!check);
                    },
                  })}
                ></input>
                <span className="font-light-so text-[14px] ml-1">
                  Tôi đã đọc{" "}
                  <span
                    className="font-medium-so text-[#4E9ABB]"
                    onClick={() => checkPrize()}
                  >
                    {" "}
                    Điều khoản sử dụng
                  </span>{" "}
                  và đồng ý với nội dung này. 
                </span>
              </div>
              <div className="text-[red] text-center font-light-so">
                {!check ? errorCheck : ""}
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="consent"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            {!data?.collections?.disable && (
              <div className="mt-6 flex justify-around">
                {!data?.collected_data && (
                  <div className="flex justify-center">
                    {isLoading ? (
                      <div className="bg-[#5BC2DC] text-center w-40 px-8 font-semibold-so py-[12px] text-[14px] rounded-lg text-white">
                        <LoadingButton />
                      </div>
                    ) : (
                      <button className="bg-[#5BC2DC] w-40 px-8 font-semibold-so py-3 text-[14px] rounded-lg text-white">
                        {labelButton}
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </form>
          <div className="mt-3 mb-10 relative z-50 font-light-so text-[#424242] text-[12px] text-center px-2">
            Gọi điện cho Hotline
            <span className="text-[#4E9ABB] text-[14px] font-medium-so">
              {" "}
              <a href={`tel:${data?.hotline}`}>{data?.hotline} </a>
            </span>
            để được hỗ trợ
          </div>
        </div>
      </HeaderFrame>
      <PopupTerm isShowPopup={isShowPopup} setShowPopup={setShowPopup} />
      <PopupError
        isShow={isShowAge}
        messError={errAge}
        handleClose={handleClose}
        labelBtn="Đồng ý"
      />
    </div>
  );
}
