import "../../../assets/css/Summer.css";
import { Fragment, useEffect, useState } from "react";
import Man from "../../../components/Game/Summer/Man";
import { useLocation, useNavigate } from "react-router-dom";
import { GameServices } from "../../../services/apiService/GameService";
import PopupGeneral from "./PopupGameSummer/PopupGeneral";
import BACKHEADER from "../../../assets/image/Header/back-header.png";
import UP from "../../../assets/image/Game/Summer/up.png";
import DOWN from "../../../assets/image/Game/Summer/down.png";
import TAMGIAC from "../../../assets/image/Game/LuckyBox/tamgiac.png";
import { DetailsDragonSnake, GetSaleID } from "../../../models/GameModel";
import PopupJigSaw from "./PopupGameSummer/PopupJigSaw";
import PopupTC from "./PopupGameSummer/PopupTC";
import Loading from "../../../components/Loading/Loading";
import PopupJigSawSuccess from "./PopupGameSummer/PopupJigSawSuccess";
import PopupError from "../../../components/Popup/PopupError";

let man = [1, 2, 3, 4, 5];
export default function Summer() {
  const [isLegRight, setIsLegRight] = useState(false);
  const [isLeg, setisRight] = useState(false);
  const [numberFirstLocation, setNumberFirstLocation] = useState(3);
  const [isAction, setIsAction] = useState<boolean>(false);
  const [changeBody, setChangeBody] = useState(false);
  const [isHideBox, setIsHideBox] = useState(false);
  const [isDisableAllButton, setIsDisableAllButton] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const campaign_id = params.get("campaign_id") as string;
  const [lineId, setLineID] = useState(0);
  const [saleId, setSaleID] = useState(0);
  const [statusStep, setStatusStep] = useState("center");
  const [dataGame, setDataGame] = useState<DetailsDragonSnake>();
  const [dataGift, setGift] = useState<any>([]);
  const [detailsGift, setDetailsGift] = useState();
  const [dataTC, setTC] = useState("");
  const [isPopupIncome, setIsPopupIncome] = useState(false);
  const [isPopupTC, setIsPopupTC] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [lstSaleID, setLstSaleID] = useState<GetSaleID>();
  const checkNumberGame = localStorage.getItem("CHECKGAME");
  const handleAction = () => {
    if (lstSaleID?.total_remaining_draw !== 0 && totalRemaining !== 0) {
      setIsDisableAllButton(true);
      if (!isDisableAllButton) {
        countDraw(saleId, parseInt(campaign_id), lineId);
      }
    }
  };
  const handleStep = (e: string) => {
    if (lstSaleID?.total_remaining_draw !== 0 && totalRemaining !== 0) {
      setIsDisableAllButton(true);
      if (!isDisableAllButton) {
        if (e == "left") {
          if (numberFirstLocation !== 1) {
            setStatusStep(e);
            setTimeout(() => {
              setNumberFirstLocation(numberFirstLocation - 1);
              setStatusStep("center");
              setIsDisableAllButton(false);
            }, 400);
          } else {
            setIsDisableAllButton(false);
          }
        } else {
          if (numberFirstLocation !== 5) {
            setStatusStep(e);
            setTimeout(() => {
              setNumberFirstLocation(numberFirstLocation + 1);
              setStatusStep("center");
              setIsDisableAllButton(false);
            }, 400);
          } else {
            setIsDisableAllButton(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    getDataGame();
    // localStorage.removeItem("GIFT_WHEEL");
    if (parseInt(checkNumberGame as string) == 0) {
      window.location.reload();
      localStorage.removeItem("CHECKGAME");
    }
  }, []);
  const [checkJigSaw, setCheckJigSaw] = useState(false);
  const getDataGame = () => {
    GameServices.GetJoinGamePlantTree(id as string)
      .then((res: any) => {
        setDataGame(res?.game_data);
        setGift(res?.gifts);
        const checkJigSaw =
          res?.gifts?.filter((x: any) => x?.quantity === 0)?.length > 0;
        setCheckJigSaw(checkJigSaw);
        setTC(res?.content_details);
        localStorage.setItem("GAME_DATA_LOCAL", JSON.stringify(res?.game_data));
      })
      .catch((err) => {});
  };
  const handleClosePopup = () => {
    if (!isDisableAllButton) {
      setIsPopupIncome(!isPopupIncome);
    }
  };
  const handleClosePopupJigSaw = () => {
    setPopupJigSaw(!popupJigSaw);
    getDataGame();
  };
  const handleCloseTC = () => {
    if (!isDisableAllButton) {
      setIsPopupTC(!isPopupTC);
    }
  };
  const handleOpenPhoto = () => {
    if (!isDisableAllButton) {
      navigation(
        `/guide-page-picture/0?back=summer?id=${id}&campaign_id=${campaign_id}`
      );
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getAllSaleIdSummer(parseInt(campaign_id));
  }, []);

  const getAllSaleIdSummer = (campaign_id: number) => {
    GameServices.getAllSaleIdSummer(campaign_id)
      .then((res: any) => {
        console.log(res);
        const filterGame = res?.lst_game_summer?.filter(
          (x: any) => x.remaining_draw !== 0
        );
        filterGame?.sort((a: any, b: any) => a.sale_id - b.sale_id);
        console.log(filterGame);
        if (filterGame) {
          setSaleID(filterGame[0]?.sale_id);
          getCalculator(filterGame[0]?.sale_id, campaign_id);
        } else {
          setIsDisableAllButton(true);
        }
        setLstSaleID(res);
        setTotalRemaining(res?.total_remaining_draw);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: number | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
        } else {
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineID(itemWin?.line_id);
          setDetailsGift(itemWin);
        }
      })
      .catch((err) => {});
  };
  const countDraw = async (
    sale_id: number,
    campaign_id: number,
    lineId: number
  ) => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id.toString(),
      line_id: lineId,
    };
    GameServices.CountSummerDrawApi(data)
      .then((res: any) => {
        setIsAction(true);
        setTimeout(() => {
          setIsLegRight(true);
        }, 200);
        setTimeout(() => {
          setisRight(true);
          setIsLegRight(false);
        }, 400);
        setTimeout(() => {
          setChangeBody(true);
          setIsHideBox(true);
        }, 3000);
        setTimeout(() => {
          setisRight(false);
          setTimeout(() => {
            setIsLegRight(true);
          }, 800);
          setTimeout(() => {
            setIsAction(false);
            navigation(`/threejs?id=${id}&campaign_id=${campaign_id}`);
          }, 1100);
          sendSms(saleId, campaign_id, lineId);
        }, 4800);
        const bottleCap =
          res?.lucky_draw_list?.list_gift_immediate[0]?.list_gift_immediate[0];
        localStorage.setItem("GIFT_WHEEL", JSON.stringify(detailsGift));
        localStorage.setItem("LUCKY_CODE", JSON.stringify(res?.exchange_code));
        localStorage.setItem("BOTTLE_CAP", JSON.stringify(bottleCap));
        setTotalRemaining(totalRemaining - 1);
      })
      .catch((err) => {
        setMessageError(err);
        setIsError(true);
        setIsDisableAllButton(false);
      });
  };

  const sendSms = async (
    sale_id: number,
    campaign_id: number,
    lineId: number
  ) => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id.toString(),
      line_id: lineId,
    };
    GameServices.SendSMSSummer(data)
      .then((res: any) => {})
      .catch((err) => {});
  };

  const [popupJigSaw, setPopupJigSaw] = useState(false);
  const [isError, setIsError] = useState(false);
  const [numberJigSaw, setNumberJigSaw] = useState(0);
  const [messageError, setMessageError] = useState("");
  const handleJigSaw = () => {
    setIsPopupIncome(false);
    GameServices.postJigSaw(id)
      .then((res: any) => {
        setNumberJigSaw(res?.number_of_pieces);
        setPopupJigSaw(true);
      })
      .catch((err) => {
        setMessageError(err);
        setIsError(true);
      });
  };
  const handleNext = () => {
    setPopupJigSaw(false);
    getDataGame();
  };

  const handleClose = () => {
    setIsError(false);
  };
  const [isLstTC, setIsLstTC] = useState(false);
  const handleOpenListTC = () => {
    if (!isDisableAllButton) {
      setIsLstTC(!isLstTC);
    }
  };
  const handleClickGift = (e: string) => {
    if (!isDisableAllButton) {
      navigation(e);
    }
  };
  return (
    <div
      className=""
      style={{
        backgroundImage: `url(${dataGame?.background_game_image_url})`,
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {isLoading && <Loading />}
      <div className="absolute z-20 top-3 right-3 bg-white rounded-lg w-[70px] py-2 flex flex-col items-center justify-center">
        <div className=""> </div>
        <div className=" w-16" onClick={handleCloseTC}>
          <img src={dataGame?.tnc_icon_url} />
        </div>
        {isLstTC ? (
          <Fragment>
            <div
              className=" w-16"
              onClick={() =>
                handleClickGift(
                  `/list-receive-customer/prize/${campaign_id}?type_campaign=summer&periodic_id=${id}`
                )
              }
            >
              <img src={dataGame?.prize_image_url} />
            </div>
            <div
              className=" w-16"
              onClick={() =>
                handleClickGift(
                  `/list-receive-customer/gift/${campaign_id}?type_campaign=summer&periodic_id=${id}`
                )
              }
            >
              <img src={dataGame?.gift_image_url} />
            </div>
          </Fragment>
        ) : null}

        <div
          onClick={handleOpenListTC}
          className="w-[60px] relative z-30 h-4 border-tc-summer rounded-xl flex justify-center items-center"
        >
          <img src={isLstTC ? UP : DOWN} />
        </div>
      </div>

      <div
        className="absolute top-5 left-3 w-10"
        onClick={() => {
          !isDisableAllButton &&
            navigation(`/intro-summer?id=${id}&campaign_id=${campaign_id}`);
        }}
      >
        <img src={dataGame?.back_button_image_url} />
      </div>
      <div className="relative top-20 flex justify-center">
        <img src={dataGame?.title_image_url} className="relative top-0" />
      </div>
      <div className="relative top-[10%] grid w-full grid-cols-4 px-5">
        <div className="col-span-1 relative top-2 flex justify-center animation-box-summer1">
          <img
            src={dataGame?.cloud_image_url}
            className={`${
              numberFirstLocation == 1 && isHideBox ? "hidden" : "block"
            } w-20`}
          />
        </div>
        <div
          className={`col-span-1 relative top-0 flex justify-center animation-box-summer2`}
        >
          <img src={dataGame?.cloud_image_url} className="w-20" />
        </div>
        <div
          className={`col-span-1 relative top-5 flex justify-center animation-box-summer3`}
        >
          <img src={dataGame?.cloud_image_url} className="w-20" />
        </div>
        <div className="col-span-1 relative top-3 flex justify-center animation-box-summer1">
          <img
            src={dataGame?.cloud_image_url}
            className={`${
              numberFirstLocation == 5 && isHideBox ? "hidden" : "block"
            } w-20`}
          />
        </div>
      </div>
      <div className="relative top-[6%] grid w-full grid-cols-3 px-14">
        <div className="col-span-1 relative top-2 flex justify-center animation-box-summer3">
          <img
            src={dataGame?.cloud_image_url}
            className={`${
              numberFirstLocation == 2 && isHideBox ? "hidden" : "block"
            } w-20`}
          />
        </div>
        <div className="col-span-1 relative top-0 flex justify-center animation-box-summer2">
          <img
            src={dataGame?.cloud_image_url}
            className={`${
              numberFirstLocation == 3 && isHideBox ? "hidden" : "block"
            } w-20`}
          />
        </div>
        <div className="col-span-1 relative top-5 flex justify-center animation-box-summer1">
          <img
            src={dataGame?.cloud_image_url}
            className={`${
              numberFirstLocation == 4 && isHideBox ? "hidden" : "block"
            } w-20`}
          />
        </div>
      </div>

      <div className="relative top-[16%] grid grid-cols-5 px-5">
        {man?.map((number: number, index: number) => (
          <div key={index} className="col-span-1">
            {numberFirstLocation === index + 1 && (
              <Man
                statusStep={statusStep}
                changeBody={changeBody}
                isLeg={isLeg}
                isLegRight={isLegRight}
                isAction={isAction}
              />
            )}
          </div>
        ))}
      </div>
      <div className="fixed bottom-9 z-10 w-full grid grid-cols-11">
        <div className="relative col-span-4"></div>
        <div className="relative col-span-3">
          <button className="relative z-10 left-1/2 -translate-x-1/2">
            <button
              onClick={() => handleStep("left")}
              className="absolute z-20 top-1/2 -translate-y-1/2 -left-8"
            >
              <img
                src={dataGame?.left_enable_button_image_url}
                className={`${
                  isDisableAllButton ||
                  lstSaleID?.total_remaining_draw == 0 ||
                  totalRemaining === 0
                    ? "filter grayscale"
                    : ""
                } w-14 relative z-20`}
              />
            </button>
            <img
              src={dataGame?.button_enable_play_image_url}
              onClick={handleAction}
              className={`${
                isDisableAllButton ||
                lstSaleID?.total_remaining_draw == 0 ||
                totalRemaining === 0
                  ? "filter grayscale"
                  : ""
              } relative z-30 w-10/12 left-1/2 -translate-x-1/2`}
            />
            <div className="absolute z-10 -bottom-[42px] w-32 left-1/2 -translate-x-1/2">
              <img src={dataGame?.result_icon_url} className="w-" />
              <div className="absolute leading-3 text-[10px] text-white text-center top-1/2 -translate-y-1/2 w-full">
                Bạn có{" "}
                {totalRemaining.toString().length < 2
                  ? "0" + totalRemaining
                  : totalRemaining}{" "}
                lượt xác
                <br /> định trúng thưởng
              </div>
            </div>
            <button
              onClick={() => handleStep("right")}
              className="absolute z-20 top-1/2 -translate-y-1/2 -right-8"
            >
              <img
                src={dataGame?.right_enable_button_image_url}
                className={`${
                  isDisableAllButton ||
                  lstSaleID?.total_remaining_draw == 0 ||
                  totalRemaining === 0
                    ? "filter grayscale"
                    : ""
                } w-14`}
              />
            </button>
          </button>
        </div>
        <div className="relative col-span-4"></div>
      </div>
      <button
        className="w-20 fixed bottom-3 z-20 left-3"
        onClick={handleClosePopup}
      >
        <img src={dataGame?.quest_icon_url} />
        {!checkJigSaw && (
          <div className="w-3 h-3 absolute top-0 right-3 bg-red-500 rounded-xl"></div>
        )}
      </button>
      <button className="fixed bottom-3 z-20 right-2" onClick={handleOpenPhoto}>
        <div className="relative animation-takephoto-summer bottom-0 text-[7px] w-full font-regular-so border-luckyBox rounded-full text-center bg-[#FAF4BF] p-1 animation_label-takephoto">
          <div className="font-bold-so">TÌM LƯỢT XÁC ĐỊNH</div>
          <div className="font-bold-so">TRÚNG THƯỞNG TẠI ĐÂY</div>
          <div className="absolute -bottom-[10px] left-1/2 -translate-x-1/2">
            <img src={TAMGIAC} />
          </div>
        </div>
        <img src={dataGame?.join_button_url} className="w-20 " />
      </button>
      <PopupGeneral
        isShow={isPopupIncome}
        bgGame={dataGame?.background_game_image_url}
        bgPopup={dataGame?.popup_background_image_url}
        title="THU NHẬP"
      >
        <PopupJigSaw
          dataGame={dataGame!}
          dataGift={dataGift}
          handleClosePopup={handleClosePopup}
          handleJigSaw={handleJigSaw}
        />
      </PopupGeneral>
      <PopupGeneral
        isShow={popupJigSaw}
        bgGame={dataGame?.background_game_image_url}
        bgPopup={dataGame?.popup_background_image_url}
        title="THU NHẬP"
      >
        <PopupJigSawSuccess
          numberJigSaw={numberJigSaw}
          dataGame={dataGame!}
          dataGift={dataGift}
          handleClosePopup={handleClosePopupJigSaw}
          handleJigSaw={handleJigSaw}
          handleNext={handleNext}
        />
      </PopupGeneral>
      <PopupGeneral
        isShow={isPopupTC}
        bgGame={dataGame?.background_game_image_url}
        bgPopup={dataGame?.home_screen_url}
        title="THỂ LỆ"
      >
        <PopupTC
          dataTC={dataTC}
          dataGame={dataGame!}
          handleCloseTC={handleCloseTC}
        />
      </PopupGeneral>
      <PopupError
        isShow={isError}
        messError={messageError}
        handleClose={handleClose}
        labelBtn="Đồng ý"
      />
    </div>
  );
}
