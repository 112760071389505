import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GameServices } from "../../../services/apiService/GameService";
import { DetailsDragonSnake } from "../../../models/GameModel";
import { BACK_GLOBAL } from "../../../services/localService/localService";
import Loading from "../../../components/Loading/Loading";

export default function IntroDragon() {
  const navigation = useNavigate();
  const { id } = useParams();
  const [dataGame, setDataGame] = useState<DetailsDragonSnake>();
  const [disable, setDisable] = useState(false);
  const handleGoToPlayGame = () => {
    localStorage.setItem(BACK_GLOBAL, `dragon_snake?id=${id}`);
    getFloorGame();
  };
  const getFloorGame = () => {
    GameServices.GetJoinGamePlantTree(id as string)
      .then((res: any) => {
        navigation(`/dragon_snake?id=${id}`);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setDisable(true);
    GameServices.GetGamePlantTree(id as string)
      .then((res: any) => {
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
        setDataGame(res?.game_data);
      })
      .catch((err) => {})
      .finally(() => {
        setDisable(false);
      });
  }, []);

  return (
    <div className="relative top-0">
      {disable ? (
        <Loading />
      ) : (
        <div>
          <img src={dataGame?.home_screen_url} className="relative w-full" />
          <button className="absolute top-[50%] -translate-y-[83%] w-full">
            <img
              onClick={handleGoToPlayGame}
              src={dataGame?.join_button_url}
              className="w-[43%] relative left-1/2 -translate-x-1/2"
            />
          </button>
          <div className="fixed bottom-0">
            <img src={dataGame?.frame_icon_url} />
          </div>
        </div>
      )}
    </div>
  );
}
