import { Fragment, useEffect, useState } from "react";
import TEN_LUA from "../../assets/image/Popup/countdown.png";
import PHONE from "../../assets/image/Popup/phone.png";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate } from "react-router-dom";
import { homeServices } from "../../services/apiService/homeService";
import { ListReceipt } from "../../models/GameModel";
import PopupConfirm from "../../pages/GuideTakeAPhoto/UrgentPageReviewBill/PopupConfirm";
import ErrorBill from "../../pages/GuideTakeAPhoto/UrgentPageReviewBill/ErrorBill";
import PopupConfirmSting from "../../pages/GuideTakeAPhoto/UrgentPageReviewBill/PopupConfirmSting";
import BTN_HOTLINE from "../../assets/image/Popup/bg-hotline.png";
import DRAGONLOADING from "../../assets/image/Game/DragonSnake/dragonloading.png";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function CountDown() {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const review_image = localStorage.getItem("REVIEW_IMG");
  const sale_id = JSON.parse(localStorage.getItem("SALE_ID") || "{}");
  const navigation = useNavigate();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [count, setCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [messError, setMessError] = useState("");
  const [isConfirm, setConfirm] = useState(false);
  const [listReceipt, setListReceipt] = useState<ListReceipt | undefined>(
    undefined
  );

  const [isCampaignSting, setIsCampaignSting] = useState(false);
  const [checkLast, setCheckLast] = useState(false);
  const [secondCount, setSecondCount] = useState(60);
  const [checkCampaign, setCheckCampaign] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleRecaptchaExecution = async (action: any) => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha is not yet available");
      return null;
    }
    return await executeRecaptcha(action);
  };
  useEffect(() => {
    if (!isError) {
      const fetchData = async () => {
        if ([2, 10, 20, 30, 40, 50, 59].includes(seconds)) {
          let lastCall = false;
          if (minutes === 0 && seconds === 2 && count === 1) {
            lastCall = true;
            setCheckLast(true);
          }

          try {
            const token_captcha = await handleRecaptchaExecution("form_submit");

            const res: any = await homeServices.RecallSubmitReceiptApi(
              sale_id as string,
              lastCall,
              token_captcha as any
            );
            console.log(res);
            const checkCampaignSting = res?.rewards?.filter(
              (x: any) =>
                x.campaign_type.toLowerCase().includes("special") ||
                x.campaign_type.toLowerCase().includes("aqua")
            );

            const hasSting = res?.rewards?.some(
              (x: any) => x.campaign_type === "special"
            );
            const hasAqua = res?.rewards?.some(
              (x: any) => x.campaign_type === "aqua"
            );

            if (res?.auto) {
              setConfirm(true);
              setMinutes(0);
              setSeconds(0);
              setListReceipt(res);
              if (checkCampaignSting.length > 0 && !(hasSting && hasAqua)) {
                setIsCampaignSting(true);
                setCheckCampaign(hasSting ? "sting_pickup" : "aqua_pickup");
              } else {
                setIsCampaignSting(false);
              }
              clearInterval(interval); // Dừng đếm khi cần
            }
          } catch (err: any) {
            setMessError(err);
            setIsError(true);
          }
        }
      };

      const interval = setInterval(() => {
        setSeconds((prev) => {
          if (prev > 0) return prev - 1;
          if (minutes > 0) {
            setMinutes((m) => m - 1);
            return 59;
          }
          if (count === 1) {
            clearInterval(interval);
            return 0;
          } else if (count === 0) {
            setMinutes(1);
            setSeconds(0);
            setSecondCount(60);
            setCount(1);
          }
          return 0;
        });

        fetchData(); // Gọi hàm async
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [seconds, minutes, count, isError]);

  const statusConfirm = localStorage.getItem("STATUS_CONFIRM");
  useEffect(() => {
    if (statusConfirm === "TRUE") {
      navigation("/");
    }
  }, []);

  const renderTime = ({ remainingTime }: any) => {
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;
    if (remainingTime === 0 && count === 1) {
      if (!isConfirm) {
        // navigation("/bill-invalid");
      }
      return (
        <div className="timer text-[#4E9ABB] font-semibold-so text-[20px]">
          Hết giờ ...
        </div>
      );
    }
    return (
      <div className="countdown-text text-[#4E9ABB] text-[36px] font-bold-so flex justify-center">{`${minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`}</div>
    );
  };
  const [checkPush, setCheckPush] = useState(false);

  const handleUrgent = async () => {
    setCheckPush(true);
    const token_recaptcha = await handleRecaptchaExecution("form_submit");
    if (!checkPush) {
      homeServices
        .PushReviewReceiptApi(
          sale_id !== undefined ? sale_id : ("" as string),
          token_recaptcha as string
        )
        .then((res: any) => {
          navigation("/urgent-page");
        })
        .catch((err) => {});
    }
  };
  const handleClose = () => {
    setIsError(false);
    navigation("/guide-take-photo/0");
  };
  return (
    <div className="background-result min-h-screen relative">
      {isError ? (
        <ErrorBill
          title={`${checkLast ? "" : "Hóa đơn không hợp lệ"}`}
          isShow={isError}
          messError={messError}
          handleClose={handleClose}
          labelBtn="Đồng ý"
          imageError={review_image as string}
        />
      ) : (
        <div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center ">
          <div className="font-bold-so text-[#4E9ABB] text-center flex">
            Vui lòng đợi {count === 1 && "thêm"}
          </div>
          <div
            className={`${count === 1 ? "mt-0" : "mt-10"} flex justify-center `}
          >
            <img src={TEN_LUA} className="w-10/12" />
          </div>
          <div className="font-regular-so text-[#424242] text-[14px] text-center px-10">
            {count === 0
              ? "Mong quý khách thông cảm, hóa đơn của bạn đang được hệ thống xử lý"
              : "Mong quý khách thông cảm, hệ thống cần thêm thời gian để đọc hóa đơn"}
          </div>
          <div className="mt-16">
            <CountdownCircleTimer
              isPlaying
              duration={secondCount}
              colors={["#4E9ABB", "#4E9ABB", "#4E9ABB", "#bdbdbd"]}
              colorsTime={[secondCount, 6, 3, 0]}
              onComplete={() => ({ shouldRepeat: true, delay: 1 })}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
          {count === 1 && (
            <div className="mt-10">
              <button
                onClick={() => {
                  handleUrgent();
                }}
                className={`${
                  checkPush ? "bg-[#bdbdbd]" : "bg-[#5BC2DC]"
                }  w-60 shadow-item text-[#FFFFFF] font-semibold-mon py-[20px] text-center rounded-[15px] border-first`}
              >
                Ưu tiên duyệt
              </button>
            </div>
          )}

          <div className="flex items-center mt-20">
            <div>
              <img src={PHONE} />
            </div>
            <div className="text-[#2D4E68] font-light-so ml-2">
              Liên hệ Hotline{" "}
              <span className="font-semibold-so">
                <a href={`tel:${hotline}`}>{hotline}</a>
              </span>
            </div>
          </div>
        </div>
      )}

      {listReceipt && (
        <Fragment>
          {isCampaignSting ? (
            <PopupConfirmSting
              isShow={isConfirm}
              listReceipt={listReceipt}
              setConfirm={setConfirm}
              game_type={checkCampaign}
            />
          ) : (
            <PopupConfirm
              isShow={isConfirm}
              listReceipt={listReceipt}
              setConfirm={setConfirm}
            />
          )}
        </Fragment>
      )}
      <ContactPhoneZalo />
    </div>
  );
}
