import TITLE_POPUPSUMMER from "../../../../assets/image/Game/Summer/title-popupsummer.png";
import PopupOverlay from "../../../../components/BackgroundPopup/PopupOverLay";
import { useNavigate } from "react-router-dom";

export default function PopupGeneral({
  isShow,
  bgGame,
  bgPopup,
  children,
  title,
}: {
  isShow: boolean;
  bgGame?: string;
  bgPopup?: string;
  children: React.ReactNode;
  title: string;
}) {
  const navigation = useNavigate();
  const handleNext = () => {
    navigation("/summer");
  };
  const handleMyGift = () => {
    navigation("/list-gift");
  };
  const dataGame = JSON.parse(localStorage.getItem("GAME_DATA_LOCAL") || "{}");
  return (
    <PopupOverlay isShow={isShow}>
      <div
        style={{
          backgroundImage: `url(${bgPopup})`,
          width: "90vw",
          backgroundPosition: "container",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="relative z-10 left-1/2 -translate-x-1/2"
      >
        {children}
      </div>
      <div className="absolute z-0 -top-[56px] w-screen left-1/2 -translate-x-1/2">
        <img src={dataGame?.popup_header_image_url} className="relative z-0" />
        <div className="absolute font-regular-coin top-1/2 -translate-y-[80%] text-center w-full text-white text-[20px]">
          {title}
        </div>
      </div>
    </PopupOverlay>
  );
}
