import { useNavigate } from "react-router-dom";
import { ListChannels } from "../../../models/HomePage";

export default function ItemCampaign({
  channel_image_url,
  channel_name,
  channel_id,
}: ListChannels) {
  const navigation = useNavigate();
  const handleRedirect = () => {
    navigation(`/list-game/chanel_id/${channel_id}`);
  };
  return (
    <div className="rounded-2xl pr-3" onClick={handleRedirect}>
      <div className="bg-[#5BC2DC] rounded-3xl grid grid-cols-6 pr-2 border-itemcampaign">
        <div className="p-[3px] col-span-2">
          <img
            src={channel_image_url}
            loading="lazy"
            alt="Image"
            className="rounded-full bg-[#F2F5F9]"
            width={50}
            height={50}
          />
        </div>
        <div className="col-span-4 text-left leading-4 text-[14px] flex items-center text-white font-regular-so">
          {channel_name}
        </div>
      </div>
    </div>
  );
}
