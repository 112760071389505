import { useEffect, useState } from "react";
import { DetailsDragonSnake } from "../../../models/GameModel";
import { GameServices } from "../../../services/apiService/GameService";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import PopupGeneral from "./PopupGameSummer/PopupGeneral";
import PopupTC from "./PopupGameSummer/PopupTC";
import { BACK_GLOBAL } from "../../../services/localService/localService";

export default function IntroSummer() {
  const [dataGame, setDataGame] = useState<DetailsDragonSnake>();
  const [isPopupTC, setIsPopupTC] = useState(false);

  const [dataTC, setTC] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const campaign_id = params.get("campaign_id");
  const navigation = useNavigate();
  useEffect(() => {
    localStorage.setItem(
      BACK_GLOBAL,
      `intro-summer?id=${id}&campaign_id=${campaign_id}`
    );
    getDataGame();
  }, []);
  const getDataGame = () => {
    GameServices.GetJoinGamePlantTree(id as string)
      .then((res: any) => {
        setDataGame(res?.game_data);
        setTC(res?.content_details);
        localStorage.setItem("GAME_DATA_LOCAL", JSON.stringify(res?.game_data));
      })
      .catch((err) => {});
  };
  const handleCloseTC = () => {
    setIsPopupTC(!isPopupTC);
  };
  const handleRedirect = () => {
    navigation(`/summer?id=${id}&campaign_id=${campaign_id}`);
  };
  return (
    <div
      className=""
      style={{
        backgroundImage: `url(${dataGame?.background_game_image_url})`,
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute top-3 right-3 w-16" onClick={handleCloseTC}>
        <img src={dataGame?.tnc_icon_url} />
      </div>
      <div
        className="absolute top-5 left-3 w-10"
        onClick={() => navigation("/")}
      >
        <img src={dataGame?.back_button_image_url} />
      </div>
      <div className="relative top-20 flex justify-center">
        <img src={dataGame?.title_image_url} className="relative top-0" />
      </div>
      <div className="flex justify-center" onClick={handleRedirect}>
        <img
          src={dataGame?.loading_screen_url}
          className="w-40 absolute top-1/2 -translate-y-1/2"
        />
      </div>
      <PopupGeneral
        isShow={isPopupTC}
        bgGame={dataGame?.background_game_image_url}
        bgPopup={dataGame?.home_screen_url}
        title="THỂ LỆ"
      >
        <PopupTC
          dataTC={dataTC}
          dataGame={dataGame!}
          handleCloseTC={handleCloseTC}
        />
      </PopupGeneral>
    </div>
  );
}
