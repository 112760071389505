import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as THREE from "three";

type ThreeSceneProps = {};

const ThreeScene: React.FC<ThreeSceneProps> = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const navigation = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const campaign_id = params.get("campaign_id");
  const checkNumberGame = localStorage.getItem("CHECKGAME");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (checkNumberGame) {
      let numberCurrent = parseInt(checkNumberGame);
      const numberLast = numberCurrent + 1;
      localStorage.setItem("CHECKGAME", numberLast.toString());
    } else {
      localStorage.setItem("CHECKGAME", "0");
    }
    const preventScroll = (e: TouchEvent) => e.preventDefault();
    document.body.addEventListener("touchmove", preventScroll, {
      passive: false,
    });

    return () => {
      document.body.removeEventListener("touchmove", preventScroll);
    };
  }, []);
  useEffect(() => {
    const scene = new THREE.Scene();
    const loadingManager = new THREE.LoadingManager();
    loadingManager.onStart = () => {};
    loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {};

    loadingManager.onLoad = () => {
      animate();
    };

    loadingManager.onError = (url) => {};
    const textureLoader = new THREE.TextureLoader(loadingManager);

    const backgroundTexture = textureLoader.load(
      "./background/bgsummer.png",
      (texture: any) => {
        texture.colorSpace = THREE.SRGBColorSpace;
      }
    );
    scene.background = backgroundTexture;
    scene.backgroundBlurriness = 0.9;
    scene.backgroundIntensity = 0.6;

    const materials = [
      "box.png",
      "box.png",
      "box.png",
      "box.png",
      "box.png",
      "box.png",
    ].map(
      (img) =>
        new THREE.MeshBasicMaterial({
          map: textureLoader.load(`./textures/${img}`),
        })
    );
    const pointLight = new THREE.PointLight(0x333333, 100, 10);
    pointLight.position.set(1, 1, 1);
    scene.add(pointLight);

    const ambientLight = new THREE.AmbientLight(0x333333, 1);
    scene.add(ambientLight);

    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, 1.3, 4);
    camera.lookAt(0, 0, 0);

    const renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    backgroundTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
    mountRef.current?.appendChild(renderer.domElement);

    const box = new THREE.Mesh(new THREE.BoxGeometry(1.5, 1.5, 1.5), materials);
    scene.add(box);

    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(20, 20),
      new THREE.MeshBasicMaterial({
        map: textureLoader.load("./background/light.png"),
        transparent: true,
        alphaTest: 0,
      })
    );
    plane.position.set(0, -2, -8);
    scene.add(plane);

    let speed = 0.001;
    const maxSpeed = 0.08;
    const acceleration = 0.02;

    let planeZ = -20;
    const targetZ = 3;
    const speedZoomZ = 0.08;

    const animate = () => {
      requestAnimationFrame(animate);
      speed = Math.min(speed + acceleration, maxSpeed);
      box.rotation.y += speed;
      plane.rotation.z += 0.009;
      // camera.position.y = 1;
      if (planeZ < targetZ) {
        planeZ += speedZoomZ;
        plane.position.z = planeZ;
        plane.position.y += 0.005;
        if (targetZ == parseFloat(planeZ.toFixed(1))) {
          backgroundTexture.dispose();
          renderer.domElement.remove();
          renderer.dispose();
          materials.forEach((material) => {
            if (material.map) {
              material.map.dispose(); // Dispose texture nếu có
            }
            material.dispose(); // Dispose material
          });

          navigation(`/result-summer?id=${id}&campaign_id=${campaign_id}`);
        }
      }
      renderer.render(scene, camera);
    };
    animate();
  }, []);

  return <div ref={mountRef} className="w-full h-screen" />;
};

export default ThreeScene;
