import React, { useEffect, useState } from "react";
interface OpenActiveCatcherModel {
  pre_number: string;
  img: string;
  img_day: string;
}
export default function OpenActiveCatcher(data: OpenActiveCatcherModel) {
  console.log(data.img_day);

  return (
    <div
      className={
        data.pre_number === "no"
          ? "relative day-gap-1"
          : data.pre_number === "left"
          ? "relative day-gap-1 ani-tranx-left"
          : "relative day-gap-1 ani-tranx-right"
      }
    >
      <img src={data.img_day} className="relative day-gap w-10 z-0" />
      <div className="rotate-gap">
        <img src={data.img} className="w-20 gap" />
      </div>
    </div>
  );
}
