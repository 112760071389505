export default function BackgroundGameFixed({
  img,
  children,
}: {
  img: any;
  children: React.ReactNode;
}) {
  return (
    <div
      className="relative top-0"
      style={{
        backgroundImage: `url(${img})`,
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        overflow: "auto",
      }}
    >
      {children}
    </div>
  );
}
