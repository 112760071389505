import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import "../../assets/css/Login.css";
import { LoginServices } from "../../services/apiService/LoginService";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import FOOTER from "../../assets/image/Login/footer.png";
import LoadingButton from "../../components/Loading/LoadingButton";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import { City, District, Ward } from "../../models/LoginModel";
import { DataRegister } from "../../models/ListReceiveCustomer";
import DOWN from "../../assets/image/Profile/down.png";
import PopupTerm from "../../components/Popup/PopupTerm";
import PopupError from "../../components/Popup/PopupError";
import { setAuthorizationSessionToken } from "../../services/apiService/configURL";

export default function Register1Component({
  goToPrevious,
  dataRegister,
  title,
  labelButton,
  handleBack,
  handleNext,
  required,
  consent,
  updateAfter,
  isLoading,
}: {
  goToPrevious: any;
  dataRegister: DataRegister;
  title: string;
  labelButton: string;
  handleBack: () => void;
  required: boolean;
  consent: boolean;
  handleNext: any;
  updateAfter: boolean;
  isLoading: boolean;
}) {
  const [isShowPopup, setShowPopup] = useState(false);
  const [isShowAge, setIsShowAge] = useState(false);
  const [errAge, setErrorAge] = useState("");
  const phone = localStorage.getItem("PHONE");
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const session_token = localStorage.getItem("SESSION_TOKEN");

  const [city, setCity] = useState<any>([]);
  const [district, setDistrict] = useState<any>([]);
  const [ward, setWard] = useState<any>([]);
  useEffect(() => {
    getCity();
    setAuthorizationSessionToken(session_token as string);
    console.log(session_token);
  }, []);
  const onSubmit = (data: any) => {
    setAuthorizationSessionToken(session_token as string);

    const age = calculateAge(`${data?.day}/${data?.month}/${data?.year}`);
    if (age > 13) {
      data["birthday"] = data?.year + "-" + data?.month + "-" + data?.day;
      if (isValidDate) {
        const params = {
          phone: phone,
        };
        const merge = Object.assign(data, params);
        goToPrevious(merge);
      }
    } else {
      setIsShowAge(true);
      setErrorAge("Bạn chưa trên 13 tuổi để tham gia chương trình");
    }
  };

  const {
    register,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });

  useEffect(() => {
    if (dataRegister) {
      getCity();
      getDistrict(dataRegister.city_id ? dataRegister.city_id : "");
      getWard(dataRegister.district_id ? dataRegister.district_id : "");
      setValue("name", dataRegister.name);
      setValue("street", dataRegister.street || "");
      setValue("year", dataRegister?.birthday?.split("-")[0]);
      setValue("day", dataRegister?.birthday?.split("-")[2]);
      setValue("gender", dataRegister.gender);
      setValue("phone", dataRegister.phone);
      setValue("email", dataRegister.email || "");
      if (dataRegister?.birthday?.split("-")[1]?.charAt(0) === "0") {
        const a = dataRegister?.birthday?.split("-")[1].split("0")[1];
        setValue("month", a);
      } else {
        setValue("month", dataRegister?.birthday?.split("-")[1]);
      }
    }
  }, [dataRegister]);

  const getCity = () => {
    LoginServices.GetCityApi()
      .then((res) => {
        setCity(res);
        setValue("city_id", dataRegister?.city_id);
      })
      .catch((err) => {});
  };
  const getDistrict = (a: string) => {
    LoginServices.GetDistrictApi(a)
      .then((res: any) => {
        setDistrict(res);
        setValue("district_id", dataRegister?.district_id);
      })
      .catch((err) => {
        setValue("district_id", "");
      });
  };
  const getWard = (a: string) => {
    LoginServices.GetWardApi(a)
      .then((res: any) => {
        setWard(res);
        setValue("ward_id", dataRegister?.ward_id);
      })
      .catch((err) => {});
  };
  const [name, setName] = useState<string>("");
  const [check, setCheck] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<string>("");
  const [errorCheck, setErrorCheck] = useState<string>("");
  const checkPrize = () => {
    setShowPopup(true);
  };
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [isLeapYear, setIsLeapYear] = useState(false);
  const [isValidDate, setIsValidDate] = useState(false);

  const checkLeapYear = (year: any) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };
  const validateDate = () => {
    if (!day || !month || !year) return true; // If no inputs, consider valid
    const daysInMonth: any = {
      1: 31,
      2: isLeapYear ? 29 : 28,
      3: 31,
      4: 30,
      5: 31,
      6: 30,
      7: 31,
      8: 31,
      9: 30,
      10: 31,
      11: 30,
      12: 31,
    };

    if (day > daysInMonth[month]) {
      return false; // Invalid day for the selected month and year
    }
    return true;
  };
  useEffect(() => {
    if (year.length === 4) {
      setIsLeapYear(checkLeapYear(Number(year)));
    } else {
      setIsLeapYear(false);
    }
  }, [year]);
  useEffect(() => {
    setIsValidDate(validateDate());
  }, [day, month, year, isLeapYear]);
  const calculateAge = (dateString: string) => {
    const [day, month, year] = dateString.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const handleClose = () => {
    setIsShowAge(!isShowAge);
  };
  return (
    <div>
      <HeaderFrame
        footer={FOOTER}
        title={title}
        iconBack={BACK_HEADER}
        handleBack={handleBack}
      >
        <div className="pb-10 px-3">
          <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Họ và tên <span className="text-[#f53a3a]">(*)</span>
              </label>
              <div className="rounded-lg bg-white mt-1 border-itemlogin">
                <input
                  placeholder="Vui lòng nhập Họ và tên"
                  className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                  {...register("name", {
                    required: "Không để trống",
                    onChange: (e) => {
                      setName(e.target.value);
                    },
                  })}
                />
              </div>
              <div className="w-full text-center text-[red] mt-1">
                {name ? "" : errorName}
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="grid grid-cols-6 gap-2">
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Ngày <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      type="tel"
                      placeholder="Nhập Ngày"
                      className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                      min="1"
                      max="31"
                      {...register("day", {
                        required: "Không để trống",
                        onChange: (e) => {
                          setDay(e.target.value);
                        },
                      })}
                    />
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="day"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Tháng <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                    <select
                      id="day"
                      {...register("month", {
                        required: required ? "Không để trống" : required,
                        onChange: (e) => {
                          setMonth(e.target.value);
                        },
                      })}
                      className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                    >
                      <option value="" selected>
                        Tháng
                      </option>
                      <option value="1">Tháng 1</option>
                      <option value="2">Tháng 2</option>
                      <option value="3">Tháng 3</option>
                      <option value="4">Tháng 4</option>
                      <option value="5">Tháng 5</option>
                      <option value="6">Tháng 6</option>
                      <option value="7">Tháng 7</option>
                      <option value="8">Tháng 8</option>
                      <option value="9">Tháng 9</option>
                      <option value="10">Tháng 10</option>
                      <option value="11">Tháng 11</option>
                      <option value="12">Tháng 12</option>
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="month"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Năm <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="rounded-lg bg-white mt-1 border-itemlogin">
                    <input
                      type="tel"
                      placeholder="Nhập Năm"
                      className=" w-full px-3 h-12 font-light-so rounded-2xl text-[14px] input-hidden text-left"
                      {...register("year", {
                        required: "Không để trống",
                        onChange: (e) => {
                          setYear(e.target.value);
                        },
                      })}
                      min="1900"
                      max="2100"
                    />
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="year"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-6 text-center">
                  {!isValidDate && (
                    <p style={{ color: "red" }}>Ngày không hợp lệ</p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-6 gap-2">
                <div className="col-span-6">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Giới tính <span className="text-[#f53a3a]">(*)</span>
                  </label>
                  <div className="border-itemlogin mt-1  text-[14px] font-light-so relative">
                    <select
                      id="day"
                      {...register("gender", {
                        required: required ? "Không được để trống" : required,
                      })}
                      className="rounded-2xl w-full px-1 text-left  text-[14px] py-[14px] input-hidden"
                    >
                      <option value="" selected>
                        Giới tính
                      </option>
                      <option value="male">Nam</option>{" "}
                      <option value="female">Nữ</option>
                      <option value="other">Khác</option>
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="gender"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">Email</label>
              <div className="rounded-lg bg-white mt-1 border-itemlogin">
                <input
                  placeholder="Vui lòng nhập email"
                  className="w-full px-3 h-12 font-light-so  text-[14px] font-light-so rounded-2xl input-hidden text-left"
                  {...register("email", {})}
                />
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">
                Nơi ở: Tỉnh/Thành <span className="text-[#f53a3a]">(*)</span>
              </label>
              <div className="border-itemlogin mt-1 relative">
                <select
                  id="province"
                  {...register("city_id", {
                    required: required ? "Không được để trống" : required,
                    onChange: (a) => {
                      getDistrict(a.target.value);
                    },
                  })}
                  className="rounded-2xl px-3 w-full text-left  text-[14px] font-light-so py-[14px] input-hidden"
                >
                  <option value="" selected key={""}>
                    Tỉnh/Thành
                  </option>
                  {city?.map((res: City) => (
                    <option value={res.city_id} key={res.city_id}>
                      {res.city}
                    </option>
                  ))}
                </select>
                <div className="absolute right-0 top-1/2 -translate-y-1/2">
                  <img src={DOWN} className="mr-2" />
                </div>
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="city_id"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="grid grid-cols-4 gap-2">
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Quận/Huyện
                  </label>
                  <div className="border-itemlogin mt-1 relative text-[14px] font-light-so">
                    <select
                      id="district"
                      {...register("district_id", {
                        // required: required ? "Không được để trống" : required,
                        onChange: (a) => {
                          getWard(a.target.value);
                        },
                      })}
                      className="px-3 w-full text-left  text-[14px] py-[14px] input-hidden rounded-2xl"
                    >
                      <option value="" selected key={""}>
                        Quận/Huyện
                      </option>
                      {district?.map((res: District) => (
                        <option value={res.district_id} key={res.district_id}>
                          {res.district}
                        </option>
                      ))}
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="district_id"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <label className="text-[#5BC2DC] font-semibold-so">
                    Phường/Xã
                  </label>
                  <div className="border-itemlogin mt-1 relative text-[14px] font-light-so">
                    <select
                      id="ward"
                      {...register("ward_id", {
                        // required: required ? "Không được để trống" : required,
                      })}
                      className="rounded-2xl px-3 w-full text-left  text-[14px] py-[14px] input-hidden"
                    >
                      <option value="" selected key={""}>
                        Phường/Xã
                      </option>
                      {ward?.map((res: Ward) => (
                        <option value={res.ward_id} key={res.ward_id}>
                          {res.ward}
                        </option>
                      ))}
                    </select>
                    <div className="absolute right-0 top-1/2 -translate-y-1/2">
                      <img src={DOWN} className="mr-2" />
                    </div>
                  </div>
                  <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                    <ErrorMessage
                      errors={errors}
                      name="ward_id"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <p
                                key={type}
                                className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                              >
                                {message}
                              </p>
                            ))
                          : null;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <label className="text-[#5BC2DC] font-semibold-so">Địa chỉ</label>
              <div className="rounded-lg bg-white mt-1 border-itemlogin">
                <input
                  placeholder="Vui lòng nhập thông tin địa chỉ"
                  className="w-full px-3 h-12 font-light-so  text-[14px] font-light-so rounded-2xl input-hidden text-left"
                  {...register("street", {
                    // required: required ? "Không được để trống" : required,
                  })}
                />
              </div>
              <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="street"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>
            </div>
            {consent && (
              <div className="mt-5">
                <div>
                  <input
                    type="checkbox"
                    placeholder=""
                    {...register("consent", {
                      required: "Vui lòng đồng ý điều khoản",
                      onChange: (e) => {
                        setCheck(!check);
                      },
                    })}
                  ></input>
                  <span className="font-light-so text-[14px] ml-1">
                    Tôi đã đọc{" "}
                    <span
                      className="font-medium-so text-[#4E9ABB]"
                      onClick={() => checkPrize()}
                    >
                      {" "}
                      Điều khoản sử dụng
                    </span>{" "}
                    và đồng ý với nội dung này. 
                  </span>
                </div>
                <div className="text-[red] text-center font-light-so">
                  {!check ? errorCheck : ""}
                </div>
                <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
                  <ErrorMessage
                    errors={errors}
                    name="consent"
                    render={({ messages }) => {
                      return messages
                        ? Object.entries(messages).map(([type, message]) => (
                            <p
                              key={type}
                              className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                            >
                              {message}
                            </p>
                          ))
                        : null;
                    }}
                  />
                </div>
              </div>
            )}

            <div className="mt-6 flex justify-around">
              <div className="flex justify-center">
                {updateAfter && (
                  <>
                    {isLoading ? (
                      <div className="flex justify-center mr-3">
                        <div className="bg-[#2D4E68] flex justify-center w-40 px-8 font-semibold-so py-3 text-[14px] rounded-lg text-white">
                          <LoadingButton />
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center mr-3">
                        <button
                          className="bg-[#2D4E68] flex justify-center w-40 px-8 font-semibold-so py-3 text-[14px] rounded-lg text-white"
                          // onClick={() => handleUpdateAfter()}
                        >
                          Cập nhật sau
                        </button>
                      </div>
                    )}
                  </>
                )}
                {isLoading ? (
                  <div className="bg-[#5BC2DC] text-center w-40 px-8 font-semibold-so py-[12px] text-[14px] rounded-lg text-white">
                    <LoadingButton />
                  </div>
                ) : (
                  <button className="bg-[#5BC2DC] w-40 px-8 font-semibold-so py-3 text-[14px] rounded-lg text-white">
                    {labelButton}
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="mt-3 mb-10 relative z-50 font-light-so text-[#424242] text-[12px] text-center px-2">
            Gọi điện cho Hotline
            <span className="text-[#4E9ABB] text-[14px] font-medium-so">
              {" "}
              <a href={`tel:${data?.hotline}`}>{data?.hotline} </a>
            </span>
            để được hỗ trợ
          </div>
        </div>
      </HeaderFrame>
      <PopupTerm isShowPopup={isShowPopup} setShowPopup={setShowPopup} />
      <PopupError
        isShow={isShowAge}
        messError={errAge}
        handleClose={handleClose}
        labelBtn="Đồng ý"
      />
    </div>
  );
}
