import { CodeGift } from "../../models/GiftModel";

export default function ItemCode({ data }: { data: CodeGift }) {
  return (
    <div className="grid grid-cols-12 mt-4 gap-1 shadow-item rounded-xl py-1">
      <div className="col-span-4 flex justify-center">
        <div className="w-28 h-28 flex justify-center">
          <img
            src={
              data.draw_banner_image_url
                ? data.draw_banner_image_url
                : data.banner_image_url
            }
            className="w-full object-cover rounded-lg"
          />
        </div>
      </div>
      <div className="col-span-8 pr-2">
        <div className="text-reponsive font-semibold-so">
          {data?.campaign_name}
        </div>
        <div className="text-[12px] text-[#8A8A8A]">
          Ngày hết hạn:{" "}
          <span className="text-[#004B93] ">{data?.final_draw_date}</span>
        </div>
        <div>
          <div className="text-[#8A8A8A] text-[12px] font-light-so">
            Mã dự thưởng quay số cuối chương trình:
          </div>
          <div className="text-[#2D4E68] text-center rounded-md bg-[#B5E0EE] w-24 border-code mt-1 py-[2px] font-semibold-so">
            {data?.code}
          </div>
        </div>
      </div>
    </div>
  );
}
