import { useEffect, useState } from "react";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { useNavigate, useParams } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import HEADER_DETAILS from "../../assets/image/Header/header-details.png";
import ICON_COIN from "../../assets/image/Home/icon-coin.png";
import PopupLogin from "../../popup/PopupLogin";
import {
  BACK_GLOBAL,
  userLocal,
} from "../../services/localService/localService";
import { setAuthorization } from "../../services/apiService/configURL";
import PopupPoint from "../../components/Popup/PopupPoint";
import TITLE from "../../assets/image/Popup/title-coin.png";
import LoadingButton from "../../components/Loading/LoadingButton";
import PopupError from "../../components/Popup/PopupError";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function DetailsExhangePoint() {
  let pathName = window.location.pathname;
  const [current, setCurrent] = useState<string>("1");
  const { id, back } = useParams();
  const navigation = useNavigate();
  const [detailsGame, setDetailsGame] = useState<any>();
  const [isConfirm, setIsConfirm] = useState<any>(false);
  const [isReward, setIsReward] = useState<any>(false);
  let { token } = userLocal.get();
  setAuthorization(token);
  const [point, setPoint] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  useEffect(() => {
    ListReceiptServices.GetDetailsExchangeGift(id as string)
      .then((res: any) => {
        setDetailsGame(res);
      })
      .catch((err) => {});
    localStorage.setItem(BACK_GLOBAL, pathName.slice(1));
  }, []);
  const handleBack = () => {
    navigation(`/${back ? back : ""}`);
  };
  const handleChangeGift = () => {
    if (!isLoading) {
      if (token) {
        setIsConfirm(true);
      } else {
        navigation("/login");
      }
    }
  };
  const handleCancel = () => {
    setIsConfirm(false);
  };
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleRecaptchaExecution = async (action: any) => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha is not yet available");
      return null;
    }
    return await executeRecaptcha(action);
  };
  const handleAgree = async () => {
    if (token) {
      setIsLoading(true);
      setAuthorization(token);
      const token_captcha = await handleRecaptchaExecution("form_submit");
      ListReceiptServices.PostExchangeGift(
        detailsGame?.reward_id,
        token_captcha as string
      )
        .then((res: any) => {
          setPoint(res.points);
          setIsReward(true);
        })
        .catch((err) => {
          setIsError(true);
          setMessage(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
      setIsConfirm(false);
    }
  };
  const handleClose = () => {
    navigation("/list-gift");
  };
  const handleClick = (e: any, status_gifts: string) => {
    setCurrent(e.target.id);
  };
  const handleClosePoint = () => {
    setIsError(false);
    setMessage("");
  };
  return (
    <div>
      <div>
        <div className="w-full h-20 bg-[#5BC2DC]">
          <img src={HEADER_DETAILS} />
        </div>
        <div className="containerNotify__background bg-[#ffffff] absolute rounded-[30px_30px_0_0] top-6 h-[96%] w-full z-10">
          <ul className="containerNotify__background-list box-border z-0">
            <div className="relative z-40">
              <div className="flex justify-start">
                <img
                  src={BACK}
                  className="w-10 relative top-3 left-3 z-40"
                  onClick={handleBack}
                />
              </div>
              <div>
                <div className="relative -top-11 z-0 h-full">
                  <div className="relative bg-[#F2FCFF]">
                    <img
                      src={
                        detailsGame?.egift_image_url
                          ? detailsGame?.egift_image_url
                          : detailsGame?.reward_image_url
                          ? detailsGame?.reward_image_url
                          : detailsGame?.gift_image_url
                      }
                      className="rounded-2xl w-full"
                    />
                    <div className="text-[24px] font-semibold-so px-3 py-2 leading-8">
                      {detailsGame?.gift_name}
                    </div>
                    <div className="flex justify-end">
                      <div className="bg-[#76DBF4] relative right-[2px] pl-[6px] w-[165px] rounded-tl-3xl rounded-br-2xl mt-0">
                        <div className="bg-[#5BC2DC] flex justify-center items-center font-light-so px-4 w-40 py-1 rounded-tl-3xl rounded-br-2xl text-white">
                          <div>
                            <img src={ICON_COIN} className="w-5" />
                          </div>
                          <div className="font-medium-so -mt-[1px] ml-2">
                            {detailsGame?.required_points}{" "}
                            <span className="text-[14px]">Pepcoin</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative top-5 px-3 mb-40">
                    <div>
                      {detailsGame?.egift ? (
                        <div className="tab-exchange-gift flex justify-between items-center align-middle">
                          <div className="flex flex-col items-center justify-center flex-auto">
                            <button
                              key="1"
                              id={"1"}
                              disabled={current === "1"}
                              onClick={(e) => handleClick(e, "quotation")}
                              className="font-medium-mon leading-4 mt-[8px] text-[11px]"
                            >
                              Điều kiện áp dụng
                            </button>
                            {current === "1" ? (
                              <div className="w-36 h-[3px] border-tab mt-[2px]"></div>
                            ) : null}
                          </div>
                          <div className="flex flex-col items-center justify-center flex-auto">
                            <button
                              key="2"
                              id={"2"}
                              disabled={current === "2"}
                              onClick={(e) => handleClick(e, "done")}
                              className="font-medium-mon leading-4 mt-[8px] text-[12px]"
                            >
                              Địa điểm áp dụng
                            </button>
                            {current === "2" ? (
                              <div className="w-36 h-[3px] border-tab mt-[2px]"></div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {current === "1" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: detailsGame?.egift_conditions,
                        }}
                        className="relative font-light-so h-full point-ul"
                        id=""
                      ></div>
                    ) : (
                      <div>
                        {detailsGame?.egift_exchange_locations?.map(
                          (res: any) => (
                            <div className="px-5 shadow-item py-2 rounded-2xl mb-2">
                              <div className="font-regular-so">
                                {res.location_name}
                              </div>
                              <div className="text-[12px] font-light-so text-[#636363] text-reponsive">
                                {res.location_address}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" z-50 flex justify-center items-center flex-col fixed bottom-4 w-full ">
              <div className=" flex items-center justify-center w-11/12">
                <div
                  className={`${
                    isLoading ? "bg-[#bdbdbd]" : "bg-[#5BC2DC]"
                  } px-1 py-4 rounded-xl w-full text-center border-first shadow-item`}
                  onClick={handleChangeGift}
                >
                  <button className="text-white ">
                    {isLoading ? (
                      <div>
                        <LoadingButton />
                      </div>
                    ) : (
                      "Đổi ngay"
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full h-5 bg-white fixed bottom-0 z-40"></div>
          </ul>
          {/* fade-background */}
        </div>
      </div>
      <PopupLogin isShow={isConfirm}>
        <div className="pb-5 pt-7">
          <div className="text-[16px] font-regular-so">
            Bạn có chắc chắn muốn đổi {detailsGame?.required_points} Pepcoin
            <br /> để được phần quà này
          </div>
          <div className="mt-2 flex px-5">
            <button
              className="bg-[#effafd] mr-3 border-cancel text-[#5BC2DC] w-full shadow-item mt-5 py-4 text-[14px] rounded-lg"
              onClick={handleCancel}
            >
              Hủy
            </button>
            <button
              onClick={handleAgree}
              className="bg-[#5BC2DC] w-full shadow-item mt-5 font-semibold-so py-3 text-[14px] rounded-lg text-white"
            >
              Đồng ý
            </button>
          </div>
        </div>
      </PopupLogin>
      <PopupPoint isShow={isReward}>
        <div>
          <div className="w-full flex justify-center">
            <img src={TITLE} className="w-6/12" />
          </div>
          <div className="font-bold-so text-[20px] mt-3">
            Đổi quà thành công
          </div>
          <div className="font-light-so text-[14px] text-[#636363]">
            bạn còn lại{" "}
            <span className="text-[28px] font-semibold-so text-[#4E9ABB]">
              {point}
            </span>{" "}
            Pepcoin
          </div>
          <div className="font-regular-so text-[14px] px-14 mt-2">
            Quà đã được gửi cho bạn. Cảm ơn bạn đã tham gia chương trình
          </div>
          <div className="mt-5">
            <button
              className="bg-[#59C2DD] text-white py-4 rounded-xl shadow-item px-14"
              onClick={handleClose}
            >
              Quà của tôi
            </button>
          </div>
        </div>
      </PopupPoint>
      <PopupError
        labelBtn="Đóng"
        isShow={isError}
        messError={message}
        handleClose={handleClosePoint}
      />
    </div>
  );
}
