import Carousel from "react-multi-carousel";
import { Fragment } from "react/jsx-runtime";
import ICON_DOTS from "../../../assets/image/GuideTakePhoto/icon-dot.png";
import ICON_DOTS_COLOR from "../../../assets/image/GuideTakePhoto/icon-dots-color.png";
import SkelatonCampaignTop from "../../../components/Skelaton/SkelatonCampaignTop";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { homeServices } from "../../../services/apiService/homeService";
const banner_dragon = {
  banner_url:
    "https://storage.googleapis.com/mvcpro_vn/9f1b4d0f-5d0a-f298-c796-bafc77812ce3_05-03-2025-08-15-14_bfd50f04-fa68-1aef-3f74-07fa9bd1712d_05-03-2025-08-15-13.jpg",
  campaign_id: 2,
  campaign_type: "dragon",
};

export default function HeaderMenu() {
  const [mergeData, setMergeData] = useState<any>([]);
  const navigation = useNavigate();
  const { data, isLoading } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  useEffect(() => {
    if (!isLoading) {
      const mergeData = [...data?.banners];
      setMergeData(mergeData.reverse());
    }
  }, [isLoading]);
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.href = banner_dragon.banner_url;
    link.as = "image";
    document.head.appendChild(link);
  }, []);
  const CustomDot = ({ onClick, active }: any) => {
    return (
      <li
        className={`${active ? "text-red-600" : "text-black"} relative -top-7`}
        onClick={() => onClick()}
      >
        <div>
          {active ? <img src={ICON_DOTS_COLOR} /> : <img src={ICON_DOTS} />}
        </div>
      </li>
    );
  };
  const handleRedirectDetails = (
    campaign_id: number | string,
    campaign_type: string
  ) => {
    if (campaign_type === "dragon") {
      navigation(`/intro-pickleball/1`);
    } else {
      if (campaign_type === "special" || campaign_type === "aqua") {
        navigation(`/${campaign_type}/tc/${campaign_id}`);
      } else {
        if (campaign_id !== "") {
          if (campaign_type == "periodic") {
            navigation(
              `/intro-summer?id=${data?.periodic_data?.periodic_id}&campaign_id=${campaign_id}`
            );
          } else {
            navigation(`/prize-game/${campaign_id}`);
          }
        } else {
          navigation(`/tc`);
        }
      }
    }
  };

  return (
    <Fragment>
      <div>
        {mergeData ? (
          <Carousel
            showDots={true}
            additionalTransfrom={0}
            autoPlaySpeed={3000}
            autoPlay
            centerMode={false}
            className="pb-5"
            containerClass="container-with-dots"
            dotListClass="custom-dot-list-style"
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            customDot={<CustomDot />}
            swipeable={true}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 50,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            arrows={false}
          >
            {mergeData?.map((res: any, index: number) => (
              <div
                key={index}
                onClick={() =>
                  handleRedirectDetails(
                    res?.campaign_id as string | number,
                    res?.campaign_type as string
                  )
                }
              >
                <picture>
                  <img
                    src={res?.banner_url}
                    className="rounded-b-3xl"
                    width={440}
                    height={220}
                    alt="Campaign banner"
                    loading={index === 0 ? "eager" : "lazy"} // Eager cho hình ảnh đầu tiên, lazy cho các hình ảnh còn lại
                  />
                </picture>
              </div>
            ))}
          </Carousel>
        ) : (
          <SkelatonCampaignTop />
        )}
      </div>
    </Fragment>
  );
}
