import { Fragment, useEffect, useRef, useState } from "react";
import BackgroundGameFixed from "../../../components/BackgroundPopup/BackgroundGameFixed";
import Cloud from "./DragonCloud/Cloud";
import { useLocation, useNavigate } from "react-router-dom";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import CAMERA from "../../../assets/image/Game/DragonSnake/camera.png";
import INFOR from "../../../assets/image/Game/DragonSnake/infor.png";
import TAMGIAC from "../../../assets/image/Game/LuckyBox/tamgiac.png";
import {
  DetailsDragonSnake,
  GiftDragon,
  TowerDetails,
} from "../../../models/GameModel";
import { GameServices } from "../../../services/apiService/GameService";
import HOME from "../../../assets/image/Game/DragonSnake/home.png";
import PointBoard from "./PointBoard/PointBoard";
import Loading from "../../../components/Loading/Loading";
import PopupError from "../../../components/Popup/PopupError";
import PopupGift from "./PopupGift/PopupGift";

const towerDefault = {
  floor: "0",
  gift_background_image_url: "",
  gift_bow_tie_image_url: "",
  gift_frame_image_url: "",
  gift_id: "",
  gift_name: "",
  gift_public_reward_image_url: "",
  gift_rectangle_image_url: "",
  gift_reward_image_url: "",
  gift_square_image_url: "",
  no_gift: true,
};
export default function DragonSnake() {
  const cloudsRef = useRef<any>({});
  const { game_data }: { game_data: DetailsDragonSnake } = JSON.parse(
    localStorage.getItem("DATA_LEADER_BOARD") || "{}"
  );
  const navigation = useNavigate();
  const [isPopup, setPopup] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const [current, setCurrent] = useState<number>(0);
  const [currentMileStoneDisplay, setCurrentMileStoneDisplay] =
    useState<number>(0);
  const [animation, setAnimation] = useState(false);
  const [disable, setDisable] = useState(false);

  const [lstTower, setLstTower] = useState<any>([]);
  const [remainingPlay, setRemainingPlay] = useState<number>(0);
  const [gift, setGift] = useState<GiftDragon>();
  const [isLastGift, setIsLastGift] = useState<boolean>(false);
  const [background, setBackround] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [isPopupLevelUp, setIsPopupLevelUp] = useState(false);
  const [mileStone, setMileStone] = useState(0);
  const [mileCurentStone, setCurentMileStone] = useState(0);
  const [messageError, setMessageError] = useState<string>("");
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setTimeout(scrollToSection, 60);
  }, [current]);
  useEffect(() => {
    setTimeout(scrollToSection, 150);
  }, [lstTower]);
  useEffect(() => {
    setIsLoading(true);
    getFloorGame();
    setTimeout(scrollToSection, 140);
    GameServices.GetGamePlantTree(id as string)
      .then((res: any) => {
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);
  const getFloorGame = () => {
    GameServices.GetJoinGamePlantTree(id as string)
      .then((res: any) => {
        setCurentMileStone(res?.current_milestone);
        if (res.last_floor) {
          setPopup(true);
          setIsLastGift(true);
          setMessageError("Bạn đã hoàn thành nhiệm vụ chương trình");
        }
        if (!res?.status && res?.current_floor !== 0) {
          setPopup(true);
          const dataGift =
            game_data?.tower_milestones[res?.current_milestone - 1]?.floors[
              res?.current_floor - 1
            ];
          setGift(dataGift as any);
        }
        if (
          res?.current_floor === 9 &&
          res?.status === "continue" &&
          res?.next_milestone > 0
        ) {
          setCurrent(0);
          getDataGame(res?.current_milestone + 1);
          setMileStone(res?.current_milestone + 1);
          setCurrentMileStoneDisplay(res?.current_mileston);
          setIsPopupLevelUp(true);
          setTimeout(() => {
            setIsPopupLevelUp(false);
          }, 3000);
        } else {
          setCurrent(res?.current_floor);
          getDataGame(res?.current_milestone);
        }
        setRemainingPlay(parseInt(res?.remaining_play));
      })
      .catch((err) => {
        setIsError(true);
        setMessageError(err);
      });
  };
  const getDataGame = (current_milestone: number) => {
    GameServices.GetGamePlantTree(id as string)
      .then((res: any) => {
        const current = res?.game_data?.tower_milestones[current_milestone - 1];
        let mergeLstTower = [towerDefault, ...current?.floors];
        setBackround(current?.background_image_url);
        setLstTower(mergeLstTower.reverse());
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
      })
      .catch((err) => {
        setIsError(true);
        setMessageError(err.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const scrollToSection = () => {
    const targetElement = cloudsRef.current[current - 1];
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const handleActive = () => {
    if (remainingPlay !== 0) {
      setDisable(true);
      if (!disable) {
        if (current === 9 && currentMileStoneDisplay === 3) {
        } else {
          playGame();
        }
      }
    }
  };
  const playGame = () => {
    GameServices.PostPlayGameDragon(id as string)
      .then((res: any) => {
        setGift(res);
        setRemainingPlay(parseInt(res?.remaining_play));
        setCurrentMileStoneDisplay(res?.current_display_milestone);
        setCurentMileStone(res?.current_display_milestone);
        setAnimation(true);
        setTimeout(() => {
          setCurrent(current + 1);
          setAnimation(false);
          setTimeout(() => {
            setPopup(true);
            setDisable(false);
          }, 500);
          if (
            res?.current_display_floor === 9 &&
            res?.current_display_milestone === 3
          ) {
            handleClose(false);
          }
        }, 1200);
      })
      .catch((err) => {
        setIsError(true);
        setMessageError(err);
      });
  };
  const [checkHaveExtraGift, setCheckHaveExtraGift] = useState<boolean>(false);
  const [openExtraGift, setOpenExtraGift] = useState<boolean>(false);
  const handleClose = (status: boolean) => {
    setDisable(true);
    if (checkHaveExtraGift) {
      if (openExtraGift) {
        navigation("/list-gift");
      } else {
        setOpenExtraGift(true);
        setDisable(false);
      }
    } else {
      if (!disable) {
        if (current === 9 && currentMileStoneDisplay === 3) {
          navigation("/list-gift");
        } else {
          GameServices.UpdateContinuePlayGameDragon(id as string, status)
            .then((res: any) => {
              if (res?.have_extra_gift) {
                setCheckHaveExtraGift(true);
                setDisable(false);
              } else {
                if (res?.last_floor) {
                  return Promise.reject(
                    "Bạn đã hoàn thành nhiệm vụ chương trình"
                  );
                }
                if (!status) {
                  setTimeout(() => {
                    setPopup(false);
                    setCurrent(0);
                  }, 500);
                } else {
                  if (current == 9) {
                    setCurrent(0);
                    getFloorGame();
                  }
                  setPopup(false);
                }
                setDisable(false);
              }
            })
            .catch((err) => {
              setIsError(true);
              setMessageError(err);
            })
            .finally(() => {});
        }
      }
    }
  };
  const handleOpenPhoto = () => {
    navigation(`/guide-page-picture/0?back=dragon_snake?id=${id}`);
  };
  const handleReloadPage = () => {
    navigation(`/dragon_snake/tc/${id}`);
  };
  const handleBackHome = () => {
    navigation(`/`);
  };
  const handleCloseError = () => {
    setIsError(false);
    setMessageError("");
    navigation(`/`);
  };
  return (
    <BackgroundGameFixed img={background}>
      {isError && (
        <PopupError
          isShow={isError}
          messError={messageError}
          handleClose={handleCloseError}
          labelBtn="Đồng ý"
        />
      )}

      {isloading ? (
        <Loading />
      ) : (
        <div className="fixed top-0 z-30">
          <img src={game_data?.title_image_url} />
          <div className="fixed top-5 left-5 z-30" onClick={handleBackHome}>
            <img src={HOME} />
          </div>
        </div>
      )}
      {isPopupLevelUp && (
        <PopupOverlay isShow={isPopupLevelUp}>
          <div className="fixed -top-40 left-1/2 -translate-x-1/2 w-full px-10">
            <img src={game_data?.loading_screen_url} className="w-full" />
            <div className="absolute top-1/2 text-[#5A4831] leading-8 -translate-y-1/2 left-1/2 -translate-x-1/2 w-full text-[24px] font-bold-so">
              CHÚC MỪNG BẠN <br />
              ĐẾN CHƯƠNG {mileStone}
            </div>
          </div>
        </PopupOverlay>
      )}
      <div className="absolute top-0 w-full">
        {lstTower &&
          lstTower?.map((res: TowerDetails, index: number) => {
            return (
              <div
                key={index}
                ref={(el) => (cloudsRef.current[8 - index] = el)}
              >
                <Cloud
                  location={index % 2 === 0 ? "right" : "left"}
                  section={(9 - index).toString()}
                  highlight={current.toString()}
                  animation={animation}
                  background={res}
                />
              </div>
            );
          })}
      </div>
      <PointBoard current={current} />
      <button className="fixed bottom-8 left-10 z-20" onClick={handleOpenPhoto}>
        <div className="relative bottom-2 -left-6 uppercase text-[11px] w-full font-semibold-so leading-4 border-luckyBox rounded-full text-center bg-[#FAF4BF] py-1 px-3 animation_label-takephoto">
          <div className="font-bold-so">tìm lượt nhảy</div>
          <div className="font-bold-so">tại đây</div>
          <div className="absolute -bottom-[10px] left-1/2 -translate-x-1/2">
            <img src={TAMGIAC} />
          </div>
        </div>
        <img src={CAMERA} className="w-[60px]" />
      </button>
      <div className="fixed bottom-5 left-1/2 -translate-x-1/2 z-20">
        <div onClick={handleActive} className="relative">
          <div className="w-28">
            {remainingPlay == 0 || disable ? (
              <img
                src={game_data?.button_disable_play_image_url}
                className="w-full"
              />
            ) : (
              <img
                src={game_data?.button_enable_play_image_url}
                className="w-full"
              />
            )}
          </div>

          <div
            style={{
              color: game_data?.button_text_color,
            }}
            className="font-semibold-so absolute h-full leading-6 text-[23px] left-1/2 -translate-x-1/2 top-0 flex flex-col items-center justify-center"
          >
            NHẢY
            <div>x {remainingPlay}</div>
          </div>
        </div>
      </div>
      <div
        className="fixed w-[60px] bottom-8 right-10 z-20"
        onClick={handleReloadPage}
      >
        <img src={INFOR} />
      </div>
      <PopupGift
        isPopup={isPopup}
        game_data={game_data}
        gift={gift!}
        gift_extra_content={gift?.gift_extra_content!}
        gift_reward_image_url={
          openExtraGift
            ? gift?.extra_gift_reward_image_url!
            : gift?.gift_reward_image_url!
        }
        gift_name={openExtraGift ? gift?.extra_gift_name! : gift?.gift_name!}
        labelChangeGift={openExtraGift ? "Đồng ý" : "Đổi quà"}
        openExtraGift={openExtraGift}
        setPopup={setPopup}
        current={current}
        isLastGift={isLastGift}
        disable={disable}
        mileCurentStone={mileCurentStone}
        handleClose={handleClose}
      />
    </BackgroundGameFixed>
  );
}
