import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { userLocal } from "../../../services/localService/localService";
import { setAuthorization } from "../../../services/apiService/configURL";
import { homeServices } from "../../../services/apiService/homeService";
import { ProfileModel } from "../../../models/LoginModel";
import PopupError from "../../../components/Popup/PopupError";
import SurveyForm from "../../../components/SurveyForm/SurveyForm";

const title = "Khảo sát thông tin";

export default function SurveyFormPickleBall() {
  const navigation = useNavigate();
  const [profile, setProfile] = useState<any>();
  let { token } = userLocal.get();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const [update, setUpdate] = useState("");
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  setAuthorization(token);
  useEffect(() => {
    homeServices
      .GetProfile()
      .then((res: any) => {
        setProfile(res);
      })
      .catch((err) => {});
  }, []);
  const goToPrevious = (e: any) => {
    setIsLoading(true);
    e["phone"] = profile.phone;
    e["collection_id"] = id;
    homeServices
      .SurveyForm(e)
      .then((res: any) => {
        setUpdate("Khảo sát thành công.");
        if (!res.is_couple) {
          navigation(`/success-pickleball/${id}?type=0`);
        } else {
          navigation(`/success-pickleball/${id}?type=1`);
        }
      })
      .catch((err) => {
        setUpdate(err);
      })
      .finally(() => {
        setStatus(true);
        setIsLoading(false);
      });
  };
  const handleClose = () => {
    setStatus(false);
  };
  const handleBack = () => {
    navigation(`/intro-pickleball/${id}`);
  };
  const handleNext = (e: any) => {};

  return (
    <div>
      <div>
        <SurveyForm
          handleNext={(e: any) => handleNext(e)}
          required={true}
          dataRegister={profile}
          goToPrevious={(e: any) => goToPrevious(e)}
          title={title}
          consent={false}
          labelButton="ĐĂNG KÝ"
          updateAfter={false}
          handleBack={handleBack}
          isLoading={isLoading}
        />
      </div>
      <PopupError
        isShow={status}
        labelBtn="Đồng ý"
        handleClose={handleClose}
        messError={update}
      />
    </div>
  );
}
