import { useNavigate } from "react-router-dom";
import { DetailsDragonSnake } from "../../../../models/GameModel";
import MYGIFT from "../../../../assets/image/Game/Summer/mygift.png";
import NEXTPLAY from "../../../../assets/image/Game/Summer/nextplay.png";

export default function PopupJigSawSuccess({
  dataGame,
  dataGift,
  handleClosePopup,
  handleJigSaw,
  handleNext,
  numberJigSaw,
}: {
  numberJigSaw: number;
  dataGame: DetailsDragonSnake;
  dataGift: any;
  handleClosePopup: () => any;
  handleJigSaw: () => any;
  handleNext: () => any;
}) {
  const navigation = useNavigate();

  const handleMyGift = () => {
    navigation("/list-gift/3");
  };
  return (
    <div>
      <div>
        <div
          className="absolute -top-4 right-0 z-20"
          onClick={handleClosePopup}
        >
          <img src={dataGame?.close_button_image_url} className="w-12" />
        </div>
        <div
          style={{ color: dataGame?.text_color }}
          className="pb-3 pt-10 text-[20px] font-bold-so"
        >
          BẠN NHẬN ĐƯỢC
        </div>
        <div
          style={{ color: dataGame?.title_color }}
          className="text-[84px] font-bold-so"
        >
          {numberJigSaw.toString().length == 1
            ? "0" + numberJigSaw
            : numberJigSaw}
        </div>
        <div
          style={{ color: dataGame?.text_color }}
          className="text-white font-semibold-so text-[20px] mb-10"
        >
          MÃ DỰ THƯỞNG
        </div>
        <div
          style={{ color: dataGame?.text_color }}
          className="text-white font-regular-so my-10"
        >
          Vui lòng kiểm tra tại quà của tôi
        </div>
        <div className="px-5 pb-5 flex justify-around">
          <button onClick={handleMyGift} className="relative text-white">
            <img src={dataGame?.gift_button_image_url} className="h-16" />
            <div className="absolute font-bold-so text-[13px] top-1/2 -translate-y-[65%] flex justify-center w-full">
              QUÀ CỦA TÔI
            </div>
          </button>
          <button onClick={handleNext} className="relative">
            <img src={dataGame?.leader_board_icon_url} className="h-16" />
            <div className="absolute font-bold-so text-white text-[13px] top-1/2 -translate-y-[65%] flex justify-center w-full">
              TIẾP TỤC
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
