import CODE from "../../../assets/image/Game/GachaTest/codegacha.png";
import TITLE_ERROR from "../../../assets/image/Game/GachaTest/err.png";
import {
  LUOT_QUAY,
  userLocal,
} from "../../../services/localService/localService";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { setAuthorization } from "../../../services/apiService/configURL";
import { GameServices } from "../../../services/apiService/GameService";
import { generateRandomDuplicateList } from "../../../utils/formatData";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import PopupError from "../../../components/Popup/PopupError";
import LoadingButton from "../../../components/Loading/LoadingButton";
import ANIMATE from "../../../assets/image/Popup/animate-bg.png";
import ContactPhoneZalo from "../../../components/ContactPhoneZalo/ContactPhoneZalo";
import ItemBall from "./ItemBall";

export default function NewGacha() {
  let { token } = userLocal.get();
  const { campaign_id, sale_id } = useParams();
  const [lat, setLat] = useState<Boolean>(false);
  const [play, setPlay] = useState<Boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [popUpGift, setPopupGift] = useState("no");
  const [messError, setMessError] = useState("");
  const [spinRemain, setSpinRemain] = useState<number>(0);
  const [lineId, setLineId] = useState();
  const [gift, setGift] = useState<any>();
  const [game, setGame] = useState<any>();
  const [ball, setBall] = useState<any>([]);
  const [listDraw, setListDraw] = useState<any>([]);
  const [isOpenPopup, setIsPopup] = useState<boolean>(false);
  const navigation = useNavigate();
  setAuthorization(token);
  useEffect(() => {
    setLoading(true);
    setAuthorization(token);
    getCalculator(sale_id, campaign_id);
  }, []);
  useEffect(() => {
    getCalculator(sale_id, campaign_id);
    setAuthorization(token);
  }, [popUpGift === "change"]);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    setAuthorization(token);
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          setLoading(false);
          setGame(res.lucky_draw_list.game_data);
          setBall(res.lucky_draw_list.game_data?.game_items);
          setPopupGift("no");
          setSpinRemain(res.remaining_draw);
          const giftWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          const listDraw = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          const newDraw = generateRandomDuplicateList(listDraw, 10);
          setListDraw(newDraw);
          setGift(giftWin);
          setLineId(giftWin?.line_id);
          localStorage.setItem(
            "LIST_LUCKY_DRAW",
            JSON.stringify(res.lucky_draw_list.list_lucky_draw)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(giftWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
      });
  };
  const [lock, setLock] = useState<boolean>(false);
  const [animationGift, setAnimationGift] = useState<boolean>(false);

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const handleClosePopup = () => {
    setIsLoadingButton(true);
    const luot_quay = localStorage.getItem(LUOT_QUAY);
    document.body.style.overflow = "unset";
    if (luot_quay === "0") {
      navigation("/list-gift");
    } else {
      setLock(false);
      setPopupGift("change");
      setIsPopup(false);
      setIsPress(false);
      setIsLoadingButton(false);
    }
  };
  const [isPress, setIsPress] = useState(false);
  const [exchange_code, setExchange_code] = useState(false);
  const [dataCode, setDataCode] = useState<any>();
  const handlePress = () => {
    setIsPress(true);
    if (!lock) {
      setLock(true);
      setLat(true);

      setTimeout(() => {
        setPlay(true);
        const data = {
          sale_id: sale_id,
          campaign_id: campaign_id,
          line_id: lineId,
        };
        GameServices.CountDrawApi(data)
          .then((res: any) => {
            setDataCode(res);
            localStorage.setItem(LUOT_QUAY, res.remaining_draw);
            if (res.remaining_draw === 0) {
              const dataParams = {
                sale_id: sale_id,
                campaign_id: campaign_id,
              };
              GameServices.SendGiftApi(dataParams)
                .then((res) => {
                  setIsLoadingButton(false);
                })
                .catch((err) => {});
            }
            setSpinRemain(res.remaining_draw);
            setExchange_code(res.exchange_code);
            setTimeout(() => {
              setPlay(false);
              setTimeout(() => {
                setIsPopup(true);
                setTimeout(() => {
                  setAnimationGift(true);
                }, 1000);
              }, 580);
            }, 5000);
          })
          .catch((err) => {
            setIsError(true);
            setMessError(err);

            console.error(err);
          });
      }, 500);
    }
  };
  const handleCloseError = () => {
    navigation("/games");
  };
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  return (
    <Fragment>
      <div className="relative top-0">
        <div className="relative top-0">
          <img src={game?.background_image_url} />
        </div>
        <div className="absolute top-3 w-full flex justify-center">
          <img src={game?.title_image_url} className="w-9/12" />
        </div>
        <div className="absolute top-[38%] -translate-y-1/2 flex justify-center w-full">
          <div className="w-full flex flex-col justify-center items-center">
            <div className="relative top-0 w-full">
              <img src={game?.machine_table_image_url} className="w-full" />
              <div className="w-full absolute -top-0">
                <div className="w-10/12 relative left-1/2 -translate-x-1/2">
                  <div className="relative">
                    <img src={game?.machine_header_image_url} className="" />
                  </div>
                  {/* Item Ball */}
                  <ItemBall play={play} ball={ball} listDraw={listDraw} />
                </div>
              </div>
              <div className="absolute bottom-[9.5%] text-[13px] flex justify-center w-full">
                Bạn&nbsp;
                {spinRemain > 0 ? (
                  <>
                    có&nbsp;
                    <span className="text-[15px] -mt-[3px] text-[#243F94] font-semibold-so">
                      {spinRemain}
                    </span>
                  </>
                ) : (
                  <>hết</>
                )}
                &nbsp;lượt chơi
              </div>
            </div>
            <div className="w-full">
              <div className="absolute -bottom-[7%] flex flex-col items-center justify-center w-full">
                <div className="w-24 relative z-10" onClick={handlePress}>
                  <div
                    className={`${
                      isPress ? "animation-press" : ""
                    } relative z-10`}
                  >
                    <img src={game?.button_top_image_url} />
                  </div>
                  <div className="absolute -bottom-5 z-0">
                    <img src={game?.button_bottom_image_url} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactPhoneZalo />
      </div>
      <PopupOverlay isShow={isOpenPopup}>
        <div className="relative top-0 text-white left-1/2 -translate-x-1/2 w-full">
          <div className="relative text-white left-1/2 -translate-x-1/2 w-full px-5">
            <div className="relative w-full leading-7 left-1/2 -translate-x-1/2 text-[28px] font-bold-so text-[#ED3136]">
              <div className="text-shadow-title uppercase flex justify-center">
                <img
                  src={
                    gift?.no_gift ? TITLE_ERROR : game?.title_result_image_url
                  }
                  className="animate-gift-gacha"
                />
              </div>
              <div className="text-[20px] text-white uppercase font-medium-so mt-3">
                {gift?.no_gift ? "" : gift?.gift_name}
              </div>
              <div className="text-[10px] text-white font-light-so mt-0">
                {gift?.no_gift ? "" : exchange_code}
              </div>
              <div className="relative left-1/2 -translate-x-1/2 z-0 flex flex-col justify-center items-center">
                <div className="relative z-10 w-full flex justify-center border-img-gift">
                  <img
                    src={gift?.gift_reward_image_url}
                    loading="lazy"
                    className={`${
                      animationGift ? "animation-rotate-giftgacha" : ""
                    } relative z-0 w-48 animate-gift-gacha`}
                  />
                </div>
                <div className="absolute -top-10 w-full z-0">
                  <img
                    src={ANIMATE}
                    className="animate-bgresult rounded-full"
                  />
                </div>
                <div
                  className="text-[11px] leading-5 text-white font-light-so mt-5"
                  dangerouslySetInnerHTML={{
                    __html: gift?.instructions_redeeming_rewards,
                  }}
                ></div>
              </div>
              {dataCode?.show_lucky_code && dataCode?.lucky_code ? (
                <div className="text-white font-medium-so text-[16px] py-1 border-code-gacha mt-2">
                  <div>{dataCode?.lucky_program_content}</div>
                  <div className="relative w-full">
                    <img
                      src={CODE}
                      className="relative left-1/2 -translate-x-1/2 mt-1"
                    />
                    <div className="absolute text-[#2D4E68] font-semibold-so text-[20px] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                      {dataCode?.lucky_code}
                    </div>
                  </div>
                  <div className="font-light-so text-[10px]">
                    Vui lòng giữ Mã dự thưởng để tham gia Vòng quay vào cuối
                    chương trình.
                  </div>
                </div>
              ) : null}

              <div className="relative w-full flex justify-center mt-5">
                <button
                  className="px-6 py-[5px] rounded-[12px] text-[14px] leading-5 font-light-so"
                  style={{
                    backgroundColor: game?.button_color,
                    border: `2px solid ${game?.button_border_color}`,
                    color: game?.button_text_color,
                  }}
                  onClick={isLoadingButton ? () => {} : handleClosePopup}
                >
                  {isLoadingButton ? (
                    <div className="w-24 py-2">
                      <LoadingButton />
                    </div>
                  ) : (
                    <>
                      {spinRemain > 0 ? (
                        <>
                          Còn{" "}
                          {spinRemain > 0 && spinRemain < 10
                            ? "0" + spinRemain
                            : spinRemain}{" "}
                          lượt chơi
                          <br />
                          Tiếp tục
                        </>
                      ) : (
                        <div className="py-2">Quà của tôi</div>
                      )}
                    </>
                  )}
                </button>
              </div>
              <div className="text-white font-regular-so mt-2 text-[16px]">
                Tổng đài <a href={`tel:${hotline}`}>{hotline}</a>
              </div>
            </div>
          </div>
        </div>
      </PopupOverlay>
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </Fragment>
  );
}
