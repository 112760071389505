import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../assets/css/Login.css";
import { LoginServices } from "../../services/apiService/LoginService";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import FOOTER from "../../assets/image/Login/footer.png";
import LoadingButton from "../../components/Loading/LoadingButton";
import OtpInput from "react-otp-input";
import { LoginAPIModel } from "../../models/LoginModel";
import { MaskedPhoneNumber } from "../../utils/formatData";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { setAuthorizationSessionToken } from "../../services/apiService/configURL";
export default function ValidateOTP() {
  const navigation = useNavigate();
  const [otp, setOtp] = useState("");
  const { next, back } = useParams();
  const phone = localStorage.getItem("PHONE");
  const handleChangeOtp = (e: any) => {
    setOtp(e);
  };
  const [isValidateOtp, setIsValidateOtp] = useState(false);
  const [isUpdatePass, setIsUpdatePass] = useState(false);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleRecaptchaExecution = async (action: any) => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha is not yet available");
      return null;
    }
    return await executeRecaptcha(action);
  };
  const session_token = localStorage.getItem("SESSION_TOKEN");
  const resendOTP = async () => {
    const token = await handleRecaptchaExecution("form_submit");
    const data = {
      phone: phone as string,
      recaptcha_token_response: token,
    };
    LoginServices.ValidateAPI(data)
      .then((res) => {
        setIsValidateOtp(true);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
    setMinutes(1);
    setSeconds(0);
  };
  const handleClickValidateOTP = async () => {
    setIsLoading(true);

    const token = await handleRecaptchaExecution("form_submit");
    const data = {
      otp: otp,
      phone: phone as string,
      recaptcha_token_response: token,
    };
    if (next === "register") {
      validateOtpRegister(data, next);
    } else if (next === "forgot-password") {
      validateOtpPassword(data, next);
    }
  };
  const validateOtpPassword = (data: LoginAPIModel, next: string) => {
    LoginServices.ValidateOTPPassword(data)
      .then((res: any) => {
        setIsValidateOtp(false);
        setIsUpdatePass(true);
        localStorage.setItem("PROTECT_CODE", res.protect_code);
        navigation(`/${next}`);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const validateOtpRegister = (data: LoginAPIModel, next: string) => {
    setAuthorizationSessionToken(session_token as string);
    LoginServices.ValidateOTPRegister(data)
      .then((res: any) => {
        localStorage.setItem("DATA_USER", JSON.stringify(res));
        localStorage.setItem("SESSION_TOKEN", res.session_token);
        setAuthorizationSessionToken(res.session_token);
        console.log(res);
        navigation(`/${next}`);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleBack = () => {
    navigation(`/${back}`);
  };
  useEffect(() => {
    console.log(session_token);
  }, []);
  return (
    <div className="">
      <HeaderFrame
        footer={FOOTER}
        title="NHẬP OTP"
        iconBack={BACK_HEADER}
        handleBack={handleBack}
      >
        <div className="pb-5 px-2 mt-5 text-center">
          <div className="text-[14px] text-[#636363] font-light-so">
            Bạn vui lòng nhập mã OTP đã được gửi về số điện thoại
            <br />{" "}
            <span className="text-[#4E9ABB] text-[14px] font-medium-so">
              {MaskedPhoneNumber(phone as string)}
            </span>
          </div>
          <div>
            <OtpInput
              className="otp-element text-[#333] w-full flex justify-center mt-3"
              value={otp}
              onChange={handleChangeOtp}
              numInputs={6}
              isInputNum={true}
            />
          </div>
          {error ? (
            <div className="text-[#ED3136] -mt-5 mb-2">{error}</div>
          ) : null}
          <div className="text-[#424242] -mt-2 font-light-so flex justify-center text-[12px]">
            Mã OTP của bạn sẽ hết hạn trong{" "}
            <span>
              <span className="countdown-text text-[#4E9ABB]">
                {" "}
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    &nbsp;{minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <button
                    disabled={seconds > 0 || minutes > 0}
                    className=" text-[#4E9ABB] mx-1.5"
                    onClick={resendOTP}
                  >
                    Gửi lại
                  </button>
                )}
              </span>
            </span>
          </div>
          <div className="mt-5">
            {isLoading ? (
              <button className="bg-[#bdbdbd] w-40 px-8 font-semibold-so py-2 text-[14px] rounded-lg text-white">
                <LoadingButton />
              </button>
            ) : (
              <button
                className="bg-[#5BC2DC] w-40 px-8 font-semibold-so py-2 text-[14px] rounded-lg text-white"
                onClick={handleClickValidateOTP}
              >
                Đăng nhập
              </button>
            )}
          </div>
        </div>
      </HeaderFrame>
    </div>
  );
}
