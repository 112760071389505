import Navbar from "../../components/NavbarMenu/Navbar";
import MenuTab from "../../components/MenuTab/MenuTab";
import ItemHistoryCoin from "./ItemHistoryCoin";
import { useEffect, useState } from "react";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import ItemHistoryBill from "./ItemHistoryBill";
import {
  HistoryBill,
  HistoryPepcoin,
  PagingHistory,
} from "../../models/HistoryModel";
import { Pagination } from "antd";
import DetailsHistory from "./DetailsHistory";
import DetailsHistoryCoin from "./DetailsHistoryCoin";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import { useNavigate } from "react-router-dom";
import SkelatonHistory from "../../components/Skelaton/SkelatonHistory";

export default function ListHistory() {
  const navigation = useNavigate();
  const [current, setCurrent] = useState<string>("1");
  const [page, setPage] = useState<number>(1);
  const [bill, setBill] = useState<any>();
  const [coin, setCoin] = useState<any>();
  const [popup, setPopup] = useState<boolean>(false);
  const [popupCoin, setPopupCoin] = useState<boolean>(false);
  const [historyBill, setHistoryBill] = useState<PagingHistory>();
  const [historyPepcoin, setHistoryPepcoin] = useState<any>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const handleClick = (e: any, status_gifts: string) => {
    setPage(1);
    setCurrent(e.target.id);
    if (e.target.id === "2") {
      getDataPepcoin(page, 10, "", "");
    } else {
      getData(page, 10, "", "");
    }
  };
  const getData = (
    page: number,
    limit: number,
    start_date: string,
    end_date: string
  ) => {
    const data = {
      page: page,
      limit: 10,
      start_date: start_date,
      end_date: end_date,
    };
    ListReceiptServices.GetListHistory(data)
      .then((res: any) => {
        setHistoryBill(res);
        setTotalPage(res.total_item);
      })
      .catch((err) => {});
  };
  const getDataPepcoin = (
    page: number,
    limit: number,
    start_date: string,
    end_date: string
  ) => {
    const data = {
      page: page,
      limit: 10,
      start_date: start_date,
      end_date: end_date,
    };
    ListReceiptServices.GetPepcoinHistory(data)
      .then((res: any) => {
        setHistoryPepcoin(res);
        setTotalPage(res.total_item);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getData(page, 10, "", "");
  }, []);
  useEffect(() => {
    if (
      startDate !== "undefined-NaN-undefined" &&
      endDate !== "undefined-NaN-undefined"
    ) {
      getData(page, 10, startDate, endDate);
      getDataPepcoin(page, 10, startDate, endDate);
    }
  }, [startDate, endDate]);
  const handleD = (e: number) => {
    setPage(e);
    if (current === "1") {
      getData(e, 10, "", "");
    } else {
      getDataPepcoin(e, 10, "", "");
    }
  };
  const handlegetBill = (data: HistoryBill) => {
    setPopup(true);
    ListReceiptServices.GetDetailHistory(data.order_id)
      .then((res: any) => {
        setBill(res);
      })
      .catch((err) => {});
  };
  const handlegetCoin = (data: HistoryPepcoin) => {
    if (data.order_id) {
      setPopupCoin(true);
      ListReceiptServices.GetDetailPepcoinHistory(data.order_id as number)
        .then((res: any) => {
          const merge = Object.assign(res, data);
          setCoin(res);
        })
        .catch((err) => {});
    }
  };
  return (
    <div className="">
      {popup || popupCoin ? (
        <div>
          {popup && <DetailsHistory bill={bill} setPopup={setPopup} />}
          {popupCoin && (
            <DetailsHistoryCoin bill={coin} setPopup={setPopupCoin} />
          )}
        </div>
      ) : (
        <div>
          <HeaderFrame
            footer=""
            iconBack={BACK_HEADER}
            title="Lịch sử"
            handleBack={() => navigation("/")}
            iconfilter={true}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          >
            <div className="relative top-5 px-2">
              <MenuTab
                current={current}
                handleClick={(e: any, status_gifts: string) =>
                  handleClick(e, status_gifts)
                }
                nameLeft="Lịch sử hóa đơn"
                nameRight="Lịch sử Pepcoin"
              />
              <div className="mt-5">
                <div></div>
                {current === "1" &&
                  (historyBill?.histories ? (
                    (historyBill?.histories?.length as number) === 0 ? (
                      <div className="relative top-20 text-center font-light-so">
                        Quý khách chưa tham gia các chương trình khuyến mãi. Hãy
                        nhanh tay để nhận ngay những ưu đãi hấp dẫn và quà tặng
                        giá trị. Thời gian khuyến mãi có hạn!
                      </div>
                    ) : (
                      historyBill?.histories?.map((res: HistoryBill) => {
                        return (
                          <div onClick={() => handlegetBill(res)}>
                            <ItemHistoryBill res={res} />
                          </div>
                        );
                      })
                    )
                  ) : (
                    <div>
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                    </div>
                  ))}
                {current === "2" &&
                  (historyPepcoin?.histories ? (
                    (historyPepcoin?.histories?.length as number) === 0 ? (
                      <div className="relative top-20 text-center font-light-so">
                        Quý khách chưa tham gia các chương trình khuyến mãi. Hãy
                        nhanh tay để nhận ngay những ưu đãi hấp dẫn và quà tặng
                        giá trị. Thời gian khuyến mãi có hạn!
                      </div>
                    ) : (
                      historyPepcoin?.histories?.map((res: HistoryPepcoin) => {
                        return (
                          <div onClick={() => handlegetCoin(res)}>
                            <ItemHistoryCoin res={res} />
                          </div>
                        );
                      })
                    )
                  ) : (
                    <div>
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                      <SkelatonHistory />
                    </div>
                  ))}

                <div className="flex justify-center mt-2">
                  {totalPage > 10 && (
                    <Pagination
                      current={page}
                      total={totalPage}
                      onChange={handleD}
                    />
                  )}

                  <div className="h-40"></div>
                </div>
              </div>
            </div>
          </HeaderFrame>
          <Navbar />
        </div>
      )}
    </div>
  );
}
