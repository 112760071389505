import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../assets/css/ListReceiveCustomer.css";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { Pagination } from "antd";
import TabGift from "./TabGift";
import TabReward from "./TabReward";
import {
  ListGiftCustomer,
  ListWinCustomer,
} from "../../models/ListReceiveCustomer";

export default function ListReceiveSpecial() {
  let campaign_type = window.location.pathname.split("/")[1];
  const navigation = useNavigate();
  const { id, type } = useParams();
  const [page, setPage] = useState<number>(1);
  const [typeTab, setTypeTab] = useState(type === "gift" ? true : false);
  const [current, setCurrent] = useState("1");
  const [lstGift, setDataListGift] = useState([]);
  const [totalPrize, setTotalPrize] = useState<number>(0);
  const [listWin, setDataListWin] = useState([]);
  const [giftDisplay, setGiftDisplay] = useState<any>();
  const data_bg = JSON.parse(localStorage.getItem("DATA_LEADER_BOARD") || "{}");
  const handleD = (data: number) => {
    setPage(data);
    getWinCampaign(data, id as string, 10);
  };
  const handleBack = () => {
    navigation(`/${campaign_type}/tc/${id}`);
  };
  const handleBackHome = () => {
    navigation(`/`);
  };
  const hanldeClick = (e: any, status_gifts: boolean) => {
    setCurrent(e.target.id);
    setTypeTab(status_gifts);
    setPage(1);
    if (status_gifts) {
      getPrizeCampaign(id as string);
      setCurrent("1");
    } else {
      getWinCampaign(1, id as string, 10);
      setCurrent("2");
    }
  };
  const getPrizeCampaign = (id: string) => {
    ListReceiptServices.ReceiptCustomerGift(id)
      .then((res: any) => {
        setGiftDisplay(res);
        setDataListGift(res?.gifts);
      })
      .catch((error) => {});
  };
  const getWinCampaign = (page: number, id: string, limit: number) => {
    ListReceiptServices.ReceiptCustomerPrize(page, id, limit)
      .then((res: any) => {
        setTotalPrize(res.total_item);
        setDataListWin(res.list_winning);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getPrizeCampaign(id as string);
    getWinCampaign(page, id as string, 10);
    if (type === "gift") {
      setCurrent("1");
    } else {
      setCurrent("2");
    }
  }, []);
  document.body.style.backgroundColor = data_bg?.background_color;
  console.log(data_bg);

  return (
    <div
      className="min-h-full w-full"
      style={{
        background: `url(${data_bg?.footer_confirm_page_image_url})`,
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <div className="px-3 pt-3 flex justify-between">
        <img
          src={data_bg?.button_back_image_url}
          className="w-10"
          onClick={handleBack}
        />
        <div
          style={{
            color: data_bg?.campaign_text_details_color,
          }}
          className="text-white pt-1 text-[20px] font-semibold-so"
        >
          DANH SÁCH
        </div>
        <img
          src={data_bg?.back_home_button_image_url}
          className="w-10"
          onClick={handleBackHome}
        />
      </div>
      <div className="px-2 mt-2">
        <div className="">
          <div className="grid grid-cols-2">
            <button onClick={(e) => hanldeClick(e, true)}>
              <img
                src={
                  typeTab
                    ? data_bg?.button_gift_enable_image_url
                    : data_bg?.button_gift_disable_image_url
                }
                className="w-full"
              />
            </button>
            <button onClick={(e) => hanldeClick(e, false)}>
              {" "}
              <img
                src={
                  !typeTab
                    ? data_bg?.button_prize_enable_image_url
                    : data_bg?.button_prize_disable_image_url
                }
                className="w-full"
              />
            </button>
          </div>
          <div className="box-border mt-5">
            {typeTab ? (
              <div>
                {giftDisplay?.gift_display === "list" ? (
                  <div>
                    {lstGift?.map((res: ListGiftCustomer) => (
                      <div className="relative top-0">
                        <div className="relative w-full grid grid-cols-12">
                          <div className="col-span-5">
                            <div className="relative top-0">
                              <img
                                src={res.gift_background_image_url}
                                className="relative top-0 z-10 w-[20vh] "
                              />
                              <div className="absolute bottom-2 z-20 w-full">
                                <div className="w-full relative flex justify-center">
                                  <img
                                    src={res.gift_bow_tie_image_url}
                                    className="w-9/12 relative z-30 top-1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-7">
                            <div className="relative top-0 left-1/2 -translate-x-1/2 w-full h-full z-10">
                              <div className="w-11/12 col-span-9 flex flex-col items-center justify-center h-full">
                                <div
                                  style={{
                                    color: data_bg?.campaign_title_color,
                                  }}
                                  className="text-center uppercase font-semibold-so leading-5 text-[#FFFFFF] text-[14px]"
                                >
                                  {res.gift_name}
                                </div>
                                <div
                                  style={{
                                    color:
                                      data_bg?.quantity_text_prize_confirm_color,
                                  }}
                                  className="text-[12px] text-[#FFFFFF] -mt-[3px]"
                                >
                                  Còn lại &nbsp;
                                  <span
                                    style={{
                                      color:
                                        giftDisplay?.gift_remaining_text_color,
                                    }}
                                    className="font-semibold-so text-[16px] text-[#67EBFF]"
                                  >
                                    {res.remaining}
                                  </span>{" "}
                                  &nbsp; / {res.plan} giải thưởng
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="absolute right-0 top-1/2 -translate-y-1/2 z-0">
                          <div className="relative top-0 w-full">
                            <img
                              src={res.gift_frame_image_url}
                              className=" w-full"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="h-24"></div>
                  </div>
                ) : (
                  <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
                    <thead className=" h-8 rounded-t-md">
                      <tr
                        className="bg-[#5BC2DC] text-white font-light-mon rounded-t-2xl"
                        style={{ backgroundColor: data_bg?.table_header_color }}
                      >
                        <th className="border-head rounded-tl-xl pl-5 text-left">
                          Tên quà
                        </th>
                        <th className="border-head w-24">Tổng quà</th>
                        <th className="border-head">Đã phát</th>
                        <th className="border-head rounded-tr-xl">Còn lại</th>
                      </tr>
                    </thead>
                    <tbody className="font-bold-mon">
                      {lstGift &&
                        lstGift?.map((item: ListGiftCustomer, index) => (
                          <TabGift item={item} key={index} index={index} />
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            ) : (
              <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
                <thead className=" h-8 rounded-t-md">
                  <tr
                    className=" text-white font-light-mon rounded-t-2xl"
                    style={{ backgroundColor: data_bg?.table_header_color }}
                  >
                    <th className="border-head rounded-tl-xl pl-5 text-left">
                      SĐT
                    </th>
                    <th className="border-head text-left pl-3">Giải thưởng</th>
                    <th className="border-head rounded-tr-xl w-40 text-right pr-1">
                      Thời gian
                    </th>
                  </tr>
                </thead>
                <tbody className="font-bold-mon ">
                  {listWin?.map((item: ListWinCustomer, index: number) => (
                    <TabReward
                      item={item}
                      key={index}
                      index={index}
                      colorEven={data_bg?.background_color}
                      colorOdd={data_bg?.background_color}
                      colorText={data_bg?.campaign_text_details_color}
                    />
                  ))}
                  <div className="h-10"></div>
                </tbody>
              </table>
            )}
          </div>
          {current === "2" ? (
            <div className="flex justify-center mt-2">
              {totalPrize > 10 && (
                <Pagination
                  defaultCurrent={page}
                  total={totalPrize}
                  onChange={handleD}
                />
              )}

              <div className="h-40"></div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* </HeaderFrame> */}
    </div>
  );
}
