import { useLocation, useNavigate, useParams } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import { useEffect, useState } from "react";
import "../../assets/css/ListReceiveCustomer.css";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { Pagination } from "antd";
import TabGift from "./TabGift";
import TabReward from "./TabReward";
import {
  ListGiftCustomer,
  ListWinCustomer,
} from "../../models/ListReceiveCustomer";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import queryString from "query-string";

export default function ListReceiveCustomer() {
  const navigation = useNavigate();
  const { id, type } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type_campaign = params.get("type_campaign");
  const periodic_id = params.get("periodic_id");
  const { type_game } = queryString.parse(location?.search);
  const [page, setPage] = useState<number>(1);
  const [current, setCurrent] = useState("1");
  const [lstGift, setDataListGift] = useState([]);
  const [totalPrize, setTotalPrize] = useState<number>(0);
  const [listWin, setDataListWin] = useState([]);
  const [giftDisplay, setGiftDisplay] = useState<any>();

  const handleD = (data: number) => {
    setPage(data);
    getWinCampaign(data, id as string, 10);
  };
  const handleBack = () => {
    if (type_game === "climbing_tower") {
      navigation(`/dragon_snake/tc/${id}`);
    } else if (type_campaign === "summer") {
      navigation(`/${type_campaign}?id=${periodic_id}&campaign_id=${id}`);
    } else {
      navigation(`/prize-game/${id}`);
    }
  };
  const hanldeClick = (e: any, status_gifts: boolean) => {
    setCurrent(e.target.id);
    if (status_gifts) {
      getPrizeCampaign(id as string);
    } else {
      getWinCampaign(1, id as string, 10);
    }
  };
  const getPrizeCampaign = (id: string) => {
    if (type_game === "climbing_tower") {
      ListReceiptServices.GetPeriodicGift(id)
        .then((res: any) => {
          setGiftDisplay(res);
          setDataListGift(res?.gifts);
        })
        .catch((error) => {});
    } else {
      ListReceiptServices.ReceiptCustomerGift(id)
        .then((res: any) => {
          setGiftDisplay(res);
          setDataListGift(res?.gifts);
        })
        .catch((error) => {});
    }
  };
  const getWinCampaign = (page: number, id: string, limit: number) => {
    if (type_game === "climbing_tower") {
      ListReceiptServices.GetPeriodicWin(id)
        .then((res: any) => {
          setTotalPrize(res.total_item);
          setDataListWin(res.list_winning);
        })
        .catch((err) => {});
    } else {
      ListReceiptServices.ReceiptCustomerPrize(page, id, limit)
        .then((res: any) => {
          setTotalPrize(res.total_item);
          setDataListWin(res.list_winning);
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    getPrizeCampaign(id as string);
    getWinCampaign(page, id as string, 10);
    if (type === "gift") {
      setCurrent("1");
    } else {
      setCurrent("2");
    }
  }, []);
  return (
    <div>
      <HeaderFrame title="" handleBack={handleBack} iconBack={BACK} footer="">
        <div className="px-2 mt-2">
          <div className="">
            <div className="tab-receive1 flex justify-between items-center align-middle mt-5">
              <div className="flex justify-center flex-auto">
                <button
                  key="1"
                  id={"1"}
                  disabled={current === `${1}`}
                  onClick={(e) => hanldeClick(e, true)}
                  className="font-semibold-mon mt-[6px] leading-4"
                >
                  Ds quà tặng
                </button>
              </div>
              <div className="flex justify-center flex-auto">
                <button
                  key="2"
                  id={"2"}
                  disabled={current === `${2}`}
                  onClick={(e) => hanldeClick(e, false)}
                  className="font-semibold-mon mt-[6px] leading-4"
                >
                  Ds trúng thưởng
                </button>
              </div>
            </div>
            <div className=" box-border mt-5 ">
              {current === "1" ? (
                <div>
                  {giftDisplay?.gift_display === "list" ? (
                    <div>
                      {lstGift?.map((res: ListGiftCustomer) => (
                        <div className="relative top-0">
                          <div className="relative w-full grid grid-cols-12">
                            <div className="col-span-4">
                              <div className="relative top-0">
                                <img
                                  src={res.gift_background_image_url}
                                  className="relative top-0 z-10 w-full "
                                />
                                <div className="absolute bottom-2 z-20 w-full">
                                  <div className="w-full relative flex justify-center">
                                    <img
                                      src={res.gift_bow_tie_image_url}
                                      className="w-10/12 relative z-30"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-8">
                              <div className="relative top-0 left-1/2 -translate-x-1/2 w-full h-full z-10">
                                <div className="w-11/12 col-span-9 flex flex-col items-center justify-center h-full">
                                  <div
                                    style={{
                                      color: giftDisplay?.gift_name_text_color,
                                    }}
                                    className="text-center font-semibold-so leading-5 text-[#FFFFFF] text-[14px]"
                                  >
                                    {res.gift_name}
                                  </div>
                                  <div
                                    style={{
                                      color: giftDisplay?.gift_name_text_color,
                                    }}
                                    className="text-[12px] text-[#FFFFFF] -mt-[3px]"
                                  >
                                    Còn lại &nbsp;
                                    <span
                                      style={{
                                        color:
                                          giftDisplay?.gift_remaining_text_color,
                                      }}
                                      className="font-semibold-so text-[16px] text-[#67EBFF]"
                                    >
                                      {res.remaining}
                                    </span>{" "}
                                    &nbsp; / {res.plan} giải thưởng
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="absolute right-0 top-1/2 -translate-y-1/2 z-0">
                            <div className="relative top-0 w-full">
                              <img
                                src={res.gift_frame_image_url}
                                className=" w-full"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="h-24"></div>
                    </div>
                  ) : (
                    <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
                      <thead className=" h-8 rounded-t-md">
                        <tr className="bg-[#5BC2DC] text-white font-light-mon rounded-t-2xl">
                          <th className="border-head rounded-tl-xl pl-5 text-left">
                            Tên quà
                          </th>
                          <th className="border-head w-24">Tổng quà</th>
                          <th className="border-head">Đã phát</th>
                          <th className="border-head rounded-tr-xl">Còn lại</th>
                        </tr>
                      </thead>
                      <tbody className="font-bold-mon">
                        {lstGift &&
                          lstGift?.map((item: ListGiftCustomer, index) => (
                            <TabGift item={item} key={index} index={index} />
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : (
                <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
                  <thead className=" h-8 rounded-t-md">
                    <tr className="bg-[#5BC2DC] text-white font-light-mon rounded-t-2xl">
                      <th className="border-head rounded-tl-xl pl-5 text-left">
                        SĐT
                      </th>
                      <th className="border-head text-left pl-3">
                        Giải thưởng
                      </th>
                      <th className="border-head rounded-tr-xl w-40 text-right pr-1">
                        Thời gian
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-bold-mon ">
                    {listWin?.map((item: ListWinCustomer, index: number) => (
                      <TabReward
                        item={item}
                        key={index}
                        index={index}
                        colorEven={"#F0F0F0"}
                        colorOdd={"#FFFFFF"}
                        colorText={"#333333"}
                      />
                    ))}
                    <div className="h-20"></div>
                  </tbody>
                </table>
              )}
            </div>
            {current === "2" ? (
              <div className="flex justify-center mt-2">
                {totalPrize > 10 && (
                  <Pagination
                    defaultCurrent={page}
                    total={totalPrize}
                    onChange={handleD}
                  />
                )}

                <div className="h-40"></div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </HeaderFrame>
    </div>
  );
}
