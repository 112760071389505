import { DetailsDragonSnake } from "../../../../models/GameModel";

export default function PopupJigSaw({
  dataGame,
  dataGift,
  handleClosePopup,
  handleJigSaw,
}: {
  dataGame: DetailsDragonSnake;
  dataGift: any;
  handleClosePopup: () => any;
  handleJigSaw: () => any;
}) {
  return (
    <div>
      <div className="absolute -top-4 right-0 z-20" onClick={handleClosePopup}>
        <img src={dataGame?.close_button_image_url} className="w-12" />
      </div>
      <div className="flex justify-around pt-6">
        <div className="relative z-10">
          <img src={dataGift[0]?.gift_square_image_url} className="w-40" />
          <div className="absolute -bottom-1 w-10/12 left-1/2 -translate-x-1/2">
            <img src={dataGame?.jigsaw_button_image_url} className="w-" />
            <div className="absolute top-1 text-white text-center w-full">
              {dataGift[0]?.quantity} logo
            </div>
          </div>
        </div>
        <div className="relative z-10">
          <img src={dataGift[1]?.gift_square_image_url} className="w-40" />
          <div className="absolute -bottom-1 w-10/12 left-1/2 -translate-x-1/2">
            <img src={dataGame?.jigsaw_button_image_url} className="w-" />
            <div className="absolute top-1 text-white text-center w-full">
              {dataGift[1]?.quantity} logo
            </div>
          </div>
        </div>
        <div className="absolute bg-[#58b8f8] h-1 w-60 top-28 z-0"></div>
      </div>
      <div className="flex justify-center">
        <div className="relative z-10">
          <img src={dataGift[2]?.gift_square_image_url} className="w-40" />
          <div className="absolute -bottom-1 w-10/12 left-1/2 -translate-x-1/2">
            <img src={dataGame?.jigsaw_button_image_url} className="w-" />
            <div className="absolute top-1 text-white text-center w-full">
              {dataGift[2]?.quantity} logo
            </div>
          </div>
        </div>
        <div className="absolute bg-[#58b8f8] left-0 h-1 w-60 top-52 rotate-[60deg] z-0"></div>
        <div className="absolute bg-[#58b8f8] right-0 h-1 w-60 top-52 -rotate-[60deg] z-0"></div>
      </div>
      <div className="mt-5 pb-6 relative w-full flex justify-center">
        <button onClick={handleJigSaw} className="flex justify-center">
          <img src={dataGame?.jigsaw_button_image_url} className="w-10/12" />
          <div className="absolute left-0 top-2 leading-5 font-bold-so uppercase text-white text-center w-full text-[16px]">
            Nhận mã
            <br />
            dự thưởng
          </div>
        </button>
      </div>
    </div>
  );
}
