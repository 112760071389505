import { isMobileDevice } from "../../utils/deviceModel";
import ErrorBill from "../../assets/image/desktop.png";
import DESKTOP_STING from "../../assets/image/desktop_sting.jpg";
import DESKTOP_REVIVE from "../../assets/image/desktop-revive.png";

export default function NoAccessBrowser() {
  let isMobile = isMobileDevice();
  let type = window.location.pathname;
  const check = type.includes("/special/tc/");
  const checkRevive = type.includes("/intro-pickleball");

  const backgroundMobile = {
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "40vh",
    paddingLeft: "15px",
    paddingRight: "15px",
    backgroundColor: "#5BC2DC",
  };
  return (
    <div>
      {isMobile ? (
        <div className="check-reponsive" style={backgroundMobile}>
          <div className="container">
            <div style={{ textAlign: "center" }}>
              <div>
                <div className="noaccess-content">
                  Bạn vui lòng truy cập ứng dụng trên trình duyệt (Không phải ẩn
                  danh) Chrome hoặc Safari của thiết bị di động. Nhấn vào dấu
                  “…” ở góc phải màn hình xong chọn “Mở bằng trình duyệt” để
                  tiếp tục tham gia chương trình.
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${
            check ? "bg-sting" : checkRevive ? "bg-revive" : "bg-desktop"
          } h-full relative `}
        >
          <div className="relative h-full flex justify-center items-center bg-cove min-h-screen">
            <img
              src={
                check ? DESKTOP_STING : checkRevive ? DESKTOP_REVIVE : ErrorBill
              }
              className="absolute top-[50%] -translate-y-1/2"
            />
          </div>
        </div>
      )}
    </div>
  );
}
