import { useEffect, useState } from "react";
import { GameServices } from "../../../services/apiService/GameService";
import { useNavigate, useParams } from "react-router-dom";
import { Campaign } from "../../../models/GameModel";
import TC from "../PlantTree/PopupGame/TC";
import { useQuery } from "react-query";
import { homeServices } from "../../../services/apiService/homeService";
import PopupLogin from "../../../popup/PopupLogin";
import CLOSE from "../../../assets/image/Home/cancel-popup.png";
import PICKLEPALL from "../../../assets/image/Game/PicklePall/join.png";
import { BACK_GLOBAL } from "../../../services/localService/localService";

export default function IntroPickleBall() {
  const { id } = useParams();
  const [dataBg, setData] = useState<Campaign>();
  const [tc, setTc] = useState<any>();
  const [isPopup, setIsPopup] = useState(false);
  const [isShowNotify, setIsShowNotify] = useState(false);
  const [isCouple, setIsCouple] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    localStorage.setItem(BACK_GLOBAL, "intro-pickleball/1");
    GameServices.GetDataCollection(id as string)
      .then((res: any) => {
        setTc(res);
        setData(res?.collection_content_data);
      })
      .catch((err) => {});
    homeServices
      .GetProfile()
      .then((res: any) => {
        setIsCouple(res?.is_couple);
      })
      .catch((err) => {});
  }, []);
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const handleRedirect = () => {
    if (!data?.collections?.disable) {
      if (data?.collected_data) {
        if (isCouple) {
          navigation(`/success-pickleball/${id}?type=1`);
        } else {
          navigation(`/success-pickleball/${id}?type=0`);
        }
      } else {
        navigation(`/surveyform?id=${id}`);
      }
    } else {
      setIsShowNotify(true);
    }
  };
  const hanldeOpenTC = () => {
    setIsPopup(!isPopup);
  };
  const handleRedirectHome = () => {
    navigation("/");
  };
  const handleClose = () => {
    setIsShowNotify(false);
  };
  return (
    <div
      style={{
        backgroundImage: `url(${dataBg?.background_confirm_image_url})`,
        height: "100vh",
        width: "100vw",
        backgroundPosition: "cover",
        backgroundSize: "100% 100%",
      }}
      className="relative"
    >
      <div>
        <button className="absolute left-3 top-3" onClick={handleRedirectHome}>
          <img src={dataBg?.button_back_image_url} className="w-12" />
        </button>
        <button className="absolute right-3 top-3" onClick={hanldeOpenTC}>
          <img src={dataBg?.prizes_button_image_url} className="w-16" />
        </button>
        <button onClick={handleRedirect}>
          <div className="flex justify-center w-full absolute top-[40%] -translate-y-1/2">
            <img src={dataBg?.button_join_image_url} className="w-8/12" />
          </div>
        </button>
      </div>
      <TC
        frameBow={dataBg?.label_continue_image_url as string}
        imgTitle={dataBg?.title_confirm_image_url as string}
        showTab={isPopup}
        handleOpenTC={hanldeOpenTC}
        content={tc?.content_details}
      />
      <PopupLogin isShow={isShowNotify}>
        <div className="py-5">
          <div className="uppercase text-[#2D4E68] text-center text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="py-5">
            Chương trình diễn ra trong khoản thời gian từ {tc?.collection_time}.
            Xin cảm ơn Quý khách.
          </div>
          <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
            <img src={CLOSE} onClick={handleClose} />
          </div>
        </div>
      </PopupLogin>
    </div>
  );
}
