import { GameServices } from "../services/apiService/GameService";

export const getDataAddress = async (campaign_id: string, sale_id: string) => {
  try {
    const data = await GameServices.GetInforAddressUser(campaign_id, sale_id);
    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
  }
};
