import { useEffect, useState } from "react";
import ActiveCatcher from "./ActiveCatcher";
import OpenActiveCatcher from "./OpenActiveCatcher";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "./Pickup.css";
import {
  LUOT_QUAY,
  WHEEL_HAS_LUOTQUAY,
  WHEEL_LUOTQUAY,
  WHEEL_PHANTHUONG,
} from "../../../services/localService/localService";
import { GameServices } from "../../../services/apiService/GameService";
import { GameDetailsModel } from "../../../models/GameModel";
import { GiftWheelModel } from "../../../models/GiftModel";
import { PhysicalGiftModel } from "../../../models/PhysicalGift";

export default function UFOCatcher() {
  const so_id = localStorage.getItem("so_id");
  const WIN = JSON.parse(localStorage.getItem("LISTWIN") || "{}");
  console.log(WIN);
  console.log(so_id);
  const { id } = useParams();
  console.log(id);
  const navigation = useNavigate();
  const [severGiftList, setSeverGiftList] = useState<any>([]);
  const [giftWin, setGiftWin] = useState();
  const [spinRemain, setSpinRemain] = useState(0);
  const [gap_active, setGap_Active] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [number_gap, setNumber_Gap] = useState(0);
  const [open_gap, set_open_gap] = useState(false);
  const [current_number, set_current_number] = useState(3);
  const [pre_number, set_pre_number] = useState("no");
  const [disableBtn, setDisableBtn] = useState(false);
  const [popUpGift, setPopupGift] = useState(false);
  const [random_location, set_random_location] = useState(0);
  const [soqua, setsoqua] = useState(8);
  const interleave = ([x, ...xs]: any[], ys: any[]): any[] =>
    x ? [x, ...interleave(ys, xs)] : ys;

  console.log(animation);

  const runWheel = () => {
    console.log("ấn vào quay");
    // luckyDrawService
    //   .postCountDraw(id)
    //   .then((response: any) => {
    //     console.log(response);
    //     setSpinRemain(
    //       response.gift_no + response.gift_extra_no - response.count_draw
    //     );
    //     const { voucher_line_id } = JSON.parse(
    //       localStorage.getItem(WHEEL_PHANTHUONG) || "{}"
    //     );
    //     console.log(voucher_line_id);
    //     localStorage.setItem("VOUCHER_LINE", voucher_line_id);
    //     if (response.gift_no >= response.count_draw) {
    //       localStorage.setItem(
    //         WHEEL_LUOTQUAY,
    //         "" +
    //           (response.gift_no + response.gift_extra_no - response.count_draw)
    //       );
    //       if (response.gift_no - response.count_draw >= 1) {
    //         localStorage.setItem(WHEEL_HAS_LUOTQUAY, "TRUE");
    //       } else {
    //         localStorage.setItem(WHEEL_HAS_LUOTQUAY, "FAlSE");
    //       }
    //     } else {
    //       localStorage.setItem(
    //         WHEEL_LUOTQUAY,
    //         "" +
    //           (response.gift_no + response.gift_extra_no - response.count_draw)
    //       );
    //       if (
    //         response.gift_no + response.gift_extra_no - response.count_draw >=
    //         1
    //       ) {
    //         localStorage.setItem(WHEEL_HAS_LUOTQUAY, "TRUE");
    //       } else {
    //         localStorage.setItem(WHEEL_HAS_LUOTQUAY, "FAlSE");
    //       }
    //     }
    //   })
    //   .catch((err: any) => {
    //     console.log(err?.message);
    //     toast.error(err?.message);
    //     navigation(`/list-rotation`);
    //   });
  };
  const [gameData, setGameData] = useState<any>();
  const { campaign_id, sale_id } = useParams<string>();
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [isPopupGift, setIsPopupGift] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [win, setwin] = useState<GiftWheelModel>();
  const [disable, setDisable] = useState(false);
  const [video, setVideo] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [lineId, setLineId] = useState();
  const [physicalGift, setPhysicalGift] = useState<PhysicalGiftModel>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(id);
    // luckyDrawService
    //   .postDrawLuckyGift(id)
    //   .then((dataResponse: any) => {
    //     console.log(dataResponse);
    //     setGameData(dataResponse?.game_data);
    //     localStorage.setItem(
    //       "GAME_DATA",
    //       JSON.stringify(dataResponse?.game_data)
    //     );
    //     if (
    //       dataResponse.count_draw ===
    //       dataResponse.gift_no + dataResponse.gift_extra_no
    //     ) {
    //       navigation("/");
    //     }
    //     setSpinRemain(
    //       dataResponse.gift_no +
    //         dataResponse.gift_extra_no -
    //         dataResponse.count_draw
    //     );
    //     set_random_location(
    //       dataResponse.gift_no +
    //         dataResponse.gift_extra_no -
    //         dataResponse.count_draw
    //     );
    //     if (dataResponse.count_draw < dataResponse.gift_no) {
    //       if (dataResponse.list_prizes !== undefined) {
    //         let list_prizes = dataResponse.list_prizes;
    //         let segmentsTemp = [];
    //         let segmentsBigHalfGiftTemp = [];
    //         let segmentsNormalGiftTemp = [];
    //         let gift_no = dataResponse.gift_no;
    //         let count_draw = dataResponse.count_draw;
    //         let winStateTemp = 0;
    //         if (gift_no > count_draw) {
    //           let gift_code_win = dataResponse.list_win[count_draw]?.gift_code;
    //           localStorage.setItem(
    //             WHEEL_PHANTHUONG,
    //             JSON.stringify(dataResponse.list_win[count_draw])
    //           );
    //           setGiftWin(dataResponse.list_win[count_draw]);
    //           localStorage.setItem(
    //             "VOUCHER_LINE_WHEEL",
    //             dataResponse.list_win[count_draw].voucher_line_id
    //           );
    //           if (
    //             list_prizes !== undefined &&
    //             list_prizes !== null &&
    //             list_prizes.length > 0
    //           ) {
    //             for (let i = 0; i < list_prizes.length; i++) {
    //               let a = list_prizes[i].gift_short_name;
    //               var check = false;

    //               let itemTemp = {
    //                 gift_code: list_prizes[i].gift_code,
    //                 image:
    //                   i % 2 === 0
    //                     ? dataResponse?.game_data?.box_odd
    //                     : dataResponse?.game_data?.box_even,
    //                 text: a,
    //                 check: check,
    //                 gift_image:
    //                   i % 2 === 0
    //                     ? dataResponse?.game_data?.box_odd
    //                     : dataResponse?.game_data?.box_even,
    //               };
    //               if (!list_prizes[i].gift_code.includes("biggift")) {
    //                 if (!list_prizes[i].gift_code.includes("bighalfgift")) {
    //                   segmentsNormalGiftTemp.push(itemTemp);
    //                 } else {
    //                   segmentsBigHalfGiftTemp.push(itemTemp);
    //                 }
    //               }
    //               segmentsTemp.push(itemTemp);
    //             }
    //             let a = 0;
    //             var segmentsTempList = segmentsTemp.concat(
    //               segmentsBigHalfGiftTemp
    //             );
    //             if (segmentsTempList.length < soqua) {
    //               a = 8 - segmentsTempList.length;
    //               var array = [];
    //               for (let i = 0; i < a; i++) {
    //                 array.push(
    //                   segmentsNormalGiftTemp[
    //                     Math.floor(
    //                       Math.random() * segmentsNormalGiftTemp.length
    //                     )
    //                   ]
    //                 );
    //               }
    //               segmentsTemp = interleave(segmentsTempList, array);
    //             }
    //             for (let a = 0; a < segmentsTemp.length; a++) {
    //               if (segmentsTemp[a].gift_code === gift_code_win) {
    //                 check = true;
    //                 winStateTemp = a + 1;
    //               }
    //               segmentsTemp[a] = { ...segmentsTemp[a], id: a + 1 };
    //             }
    //           }
    //         } else {
    //         }
    //         setSeverGiftList(segmentsTemp);
    //         localStorage.setItem(WHEEL_LUOTQUAY, "" + (gift_no - count_draw));
    //       }
    //     } else {
    //       if (dataResponse.list_extra_prizes !== undefined) {
    //         let list_extra_prizes = dataResponse.list_extra_prizes;
    //         let segmentsTemp = [];
    //         let segmentsBigHalfGiftTemp = [];
    //         let segmentsNormalGiftTemp = [];
    //         let gift_no = dataResponse.gift_no;
    //         let gift_extra_no = dataResponse.gift_extra_no;
    //         let count_draw = dataResponse.count_draw;
    //         let winStateTemp = 0;
    //         if (gift_no + gift_extra_no > count_draw) {
    //           console.log(count_draw - gift_no);
    //           let gift_code_win =
    //             dataResponse.list_extra_win[count_draw - gift_no]?.gift_code;
    //           localStorage.setItem(
    //             WHEEL_PHANTHUONG,
    //             JSON.stringify(
    //               dataResponse.list_extra_win[count_draw - gift_no]
    //             )
    //           );
    //           setGiftWin(dataResponse.list_extra_win[count_draw - gift_no]);
    //           localStorage.setItem(
    //             "VOUCHER_LINE_WHEEL",
    //             dataResponse.list_extra_win[count_draw - gift_no]
    //               .voucher_line_id
    //           );
    //           if (
    //             list_extra_prizes !== undefined &&
    //             list_extra_prizes !== null &&
    //             list_extra_prizes.length > 0
    //           ) {
    //             for (let i = 0; i < list_extra_prizes.length; i++) {
    //               let a = list_extra_prizes[i].gift_short_name;
    //               var check = false;
    //               let itemTemp = {
    //                 gift_code: list_extra_prizes[i].gift_code,
    //                 image:
    //                   i % 2 === 0
    //                     ? dataResponse?.game_data?.box_odd
    //                     : dataResponse?.game_data?.box_even,
    //                 //  list_extra_prizes[i].gift_image,
    //                 text: a,
    //                 check: check,
    //                 gift_image:
    //                   i % 2 === 0
    //                     ? dataResponse?.game_data?.box_odd
    //                     : dataResponse?.game_data?.box_even,
    //                 //  list_extra_prizes[i].gift_image,
    //               };
    //               if (!list_extra_prizes[i].gift_code.includes("biggift")) {
    //                 if (
    //                   !list_extra_prizes[i].gift_code.includes("bighalfgift")
    //                 ) {
    //                   segmentsNormalGiftTemp.push(itemTemp);
    //                 } else {
    //                   segmentsBigHalfGiftTemp.push(itemTemp);
    //                 }
    //               }
    //               segmentsTemp.push(itemTemp);
    //             }
    //             let a = 0;
    //             var segmentsTempList = segmentsTemp.concat(
    //               segmentsBigHalfGiftTemp
    //             );
    //             if (segmentsTempList.length < soqua) {
    //               a = 8 - segmentsTempList.length;
    //               var array = [];
    //               for (let i = 0; i < a; i++) {
    //                 array.push(
    //                   segmentsNormalGiftTemp[
    //                     Math.floor(
    //                       Math.random() * segmentsNormalGiftTemp.length
    //                     )
    //                   ]
    //                 );
    //               }
    //               segmentsTemp = interleave(segmentsTempList, array);
    //             }
    //             console.log("seg: ", segmentsTemp);
    //             console.log("gift_code_win: ", gift_code_win);
    //             for (let a = 0; a < segmentsTemp.length; a++) {
    //               if (segmentsTemp[a].gift_code === gift_code_win) {
    //                 check = true;
    //                 winStateTemp = a + 1;
    //               }
    //               segmentsTemp[a] = { ...segmentsTemp[a], id: a + 1 };
    //             }
    //             console.log("data: ", winStateTemp);
    //           }
    //         } else {
    //         }
    //         setSeverGiftList(segmentsTemp);
    //         localStorage.setItem(
    //           WHEEL_LUOTQUAY,
    //           "" + (gift_no + gift_extra_no - count_draw)
    //         );
    //       }
    //     }
    //   })
    //   .catch((err: any) => {
    //     console.log(err?.message);
    //     toast.error(err?.message);
    //     setTimeout(() => {
    //       navigation(`/list-rotation`);
    //     }, 100);
    //   });
    getCalculator(campaign_id, sale_id);
  }, []);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    setIsChange(true);
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        console.log(res);
        setDisable(false);
        setIsPopupGift(false);
        setIsLoading(false);
        setVideo(false);
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setDataCallUrbox(res.remaining_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          setwin(itemWin);
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
      });
  };
  useEffect(() => {
    setGap_Active(false);
    setAnimation(false);
    set_open_gap(false);
    setDisableBtn(false);
    set_current_number(3);
    setNumber_Gap(0);
    set_pre_number("no");
  }, []);

  const handleRight = () => {
    if (current_number === 5) {
    } else {
      set_pre_number("right");
      set_current_number(current_number + 1);
    }
  };
  const handleDisable = () => {};
  const handleLeft = () => {
    if (current_number === 1) {
    } else {
      set_pre_number("left");
      set_current_number(current_number - 1);
    }
  };
  document.body.style.backgroundColor = "#000000";

  const hanleAniGap = () => {
    setAnimation(true);
    setDisableBtn(true);
    setTimeout(() => {
      runWheel();
    }, 2000);
    setTimeout(() => {
      set_open_gap(true);
      setTimeout(() => {
        set_open_gap(false);
        setGap_Active(true);
      }, 400);
    }, 1300);
    setTimeout(() => {
      setAnimation(false);
      setDisableBtn(false);
      setGap_Active(false);
      setPopupGift(true);
      setTimeout(() => {
        navigation(`/popup-pickup/${id}`);
      }, 2000);
      console.log("hello");
    }, 4300);
  };

  const handleAnimationEnd = () => {
    console.log("Animation kết thúc!");
    alert("Animation kết thúc!");
    navigation(`/popup-pickup/${id}`);
  };
  return (
    <div className="">
      {popUpGift ? (
        <div className="stylevideo min-h-screen w-full">
          <img
            src={
              random_location % 2 === 0
                ? "https://storage.googleapis.com/mvcpro_vn/e66f8364-6ef1-5958-3c8c-dce8ffa46e1e_27-12-2024-17-43-45_animation-blindbag-blue-21-ezgif.com-video-to-gif-converter.gif"
                : "https://storage.googleapis.com/mvcpro_vn/e66f8364-6ef1-5958-3c8c-dce8ffa46e1e_27-12-2024-17-43-45_animation-blindbag-blue-21-ezgif.com-video-to-gif-converter.gif"
            }
            onAnimationEnd={handleAnimationEnd}
          />
        </div>
      ) : (
        <div
          className="relative top-0"
          style={{
            backgroundImage: `url(${gameData?.intro_bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            // height: "80px",
            minHeight: "100vh",
          }}
        >
          <div className="relative top-10 px-6 flex text-center justify-between "></div>
          <div className=" w-full">
            <div className="relative z-40">
              <img
                src={gameData?.title_image}
                className="w-full relative z-40"
              />
            </div>
            <div className="relative -top-[4px] z-30">
              <div className="absolute bottom-0 z-0">
                <img
                  src={gameData?.card_image}
                  className="relative body-hide z-50"
                />
              </div>
              <div className="w-full relative top-[2px] z-40">
                <img
                  src={gameData?.bg_game_top}
                  className="w-full relative top-0 z-40"
                />
              </div>
              <div className="absolute top-0 w-full px-10">
                <div className="grid grid-cols-5">
                  <div className="col-span-1 flex justify-center">
                    {current_number === 1 ? (
                      <>
                        {animation ? (
                          <ActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            gap_active={gap_active}
                            open_gap={open_gap}
                            giftWin={
                              random_location % 2 === 0
                                ? gameData?.box_even
                                : gameData?.box_odd
                            }
                          />
                        ) : (
                          <OpenActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            pre_number={pre_number}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-span-1 flex justify-center">
                    {current_number === 2 ? (
                      <>
                        {animation ? (
                          <ActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            gap_active={gap_active}
                            open_gap={open_gap}
                            giftWin={
                              random_location % 2 === 0
                                ? gameData?.box_even
                                : gameData?.box_odd
                            }
                          />
                        ) : (
                          <OpenActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            pre_number={pre_number}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-span-1 flex justify-center">
                    {current_number === 3 ? (
                      <>
                        {animation ? (
                          <ActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            gap_active={gap_active}
                            open_gap={open_gap}
                            giftWin={
                              random_location % 2 === 0
                                ? gameData?.box_even
                                : gameData?.box_odd
                            }
                          />
                        ) : (
                          <OpenActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            pre_number={pre_number}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-span-1 flex justify-center">
                    {current_number === 4 ? (
                      <>
                        {animation ? (
                          <ActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            gap_active={gap_active}
                            open_gap={open_gap}
                            giftWin={
                              random_location % 2 === 0
                                ? gameData?.box_even
                                : gameData?.box_odd
                            }
                          />
                        ) : (
                          <OpenActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            pre_number={pre_number}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-span-1 flex justify-center">
                    {current_number === 5 ? (
                      <>
                        {animation ? (
                          <ActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            gap_active={gap_active}
                            open_gap={open_gap}
                            giftWin={
                              random_location % 2 === 0
                                ? gameData?.box_even
                                : gameData?.box_odd
                            }
                          />
                        ) : (
                          <OpenActiveCatcher
                            img_day={gameData?.bg_button_guide_wheel}
                            img={gameData?.bg_title_wheel}
                            pre_number={pre_number}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="absolute bottom-10 w-full px-10 z-0">
                <div className="absolute left-1/2 -translate-x-1/2 bottom-36 grid grid-cols-2 px-10">
                  <div className="col-span-1 flex justify-center">
                    {" "}
                    <div className="relative">
                      {number_gap === 1 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-10 left-1 rotate-right">
                          <img
                            src={severGiftList[7]?.gift_image}
                            className="relative w-60 bottom-2 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-center">
                    {" "}
                    <div className="relative">
                      {number_gap === 1 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-9 left-0 rotate-left">
                          <img
                            src={severGiftList[4]?.gift_image}
                            className="relative w-80 bottom-2 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4 px-10 absolute bottom-24 z-0">
                  <div className="col-span-1 flex justify-center">
                    {" "}
                    <div className="relative">
                      {number_gap === 1 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-10 left-0 rotate-right">
                          <img
                            src={severGiftList[5]?.gift_image}
                            className="relative w-80 bottom-2 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-center">
                    {" "}
                    <div className="relative">
                      {number_gap === 1 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-7 left-0 rotate-left">
                          <img
                            src={severGiftList[6]?.gift_image}
                            className="relative w-80 bottom-2 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-center">
                    {" "}
                    <div className="relative">
                      {number_gap === 1 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-12 left-1 rotate-left">
                          <img
                            src={severGiftList[7]?.gift_image}
                            className="relative w-80 bottom-2 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-center">
                    {" "}
                    <div className="relative -z-50">
                      {number_gap === 1 ? (
                        ""
                      ) : (
                        <div className="relative -z-50 w-28 -bottom-14 -left-5 rotate-left">
                          <img
                            src={severGiftList[4]?.gift_image}
                            className="absolute w-28 -bottom-14 left-1/2 -translate-x-1/2 text-black z-0"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-5">
                  <div className="col-span-1 flex justify-center">
                    <div className="relative">
                      {number_gap === 1 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-2 left-2 rotate-left">
                          <img
                            src={severGiftList[0]?.gift_image}
                            className="relative w-80 -bottom-4 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-center">
                    <div>
                      {number_gap === 2 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-7 rotate-right">
                          <img
                            src={severGiftList[1]?.gift_image}
                            className="absolute w-84 -bottom-20 left-[65%] -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-center">
                    <div>
                      {number_gap === 3 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-16 rotate-left">
                          <img
                            src={severGiftList[2]?.gift_image}
                            className="absolute w-28 -bottom-12 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-center">
                    <div>
                      {number_gap === 4 ? (
                        ""
                      ) : (
                        <div className="relative w-28 -bottom-12 rotate-right">
                          <img
                            src={severGiftList[3]?.gift_image}
                            className="absolute w-28 -bottom-20 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-center">
                    <div>
                      {number_gap === 5 ? (
                        ""
                      ) : (
                        <div className="relative w-28 right-5 rotate-left">
                          <img
                            src={severGiftList[4]?.gift_image}
                            className="absolute w-28 -bottom-28 left-1/2 -translate-x-1/2 text-black z-50"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute w-full -bottom-[31%] left-0 z-50">
                <img src={gameData?.bg_game_bottom} className="w-full z-20" />
                <div className="absolute top-20 w-full px-12 z-50">
                  <div className="grid grid-cols-3 relative z-50">
                    <div className="flex justify-center relative z-50">
                      <div>
                        <button
                          onClick={
                            disableBtn === true
                              ? () => handleDisable()
                              : handleLeft
                          }
                        >
                          <img
                            src={gameData?.bg_active_triangle}
                            className="w-9 relative -top-2 z-50"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-center relative z-50">
                      <div>
                        <button
                          className="relative z-50"
                          onClick={
                            disableBtn === true
                              ? () => handleDisable()
                              : () => hanleAniGap()
                          }
                        >
                          <span id="" className="relative z-50 bottom-7">
                            <img
                              src={gameData?.bg_button_wheel}
                              className={`${
                                animation ? "animationButton" : ""
                              } w-24`}
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-center relative z-50">
                      <div>
                        <button
                          className="relative z-50"
                          onClick={
                            disableBtn === true
                              ? () => handleDisable()
                              : handleRight
                          }
                        >
                          <img
                            src={gameData?.bg_active_hexagonal}
                            className="w-9 relative -top-2 z-50"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {spinRemain === 0 ? (
                  <div
                    style={{
                      color: gameData?.highlight_amount_wheel,
                    }}
                    className="absolute z-50 text-[12px] top-3 left-1/2 -translate-x-1/2 text-white w-full text-center"
                  >
                    Hết lượt chơi
                  </div>
                ) : (
                  <div
                    style={{
                      color: gameData?.highlight_amount_wheel,
                    }}
                    className="absolute z-40 text-[12px] top-3 left-1/2 -translate-x-1/2 text-white w-full text-center"
                  >
                    Bạn còn <span className="font-bold-mon">{spinRemain}</span>{" "}
                    lượt gắp
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
}
