import { DetailsDragonSnake } from "../../../../models/GameModel";

export default function PopupTC({
  dataGame,
  dataTC,
  handleCloseTC,
}: {
  dataGame: DetailsDragonSnake;
  dataTC: string;
  handleCloseTC: () => any;
}) {
  return (
    <div>
      <div className="absolute -top-5 -right-2" onClick={handleCloseTC}>
        <img src={dataGame?.close_button_image_url} className="w-12" />
      </div>
      <div className="pt-4 pb-4">
        <div
          style={{
            maxHeight: "440px",
            overflowY: "auto",
          }}
          dangerouslySetInnerHTML={{
            __html: dataTC,
          }}
          className="font-light-so line-tablesting point-ul text-[14px] text-white text-left point-ul line-table px-5"
        ></div>
        <button
          onClick={handleCloseTC}
          className="flex justify-center relative w-full mt-2"
        >
          <img src={dataGame?.jigsaw_button_image_url} className="w-6/12" />
          <div className="absolute left-0 top-1/2 -translate-y-1/2 leading-5 font-bold-so uppercase text-white text-center w-full text-[21px]">
            ĐỒNG Ý
          </div>
        </button>
      </div>
    </div>
  );
}
