import { ListQuest } from "../../../../../models/GameModel";
import BUTTON from "../../../../../assets/image/Game/PlantTree/button-quest.png";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GameServices } from "../../../../../services/apiService/GameService";
import DISABLE_BUTTON from "../../../../../assets/image/Game/PlantTree/disable-receive.png";

export default function Item({ res }: { res: ListQuest }) {
  const { id } = useParams();
  const [disable, setDisable] = useState(res?.disable);
  const handleReceive = (quest_id: string) => {
    const checkOA = res?.quest_name
      ?.toLowerCase()
      ?.includes("quan tâm zalo oa");
    if (checkOA) {
      const link = document.createElement("a");
      link.href = "https://zalo.me/1745937979032015573";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    } else {
      setDisable(true);
      if (!disable) {
        GameServices.UpdateQuest(id as string, quest_id)
          .then((response) => {})
          .catch((err) => {});
      }
    }
  };

  useEffect(() => {
    try {
      const script = document.createElement("script");
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => console.log("Zalo SDK loaded successfully");
      script.onerror = () => console.error("Error loading Zalo SDK");

      return () => {
        document.body.removeChild(script);
      };
    } catch (error) {
      console.error("Error loading or handling Zalo SDK:", error);
    }
  }, []);
  return (
    <div className="border-qa p-2 rounded-3xl bg-[#F0FBFE] grid grid-cols-12 gap-2 mb-3">
      <div className="col-span-2">
        <img src={res?.quest_icon_url} />
      </div>
      <div className="col-span-6 text-left">
        <div className="font-semibold-so text-[#2D4E68]">{res.quest_name}</div>
        <div className="text-[14px] text-[#4E9ABB] font-medium-so">
          x{res.quest_points} Xô nước
        </div>
      </div>
      <div className="col-span-4">
        {!disable ? (
          <button
            className="relative w-full top-1/2 -translate-y-1/2"
            onClick={
              disable ? () => {} : () => handleReceive(res.quest_id as string)
            }
          >
            <>
              <img src={BUTTON} className=" w-full" />
              <div className="absolute text-white top-[45%] -translate-y-1/2 left-1/2 -translate-x-1/2">
                Nhận
              </div>
            </>
          </button>
        ) : (
          <button className="relative w-full top-1/2 -translate-y-1/2">
            <img src={DISABLE_BUTTON} className=" w-full" />
            <div className="absolute text-white top-[40%] -translate-y-1/2 left-1/2 -translate-x-1/2">
              Nhận
            </div>
          </button>
        )}
      </div>
    </div>
  );
}
