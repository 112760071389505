interface ActiveCatcherModel {
  gap_active: boolean;
  open_gap: boolean;
  img_day: string;
  img: string;
  giftWin: string;
}
export default function ActiveCatcher(data: ActiveCatcherModel) {
  console.log(data.img_day);
  return (
    <div className="relative ani-day-gap">
      <img src={data.img_day} className="relative day-gap z-0" />
      <div className="rotate-gap relative">
        <img src={data.img} className="w-20 " />
        {data.gap_active ? (
          <div className="absolute -bottom-10 -left-4 z-20 w-[100px] top-10 rotate-left">
            <div className="absolute bottom-0 w-full">
              <img
                src={data.giftWin}
                className="w-28 relative bottom-0 left-1/2 -translate-x-1/2"
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
