export default function Man({
  isLeg,
  isLegRight,
  isAction,
  changeBody,
  statusStep,
}: {
  isLeg: boolean;
  isLegRight: boolean;
  isAction: boolean;
  changeBody: boolean;
  statusStep: string;
}) {
  const dataGame = JSON.parse(localStorage.getItem("GAME_DATA_LOCAL") || "{}");
  return (
    <div>
      <div
        className={`${isAction ? "animation_go" : ""} ${
          statusStep === "left"
            ? "animation_go-left"
            : statusStep === "right"
            ? "animation_go-right"
            : ""
        } relative `}
      >
        <div className={`${isAction ? "animation_body" : ""} relative z-10 `}>
          <img
            src={
              changeBody
                ? dataGame?.object_body_image_url
                : dataGame?.object_head_image_url
            }
            className="w-20"
          />
        </div>
        <div className={`${isAction && isLeg ? "hidden" : "block"}`}>
          <div
            className={`${
              isAction ? "animation_leg-left" : ""
            } absolute w-[24px] z-0 -bottom-4 max-[390px]:left-[10px] left-[14px]`}
          >
            <img
              src={
                changeBody
                  ? dataGame?.left_legs_image_url
                  : dataGame?.object_behind_legs_image_url
              }
              className=""
            />
          </div>
          <div
            className={`${
              isAction && isLegRight ? "animation_leg-right" : ""
            } absolute w-[24px] z-0 -bottom-4 max-[390px]:right-[10px] right-[14px]`}
          >
            <img
              src={
                changeBody
                  ? dataGame?.object_front_legs_image_url
                  : dataGame?.right_legs_image_url
              }
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
