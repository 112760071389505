import { Fragment, useEffect, useRef, useState } from "react";
import { GameServices } from "../../../services/apiService/GameService";
import { LUOT_QUAY } from "../../../services/localService/localService";
import { useNavigate, useParams } from "react-router-dom";
import { GameDetailsModel } from "../../../models/GameModel";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import CLICK from "../../../assets/image/Game/Sting/click.png";
import PopupError from "../../../components/Popup/PopupError";
import "../../../assets/css/Sting.css";
import VIDEO from "../../../assets/image/Game/Sting/sting.mp4";
import BACK from "../../../assets/image/Game/Sting/back.png";
import { GiftWheelModel } from "../../../models/GiftModel";
import { getDataAddress } from "../../../utils/getAddressGift";
import { PhysicalGiftModel } from "../../../models/PhysicalGift";
import PhysicalGift from "../../../components/Game/PopupPhysicalGift/PhysicalGift";
import PopupSpecialReceive from "./PopupSpecialReceive";

export default function MainPageSting() {
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [isPopupGift, setIsPopupGift] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [win, setwin] = useState<GiftWheelModel>();
  const [disable, setDisable] = useState(false);
  const [video, setVideo] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [lineId, setLineId] = useState();
  const [physicalGift, setPhysicalGift] = useState<PhysicalGiftModel>();
  const location_status_game = localStorage.getItem("LOCATION_STATUS_GAME");
  const prizeGame = JSON.parse(
    localStorage.getItem("DATA_LEADER_BOARD") || "{}"
  );
  console.log(prizeGame);

  document.body.style.backgroundColor = "#000000";
  const [isPopupGuide, setIsPopupGuide] = useState(
    location_status_game === "TRUE" ? true : false
  );
  const { campaign_id, sale_id } = useParams<string>();

  useEffect(() => {
    localStorage.removeItem("CONFIRM");
    if (!isChange) {
      getCalculator(sale_id, campaign_id);
    }

    localStorage.removeItem("GIFT_WHEEL");
    setTimeout(() => {
      localStorage.removeItem("LOCATION_STATUS_GAME");
      setIsPopupGuide(false);
    }, 1500);
    getDataAddressUser();
  }, []);
  const getDataAddressUser = async () => {
    const dataAddress: any = await getDataAddress(
      campaign_id as string,
      sale_id as string
    );
    console.log(dataAddress);

    setPhysicalGift(dataAddress);
  };
  const navigation = useNavigate();
  const handleChoose = () => {
    setDisable(true);
    if (!disable) {
      setVideo(true);
      document.body.style.overflow = "hidden";
      countDraw();
    }
  };
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    setIsChange(true);
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        console.log(res);
        setDisable(false);
        setIsPopupGift(false);
        setIsLoading(false);
        setVideo(false);
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setDataCallUrbox(res.remaining_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          setwin(itemWin);
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
      });
  };
  const countDraw = async () => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };
    GameServices.CountDrawApi(data)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem("CONFIRM", "CONFIRM");
        if (res.remaining_draw === 0) {
          const dataParams = {
            sale_id: sale_id,
            campaign_id: campaign_id,
          };
          GameServices.SendGiftApi(dataParams)
            .then((res) => {})
            .catch((err) => {});
        }
        setDataCallUrbox(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
      });
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleClearPopup = () => {
    localStorage.removeItem("LOCATION_STATUS_GAME");
    setIsPopupGuide(false);
  };
  const handleCloseError = () => {
    navigation("/games");
    setIsError(false);
  };
  const handleBack = () => {
    navigation("/");
  };
  const handleClosePopup = () => {
    localStorage.removeItem("CONFIRM");

    if (!isLoading) {
      setIsLoading(true);
      getCalculator(sale_id, campaign_id);
    }
    if (dataCallUrbox === 0) {
      navigation("/list-gift");
    } else {
      window.location.reload();
    }
  };
  const videoRef = useRef<any>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Phát video thủ công
      video.play().catch((error: any) => {
        console.error("Error playing video:", error);
      });

      // Ngăn iOS hiển thị media controls (nếu có thể)
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = null; // Xóa metadata của media session
        navigator.mediaSession.setActionHandler("play", null);
        navigator.mediaSession.setActionHandler("pause", null);
        navigator.mediaSession.setActionHandler("seekbackward", null);
        navigator.mediaSession.setActionHandler("seekforward", null);
      }
    }
  }, []);
  // Hàm xử lý khi video kết thúc
  const handleVideoEnd = () => {
    setIsPopupGift(true); // Giả sử bạn có state này trong component
  };
  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Ngăn sự kiện pause
      const preventPause = () => {
        video.play(); // Buộc phát lại nếu bị tạm dừng
      };

      // Xử lý cho iOS/Safari
      const handleWebkitPlayback = (event: any) => {
        if (event.availability === "available") {
          video.play();
        }
      };

      video.addEventListener("pause", preventPause);
      video.addEventListener(
        "webkitplaybacktargetavailabilitychanged",
        handleWebkitPlayback
      );

      // Cleanup để tránh memory leak
      return () => {
        video.removeEventListener("pause", preventPause);
        video.removeEventListener(
          "webkitplaybacktargetavailabilitychanged",
          handleWebkitPlayback
        );
      };
    }
  }, []);
  const handleBackToGame = () => {
    navigation(`/sting_pickup/${campaign_id}/${sale_id}`);
  };
  return (
    <Fragment>
      {video ? (
        <div className="stylevideo bg-[#333333] min-h-screen w-full">
          <video
            width="100%"
            playsInline
            preload="metadata"
            autoPlay
            muted
            disablePictureInPicture
            controls={false}
            style={{ opacity: 1 }}
            onEnded={() => {
              setIsPopupGift(true);
            }}
            ref={videoRef}
          >
            <source
              src={
                "https://storage.googleapis.com/mvcpro-fe/CAMPAIGN_GAME/ROTATION_FRAME_IMAGE_URL/1743490533431.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
      ) : (
        <div
          className="bg-[#333333]"
          style={{
            backgroundImage: `url(${game?.background_image_url})`,
            backgroundSize: "100% 100%",
            backgroundPosition: "cover",
            backgroundColor: "#333333",
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <button
            className="absolute top-5 left-5 z-10"
            aria-label="buy product"
            onClick={handleBack}
          >
            <div className="flex items-center">
              <img
                src={BACK}
                loading="lazy"
                sizes="32px"
                className="w-32"
                alt=""
              />
            </div>
          </button>
          <PopupOverlay isShow={isPopupGuide}>
            <div onClick={handleClearPopup}>
              <div className="relative">
                <img
                  src={game?.game_items[0]}
                  className="w-8/12 relative left-1/2 -translate-x-1/2"
                />
                <div className="absolute top-2 w-full flex justify-center">
                  <img
                    src={game?.game_items[1]}
                    className="relative rotate-thunder w-8/12 animation-thunder"
                  />
                </div>
              </div>
              <div className="absolute top-1/2 left-[40%] -translate-x-1/2">
                <img src={CLICK} alt="" className="w-16 click-animation" />
              </div>
              <div className="absolute top-1/2 font-semibold-so text-white text-center w-full">
                Chạm để chơi
              </div>
            </div>
          </PopupOverlay>
          <div className="relative top-5 w-full">
            <img
              src={game?.title_image_url}
              className="w-7/12 relative top-5 left-1/2 -translate-x-1/2"
            />
            <div
              className="relative top-5 w-8/12 left-1/2 -translate-x-1/2"
              onClick={() => handleChoose()}
            >
              <img
                src={game?.game_items[0]}
                className=" relative left-1/2 -translate-x-1/2"
              />
              <div className="absolute top-2 w-full flex justify-center">
                <img
                  src={game?.game_items[1]}
                  className="relative rotate-thunder w/12 animation-thunder"
                />
              </div>
              <div className="absolute top-1/2 left-[40%] -translate-x-1/2">
                <img src={CLICK} alt="" className="w-16 click-animation" />
              </div>
            </div>
            <button
              className="relative left-1/2 -translate-x-1/2"
              style={{
                backgroundImage: `url(${game?.button_top_image_url})`,
                backgroundSize: "100% 100%",
                backgroundPosition: "contain",
                width: "250px",
                height: "120px",
                color: game?.button_text_color,
              }}
              onClick={() => handleChoose()}
            >
              <label className="relative -top-[3px] font-medium-air text-[15px] leading-3">
                {dataCallUrbox > 0 ? (
                  <>
                    Bạn có{" "}
                    <span className="text-[25px] "> {dataCallUrbox}</span> lượt
                    <div>tham gia trúng thưởng</div>
                  </>
                ) : (
                  <div className="">Hết lượt chơi</div>
                )}
              </label>
            </button>
          </div>
          <PopupError
            labelBtn={"Đồng ý"}
            messError={messError}
            handleClose={handleCloseError}
            isShow={isError}
          />
        </div>
      )}
      {/* <PhysicalGift
        campaign_id={campaign_id}
        line_id={lineId}
        sale_id={sale_id}
        isShow={isPopupGift}
        physicalGift={physicalGift}
        handleBack={handleBackToGame}
      /> */}
      <PopupSpecialReceive
        handleClosePopup={handleClosePopup}
        amountPlay={dataCallUrbox}
        gift={win!}
        isPopupGift={isPopupGift}
        isLoading={isLoading}
      />
    </Fragment>
  );
}
